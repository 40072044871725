import React from 'react'
import PropTypes from 'prop-types'

class rulesModal extends React.Component{
    static propTypes = {
        content:PropTypes.string
    }
    render(){
        return (
            <div className="hidden_scroll" style={{height:'600px',overflow:'auto'}} dangerouslySetInnerHTML={{__html:this.props.content}}>
                
            </div>
        )
    }
}

export default rulesModal