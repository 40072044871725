import React from 'react'
import LivePlayer from './LivePlayer'
import BjyLivePlayer from './BjyLivePlayer'

import PolyvVideo from './PolyvVideo'
import BjyVideo from './BjyVideo'
// import VideoPlayer from './VideoBox';
import 'video.js/dist/video-js.min.css';


/* eslint-disable */


class PlayerBox extends React.Component{
    state = {
        id:1,
        play_url:'',
    }
    componentDidMount(){
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.sectionInfo.id && nextProps.sectionInfo.id !== prevState.id){
            if(nextProps.sectionInfo.type === 2){
                return ({
                    id:nextProps.sectionInfo.id,
                    play_url:nextProps.sectionInfo.videoUrl,
                })
            }else{
                return null
            }
        }else{
            return null
        }
    }
    createDome = () => {
        const { sectionInfo } = this.props
        if(sectionInfo.type === 1 || sectionInfo.type === 4){
            return (
                <div style={{width:'100%',height:'100%',overflow:'hidden'}}>
                    {/* platformId:1-保利威视；2-百家云 */}
                    {sectionInfo.platformId==1?
                    <LivePlayer channelId={sectionInfo.channelId} sectionInfo={sectionInfo} {...this.props}></LivePlayer>:
                    (sectionInfo.type===1?
                    <BjyLivePlayer channelId={sectionInfo.channelId} sectionInfo={sectionInfo} {...this.props}></BjyLivePlayer>:
                    <BjyVideo channelId={sectionInfo.channelId} sectionInfo={sectionInfo} {...this.props}></BjyVideo>)
                    }
                </div>
            )
        }else{
            return (
                <div style={{height:'100%'}}>
                    <PolyvVideo src={sectionInfo.videoUrl} {...sectionInfo} {...this.props}></PolyvVideo>
                </div>
            )
        }
    }
    render(){
        return (
            <div style={{width:'100%',height:'100%'}}>
                {this.createDome()}
            </div>
        )
    }
}

export default PlayerBox