export default {
    menus: [    // 菜单相关路由
        // { key: '/login', title: '登录', icon: 'mobile', component: 'Login' },
        { key: '/home', title: '我的班级', icon: 'mobile', component: 'Home'},
        { key: '/home/modular', title: '课程模块', icon: 'mobile', component: 'Modular'},
        { key: '/home/exercise', title: '模块练习', icon: 'mobile', component: 'Exercise'},
        { key: '/home/yearTruth', title: '历年真题', icon: 'mobile', component: 'YearTruth'},
        { key: '/home/myError', title: '我的错题', icon: 'mobile', component: 'MyError'},
        { key: '/home/myCollection', title: '我的收藏', icon: 'mobile', component: 'MyCollection'},
        { key: '/home/myCollection/detail', title: '我的收藏-详情', icon: 'mobile', component: 'MyCollectionDetail'},
        { key: '/home/error/analysis', title: '错题解析', icon: 'mobile', component: 'ErrorAnalysis'},
        { key: '/home/examResult', title: '考试结果', icon: 'mobile', component: 'ExamResult'},
        { key: '/home/VedioStudy', title: '看视频', icon: 'mobile', component: 'VedioStudy'},
        { key: '/home/intelligent', title: '智能练习', icon: 'mobile', component: 'Intelligent'},
        { key: '/home/agreementList', title: '签署协议', icon: 'mobile', component: 'AgreementList'},
    ],
    other:[
        { key: '/login', title: '登录', component: 'Login'},
    ]
}