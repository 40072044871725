import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Icon, message, Modal, Form, Button } from 'antd'

import tool from '../../utils/commonFuc'

const ContactUsModal = (propsObj)=>{
    const [state,setState] = useState({
        videoUrl:'',
        iframeUrl:'',//直播地址
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
        // if(propsObj.luboVideoUrl){
        //     updateState({videoUrl: propsObj.luboVideoUrl})
        // }else {
        //     updateState({videoUrl: ''})
        // }
        // if(propsObj.iframeUrl){
        //     updateState({iframeUrl: propsObj.iframeUrl})
        // }else {
        //     updateState({iframeUrl: ''})
        // }
        // ,[propsObj.luboVideoUrl,propsObj.iframeUrl]
    })
    const handleCancel = ()=>{
        propsObj.setPlayerModalStatus()
    }

    return <Modal title="" visible={propsObj.showPlayerModal} onCancel={handleCancel} footer={null} width={1200}>
        <div style={{padding:'30px 0px 10px'}}>
            {
                propsObj.iframeUrl ? 
                    <iframe style={{width:'100%',height:'600px'}}
                    name="my_liveing_player"
                    title="直播页面"
                    scrolling="no"
                    frameBorder="0"
                    id="my_liveing_player"
                    src={propsObj.iframeUrl}
                    allowfullscreen="true"
                    allowtransparency="true"
                ></iframe> :
                <video id="video-content" src={propsObj.luboVideoUrl} controls style={{height:'100%',width:'100%'}} autoPlay controlsList="nodownload"></video>
            }
        </div>
    </Modal>
}
export default Form.create({})(withRouter(ContactUsModal))