import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { getModuleTestData } from '../../actions/exam'
import { moduleTestData } from '../../reducer/exam'
import { Breadcrumb } from 'antd';

import { getMyClassData } from '../../actions/class'
import { myClassSubjectData } from '../../reducer/class'

import SubejectSwitcher from '../../components/exam/SelectSubject'
import MainBox from '../../components/MainBox'
import Exercise from '../../views/Exercise/Exercise'
import XqdExamList from '../../views/Exercise/XqdExamList'
import tool from '../../utils/commonFuc'



class ExerciseContainers extends React.Component{
    static propTypes = {
        moduleTestData: PropTypes.array,
        getModuleTestData: PropTypes.func
    }
    state = {
        requstData:{
            productId:null,
            teachPlanId:null,
        },
        isValid:1,
        subjectType:0,
    }
    componentWillMount(){
        if(this.props.myClassSubjectData.length <= 0){
            this.props.getMyClassData({})
        }
        let subjectType = sessionStorage.getItem('subjectType')//1-自营；2-外部产品新起点
        this.setState({subjectType})
        let obj = {
            teachPlanId: sessionStorage.getItem('teachPlanId'),
            subjectId: sessionStorage.getItem('subjectId'),
            tagId:tool.getQueryString(this.props.history,'tagId') || sessionStorage.getItem('tagId')
        }
        if(subjectType==2){
            obj = {
                subjectId: sessionStorage.getItem('subjectId'),
                paperTypeId:tool.getQueryString2('paperTypeId')
            }
        }else{
            this.props.getModuleTestData(obj)
        }
    }
    goBack = () => {
        this.props.history.push({pathname:'/home',query:{index:2}})
    }
    render(){
        const { myClassSubjectData, getMyClassData, getModuleTestData } = this.props
        return (
            <div>
                {/* <SubejectSwitcher myClassSubjectData={myClassSubjectData} getMyClassData={getMyClassData} refreshPropsData={getModuleTestData} /> */}
                {/* <SubejectSwitcher></SubejectSwitcher> */}
                <div className='exercise-list-title-wrap'>
                    <div className='exercise-list-title'>{sessionStorage.getItem('subjectName')}</div>
                </div>
                <div style={{width:'1200px',margin:'0 auto',height:'56px',display:'flex',alignItems:'center'}}>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item onClick={this.goBack} style={{cursor:'pointer'}}>题库中心</Breadcrumb.Item>
                        <Breadcrumb.Item>{sessionStorage.getItem('tagName')}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/* 自营 */}
                {this.state.subjectType==1 && <Exercise history={this.props.history} moduleTestData={this.props.moduleTestData}></Exercise>}
                {/* 外部产品（新起点） */}
                {this.state.subjectType==2 && <XqdExamList history={this.props.history} moduleTestData={this.props.moduleTestData}></XqdExamList>}
            </div>
        )
    }
}

const mapStateToProps  = (state) =>({
    moduleTestData:moduleTestData(state.exam),
    myClassSubjectData: myClassSubjectData(state.myClass)
})

const mapDispatchToProps = (dispatch) => ({
    getModuleTestData: bindActionCreators(getModuleTestData,dispatch),
    getMyClassData: bindActionCreators(getMyClassData,dispatch)
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExerciseContainers))