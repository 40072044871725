import React, { memo, useEffect, useRef, useState } from 'react';
import { Pagination } from 'antd';
import './index.scss'
import api from '../../api/homePage.js'
import TeacherInfoCard from './component/TeacherIntroductMoudle/TeacherInfoCard';
import tool from '../../utils/commonFuc'
//教师介绍
const Teacher = memo((props) => {
    const [state,setState] = useState({
        pageNum:1,
        rows:20, 
        
        total:0,
        teacherData:[]
        
    })
    //更新state
    const updateState = (newState)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    useEffect(()=>{
        getTeacherData()
       
    },[])
    const getTeacherData = (rows,page) => {  //获取更多教师数据
        let parmasObj = {
            page:page ? page :state.pageNum,
            rows:rows ? rows :state.rows,
            id: Number(tool.getQueryString2('id')) || null,
            rentId:sessionStorage.getItem('rentId') || null
        }
        api.getMoreTeacher(parmasObj,res=>{
            console.log("教师列表",res,parmasObj);
            updateState({
                teacherData:res.recordList,
                total:res.totalCount,
                rows:rows ? rows : parmasObj.rows,
                pageNum:page?page : parmasObj.page
            })
        })
    }
        
      // 改变页码
    const changePage = (val)=>{
        // updateState({page:val})
        getTeacherData(state.rows,val)
    }
    const onShowSizeChange = (current, pageSize) =>{ //改变每页条数
        console.log("current==",current,'pageSize',pageSize);
        // updateState({rows:pageSize,pageNum:1})
        getTeacherData(pageSize,1)
    }
    return (
        <div className="home-page-wrap"> 
            <div  style={{margin:'20px auto',width:'1200px'}}>
                <div className='header-main-box'>
                    <span className='header-title'>{tool.getQueryString2('mainTitle') || ''}</span>
                    <span className='header-subTitle'>{tool.getQueryString2('subTitle') || ''}</span>
                </div>
                <div className='teacher-intro-card-module' >
                    { state.teacherData.length > 0&& state.teacherData.map((v,i)=>{
                        return  <TeacherInfoCard key={v.id} info={v} lineNum={4} />
                        
                    })}
                </div>
            </div>
            {state.teacherData.length>0 
            && <Pagination showTotal={total => `共 ${total} 条`} 
                            showSizeChanger 
                            onShowSizeChange={onShowSizeChange} 
                            className="teacher-list-page" 
                            showQuickJumper 
                            current={state.pageNum} 
                            defaultCurrent={1} 
                            total={state.total} 
                            pageSize={state.rows} 
                            onChange={changePage} />}

           
        </div>
    );
});

export default Teacher;