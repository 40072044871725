/**
 * 路由组件出口文件
 * zhoujiafeng 2019年5月27日
 */
// import Loadable from 'react-loadable';
// import Loading from './widget/Loading';
import LoadableComponent from '../utils/LoadingPage'
// const WysiwygBundle = Loadable({    // 按需加载富文本配置
//     loader: () => import('./ui/Wysiwyg'),
//     loading: Loading,
// });

// 首页模块
const HomePage = LoadableComponent( () => import('../containers/HomePage/HomePageContainers.js'))
const Course = LoadableComponent( () => import('../containers/HomePage/CourseContainers.js'))
const CourseDetail = LoadableComponent( () => import('../containers/HomePage/CourseDetailContainers.js'))
const AboutUs = LoadableComponent( () => import('../containers/HomePage/AboutUsContainers.js'))
const Home = LoadableComponent( () => import('../containers/Home/HomeContainers.js'))
// const Home = LoadableComponent( () => import('../containers/Exercise/ExerciseContainers.js'))
const Login = LoadableComponent( () => import('../containers/Login/startPageContainers'))
const Modular = LoadableComponent( () => import('../containers/Modular/ModularContainers.js'))
const Exercise = LoadableComponent( () => import('../containers/Exercise/ExerciseContainers.js'))
const MyError = LoadableComponent( () => import('../containers/MyError/MyErroorContainers'))
const MyCollection = LoadableComponent( () => import('../containers/MyCollection/MyCollectionContainers'))
const MyCollectionDetail = LoadableComponent( () => import('../containers/MyCollection/MyCollectionDetailContainers'))
const YearTruth = LoadableComponent( () => import('../containers/YearTruth/YearTruthContainers'))  // 历年真题、专项练习等页面
const ErrorAnalysis = LoadableComponent( () => import('../containers/ErrorAnalysis/ErrorAnalysisContainers'))  // 错题解析
const ExamResult = LoadableComponent( () => import('../containers/ExamResult/ExamResultContainers'))  // 结果页面
const VedioStudy = LoadableComponent( () => import('../containers/VedioStudy/VedioStudy.js'))
const Intelligent = LoadableComponent( () => import('../containers/Intelligent/IntelligentContainers.js'))
const AgreementList = LoadableComponent( () => import('../containers/AgreementList/AgreementListContainers.js'))
export default {
    HomePage,
    Course,
    CourseDetail,
    AboutUs,
    Login,
    Home,
    Modular,
    Exercise,
    YearTruth,
    MyError,
    MyCollection,
    MyCollectionDetail,
    ErrorAnalysis,
    ExamResult,
    VedioStudy,
    Intelligent,
    AgreementList,
}