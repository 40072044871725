import React,{useState,useEffect,useContext} from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Icon, message, Modal, Form, Button } from 'antd'
import { TestContext } from '../../containers/HomePage/CourseContainers'

import api from '../../api/homePage.js'
import loginApi from '../../api/login'
import tenantApi from '../../api/tenant'
import tool from '../../utils/commonFuc'

import ContactUsModal from '../contactUsModal/index'
import PerfectModal from '../../views/home/PerfectModal'
import AccountModal from '../../views/home/AccountModal'


import {floatComponentList} from '../../reducer/componentModule'
import { getFloatComponentAction } from '../../actions/componentModule'
import './index.scss'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
const { Search } = Input
const Header = (propsObj)=>{
    const AppContext = useContext(TestContext)
    const [state,setState] = useState({
        navType:-1,//当前激活的导航
        titleBtnArr:[
            {path:'/homePage',name:'首页'},
            {path:'/course',name:'选课程'},
            {path:'/home',name:'班级学习',tabIndex:1},
            {path:'/home',name:'练习题库',tabIndex:2},
            {path:'/home',name:'学习资料',tabIndex:3},
            // {path:'/aboutUs',name:'关于我们'},
        ],
        showLogin:undefined,//展示登录按钮
        showSearch:false,//展示搜索栏
        keyword:'',//搜索关键词
        userInfo:{},//用户信息
        tenantInfo:{},//机构信息
        // weChatInfo:{},//微信信息
        // wechatPublicNumQrCode:'',//微信公众号
        // androidAppQrCode:'',//安卓APP下载二维码
        // iosAppQrCode:'',//苹果APP下载二维码
        // 模态框
        visible:false,
        passwordModal:false,
        showContactUsModal:false,//报考咨询模态框
        accountModal: false, // 切换账号
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
        const init = async()=>{
            let pathname = propsObj.location.pathname
            let navType = -1
            switch (pathname){
                case '/homePage':
                    navType = 0
                    break
                case '/course':
                    navType = 1
                    break
                case '/courseDetail':
                    navType = 1
                    break
                case '/newExamTest':
                    navType = 2
                    break
                case '/newVedioPlay':
                    navType = 2
                    break
                case '/newLivingPlay':
                    navType = 2
                    break
                case '/home':
                    
                    let tabIndex = tool.getQueryString(propsObj.history,'tabIndex')
                    if(tabIndex==2){
                        navType = 3
                    }else if(tabIndex==3){
                        navType = 4
                    }else{
                        navType = 2
                    }
                    break
                case '/aboutUs':
                    navType = 5
                    break
            }
            // 班级学习和练习题库二级页面
            if(pathname.indexOf('/home/')>-1){
                if(pathname == '/home/VedioStudy'){
                    navType = 2
                }else{
                    navType = 3
                }
            }
            // console.log("navType",navType,pathname);
            if(pathname.indexOf('/teacher') > -1){ //更多教师页面，默认选中首页tab
                navType = 0
            }
            // 是否已经登录
            let token = sessionStorage.getItem('token')
            let userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {}
            let showLogin = true
            if(token){
                showLogin = false
            }
            const init2 = async(rentId,rentInfo)=>{
                // 获取关于我们
                let tenantInfo = {}
                let aboutUsObj = {}
                // let weChatInfo = {}
                if(rentInfo){
                    tenantInfo = rentInfo
                }else{
                    if(rentId){
                        tenantInfo = await tenantApi.getTenantInfo({rentId:rentId})
                    }
                }
                if(tenantInfo.id){
                    try {
                        propsObj.setOrgInfo(tenantInfo)//设置父级机构信息
                    } catch (error) {
                    }
                }
                if(!propsObj.isTeacherIntro){

                    document.title = tenantInfo.name
                }
                aboutUsObj = await api.getAboutus({rentId:rentId})
                if(propsObj.showContactBtn && propsObj.floatComponentList && propsObj.floatComponentList.length <=0){

                    propsObj.getFloatComponentAction({rentId:sessionStorage.getItem('rentId'),tryFlag: 0,clientType :3})
                }

                // weChatInfo = await api.getQryIndex({configType:3,rentId:rentId})
                // let wechatPublicNumQrCode = await api.getQryIndex({configType:5,rentId:rentId})
                // let androidAppQrCode = await api.getQryIndex({configType:6,rentId:rentId})
                // let iosAppQrCode = await api.getQryIndex({configType:7,rentId:rentId})
                let titleBtnArr = state.titleBtnArr
                if(aboutUsObj && aboutUsObj.aboutUs){
                    state.titleBtnArr.push({path:'/aboutUs',name:'关于我们'})
                }
                updateState({
                    titleBtnArr:titleBtnArr,
                    navType:navType,
                    showLogin:showLogin,
                    userInfo:userInfo || {},
                    keyword:(propsObj.history.location.query && propsObj.history.location.query.keyword) || '',
                    tenantInfo:tenantInfo || {},
                    // weChatInfo:weChatInfo && weChatInfo.length>0?weChatInfo[0]:{},
                    // wechatPublicNumQrCode:wechatPublicNumQrCode && wechatPublicNumQrCode.length>0?wechatPublicNumQrCode[0].wechatPublicNumQrCode:'',
                    // androidAppQrCode:androidAppQrCode && androidAppQrCode.length>0?androidAppQrCode[0].androidAppQrCode:'',
                    // iosAppQrCode:iosAppQrCode && iosAppQrCode.length>0?iosAppQrCode[0].iosAppQrCode:'',
                })
                let favicon = document.createElement("link");
                favicon.rel = "icon";
                favicon.id = "favicon_icon";
                favicon.href = tenantInfo.iconImg?tenantInfo.iconImg:'https://file.mshiedu.com/POKO-icon-none.png';
                document.head.appendChild(favicon);
                let originOrgType = tenantInfo.originOrgType
                let root = document.getElementById('root')
                if(originOrgType){//新慧简学机构
                    root.setAttribute('class','blue-theme')
                }
            }
            //机构ID
            let rentId = tool.getQueryString(propsObj.history, 'rentId') || sessionStorage.getItem('rentId')
            if(rentId){
                init2(rentId)           
                sessionStorage.setItem('rentId',rentId)
            }else{
                let pcUrl = window.location.hostname
                let hostname = tool.getQueryString(propsObj.history, 'hostname') //测试专用
                if(hostname){
                    pcUrl = hostname
                }
                let arr = [
                    'localhost',
                    'aline.mshengedu.com',
                    'study.mshiedu.com',
                    'study.mshengedu.com',
                    'study.myunedu.com',
                    'grey.mshiedu.com',
                    'grey.mshengedu.com',
                ]
                let flag = true
                for(let v of arr){
                    if(pcUrl.indexOf(v)>-1){
                        flag = false
                        if(!sessionStorage.getItem('token') && window.location.hash.includes('#/homePage')){
                            propsObj.history.replace({pathname:'/phoneLogin/index'})
                        }
                        break
                    }
                }
                if(!flag){//没rentId情况下，本地、测试、线上域名打断
                    return
                }
                let rentInfo = await tenantApi.getTenantInfo({pcUrl:pcUrl})
                if(rentInfo){   
                    window.pcUrl = pcUrl
                    sessionStorage.setItem('pcUrl',pcUrl)                 
                    sessionStorage.setItem('rentId',rentInfo.id)
                    init2(rentInfo.id,rentInfo)
                    // 机构ID还没缓存，所以其他页面没调接口
                    // window.location.reload() //解决需要手动刷新才能在调取轮播图、热门课程时有rentId的问题
                }
            }
           
        }
        init()
        
    },[])
    // 跳转
    const jumpPage = (path,i,tabIndex)=>{
        let search = ''
        if(tabIndex){
            search = `tabIndex=${tabIndex}`
            localStorage.setItem('tabIndex',tabIndex)
        }
        updateState({navType:i})
        if(i==2 || i==3){
            let token = sessionStorage.getItem('token')
            if(!token){
                updateState({navType:i})
                propsObj.history.push({pathname:'/phoneLogin/passLogin'})
                return
            }
        }
        propsObj.history.push({pathname:path,search:search})
    }
    // 展示搜索栏
    const showSearchWrap = ()=>{
        updateState({
            showSearch:!state.showSearch
        })
    }
    const changeKeyword = (e)=>{
        updateState({keyword:e.target.value})
        if(AppContext.setKeyword){
            AppContext.setKeyword(e.target.value)
        }
    }
    const searchCouse = ()=>{
        showSearchWrap()
        if(propsObj.location.pathname == '/course'){
            // propsObj.
            AppContext.setSearchStatus(true,state.keyword)
        }else{
            propsObj.history.push({pathname:'/course',query:{keyword:state.keyword}})
        }
    }
    // 跳转
    const toLogin = ()=>{
        propsObj.history.push({pathname:'/phoneLogin/index'})
    }
    // 
    const toTop = ()=>{
        window.scrollTo(0,0)
    }
    const showATag = () => {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {}
        if(userInfo.accountId && userInfo.accountId!==''){
            return <div style={{cursor:'default'}}>学员：{userInfo.name}</div>
        }else {
            return <div style={{cursor:'pointer'}} onClick={handleATag}>完善身份信息</div>
        }
    }
    
    const handleATag = (e) => {
        e.preventDefault();
        updateState({visible: true})
    }
    const handleCancel = (e) => {
        updateState({visible: false,passwordModal:false, accountModal: false})
        propsObj.form.resetFields()
    }
    
    const loginOut = () => {
        Modal.confirm({
            title:'即将退出登录',
            onOk: () => {
                loginApi.loginOut({},res => {
                    if(res){
                        let index = window.location.href.indexOf('#')
                        let url = window.location.href.slice(0,index)
                        let search = ''
                        let rentId = sessionStorage.getItem('rentId')
                        if(rentId && rentId != 'null' && rentId!=1 && rentId!=8){
                            url = '/homePage'
                            let pcUrl = sessionStorage.getItem('pcUrl')
                            console.log("pcUrl-333-----------",pcUrl);
                            if(pcUrl){
                                // sessionStorage.removeItem('pcUrl')
                            }else{
                                search = `?rentId=${rentId}`
                            }
                            window.location.replace(`${window.location.protocol}//${window.location.host}${window.location.pathname}#${url}${search}`)
                            window.location.reload()
                        }else{
                            window.location.href=url + tool.appendRentId('#/phoneLogin/index');
                        }
                        // propsObj.history.push(tool.getPushLoginParam())
                        // window.location.reload()
                    }
                })
            },

            onCancel: () => {

            },
        })
    }
    // 切换账号
    const showAccountModal = (e) => {
        updateState({accountModal: true})
        // console.log('12',this.refs.account)
        // console.log('1',this)
        // this.ref.account.getStudentList()
    }
    // 修改密码
    const showPasswordModal = (e)=>{
        e.preventDefault();
        updateState({passwordModal: true})
    }
    const updatePassword = (e)=>{
        e.preventDefault();
        propsObj.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                updateState({loading: true})
                loginApi.updatePassword(values,res=>{
                    updateState({loading: false})
                    if(res){
                        message.success('密码修改成功，请重新登录，2秒后自动跳转到首页')
                        handleCancel()
                        setTimeout(() => {
                            loginApi.loginOut({},res => {
                                console.log('logout->',res)
                                if(res){
                                    propsObj.history.push(tool.getPushLoginParam())
                                    window.location.reload()
                                }
                            })
                        }, 2000);
                    }
                })
            }
        });
    }
    const filterAccount = (e) => {
        let data = JSON.parse(sessionStorage.getItem('userInfo'))
        let qryParam = ''
        let phone =  ''
        let cardNo = ''
        if (data && data.qryParam) {
            qryParam =  data.qryParam 
        }
        if (data && data.phone) {
            phone =  data.phone 
        }
        if (data && data.cardNo) {
            cardNo =  data.cardNo 
        }
        if (qryParam === phone || qryParam === cardNo) {
            console.log('手机号或证件号登录，不展示已切换账号')
            return false
        }else {
            console.log('账户名登录，展示切换账号')
            return true
        } 
    }

    const formItemLayout = {
        labelCol: {
          xs: { span: 0 },
          sm: { span: 0 },
        },
        wrapperCol: {
          xs: { span: 16 },
          sm: { span: 24 },
        },
    }
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 0,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 0,
            },
        },
    }
    const { getFieldDecorator } = propsObj.form;

    // 设置模态框
    const setContactUsModalStatus = ()=>{
        updateState({showContactUsModal:!state.showContactUsModal})
    }
    const contactInfo = propsObj.floatComponentList && propsObj.floatComponentList.filter(val => val.type !== 1)[0]  //悬浮模块也是需要后台去配置的。所以这个页面也需要改造
    console.log("======contactInfo=====",contactInfo);
    return <div className="header-wrap">
        <div className="header-content">
            {/* logo */}
            <img className="header-content-logo" src={state.tenantInfo.logo?state.tenantInfo.logo:/* require('../../assets/homePage/logo.png') */''} />
            {/* 导航 */}
            <div className="title-btn-wrap">
                {state.titleBtnArr.map((v,i)=>{
                    return <div className={`${state.navType==i?'active':''} title-btn-item`} key={i} onClick={()=>{jumpPage(v.path,i,v.tabIndex)}}>{v.name}</div>
                })}
            </div>
            {/* 按钮 */}
            <div className="header-btn-bar">
                <div className="search-btn" onClick={showSearchWrap}>
                    <img className="search-icon" src={require('../../assets/homePage/search.png')} />搜索</div>
                    {state.showLogin && <div className="login-btn" onClick={toLogin}>登录</div>}
                    {/* 头像 */}
                    {state.showLogin===false && <div className="avatar-module">
                        <div className='avatarUrl'>
                            <img width="100%" height="100%" alt="这里是一张图片" src={state.userInfo.avatarUrl} />
                        </div>
                        <div className="avatar-btn-list">
                            {showATag()}
                            <div style={{cursor:'pointer'}} onClick={showPasswordModal}>修改密码</div>
                            {filterAccount() && <div style={{cursor:'pointer'}} onClick={showAccountModal}>切换账号</div>}
                            <div style={{cursor:'pointer'}} onClick={loginOut}>退出登录</div>
                        </div>
                    </div>}
                {state.showLogin && <div className="contact-btn" onClick={setContactUsModalStatus}>{sessionStorage.getItem('rentId') == '1208' ? '免费注册' : '报考咨询'}</div>}
            </div>
            {/* 切换账号模态框 */}
            <Modal title="切换账号" visible={state.accountModal} onCancel={handleCancel} width={419} footer={null}>
                <AccountModal></AccountModal>
            </Modal>
            {/* 模态框 */}
            <Modal title="完善身份信息"
                visible={state.visible}
                onCancel={handleCancel}
                footer={null}
                width={440}
            >   
                <PerfectModal visible={state.visible}></PerfectModal>
            </Modal>
            {/* 修改密码模态框 */}
            <Modal title="修改密码"
                visible={state.passwordModal}
                onCancel={handleCancel}
                footer={null}
                width={419}
            >
                <Form {...formItemLayout} onSubmit={updatePassword} className="update-password-form" style={{width:'344px',margin:'0 auto'}}>
                    <Form.Item label="">
                        {getFieldDecorator('passwd', {
                            rules: [
                                {required: true,message: '请输入原密码',whitespace:true},
                            ],
                        })(<Input.Password style={{height:'50px'}} maxLength={12} placeholder="请输入原密码" />)}
                    </Form.Item>
                    <Form.Item label="">
                        {getFieldDecorator('newPasswd', {
                            rules: [
                                // {required: true,message: '请输入8-12位新密码',whitespace:true},
                                {
                                    validator:(rule, value, callback) => {
                                        let reg = /(?!.*[\u4E00-\u9FA5\s])(?!^[a-zA-Z]+$)(?!^[\d]+$)(?!^[^a-zA-Z\d]+$)^.{8,12}$/
                                        console.log(reg.test(value))
                                        if(value && reg.test(value)){
                                            callback()
                                        }else{
                                            callback('请输入8~12位字符，密码至少由字母，数字或特殊符号中2种字符组成区分大小写')
                                        }
                                        }
                                }
                            ],
                        })(<Input.Password style={{height:'50px'}} maxLength={12} placeholder="请输入新密码" />)}
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={state.loading} style={{fontSize: '18px',color:'#fff',background:'#396BF0',width:'344px',height:'50px',border:'none',borderRadius:25}}>确定</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <div className="search-wrap" style={{display:state.showSearch?'block':'none'}}>
                <div className="search-wrap-close" onClick={showSearchWrap}></div>
                <div className="search-wrap-content">
                    <Input className="search-input" value={state.keyword} onChange={changeKeyword} placeholder="请输入想要查找的课程名称" maxLength={50} onPressEnter={searchCouse} />
                    <div className="search-input-btn" onClick={searchCouse}>搜索课程</div>
                </div>
            </div>
        </div>
        {/* 报考咨询 */}
        {propsObj.showContactBtn && <div className="contact-module-wrap">
        {(state.showLogin || (contactInfo && contactInfo.floatList.length > 0)) && <div className="contact-btn-wrap">
            {
                // 登录之后不展示报考咨询
                state.showLogin && 
                <div  className='contact-btn-item'>
                    <div onClick={setContactUsModalStatus}>
                        <img src={require('../../assets/homePage/contact.png')} />
                        {sessionStorage.getItem('rentId') == '1208' ? '免费注册' : '报考咨询'}
                    </div>
                </div>
            }
           
            {
                contactInfo && contactInfo.floatList.map((item,i)=>{
                    return <div key={item.id} className='contact-btn-item'>
                        {item.type === 2 && item.isVisible && <div className="QR-code-module">
                            <img src={require('../../assets/homePage/weixin.png')} />
                            微信咨询
                            <img className="QR-code" src={item.pictureUrl} />
                        </div>}
                        {item.type === 3 && item.isVisible && <div className="QR-code-module">
                            <img src={require('../../assets/homePage/icon_weixingzh@2x.png')} />
                            微信公众号
                            <img className="QR-code" src={item.pictureUrl} />
                        </div>}
                        {item.type === 4 && item.isVisible && <div className="QR-code-module">
                            <img src={require('../../assets/homePage/icon_Android@2x.png')} />
                            官方安卓App
                            <img className="QR-code" src={item.pictureUrl} />
                        </div>}
                        {item.type === 5 && item.isVisible  && <div className="QR-code-module">
                            <img src={require('../../assets/homePage/icon_iOS@2x.png')} />
                            官方IOS App
                            <img className="QR-code" src={item.pictureUrl} />
                        </div>}
                    </div>
                })
            }
            </div>
            }
            <div className="to-top" onClick={toTop}>
                <img src={require('../../assets/homePage/to-top.png')} />
            </div>
        </div>}
        {/* 模态框 */}
        <ContactUsModal showContactUsModal={state.showContactUsModal} setContactUsModalStatus={setContactUsModalStatus}></ContactUsModal>
    </div>
}
const mapStateToProps = (state)=>({
    floatComponentList:floatComponentList(state.componentModuleReducer)

})
const mapDispatchToProps = (dispatch) =>({
    getFloatComponentAction:bindActionCreators(getFloatComponentAction,dispatch)
})
export default Form.create({})(withRouter(connect(mapStateToProps,mapDispatchToProps)(Header)))
// export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Header))