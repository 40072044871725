import React from 'react'

import './exercise.scss'
import { Collapse } from 'antd'
import ExericeList from './ExericeList'
import yuanImg from '../../assets/img/yuan@2x.png'

const { Panel } = Collapse;

function callback(key) {
    console.log(key);
}
class exercise extends React.Component {
    state = {
    }
    componentWillMount(){
        console.log(this.props)
    }
    componentWillReceiveProps(newProps) {
    }
    componentWillUnmount() {
        // 离开当前页面的时候，清空数据
    }
    render() {
        return <div className="modular_content_box">
            <Collapse defaultActiveKey={['0']} onChange={callback} expandIconPosition="right">
                {this.props.moduleTestData.map((item, index) => {
                    return (
                        <Panel header={
                        <div style={{display:'flex',alignItems:'center'}}>
                        <img width="16px" height="16px" style={{marginRight:'9px'}} src={yuanImg} alt="图片"></img>
                        <b className="moduleName">{item.moduleName}</b>
                        </div>} key={index}>
                        {(item.paperList && item.paperList.length>0) && item.paperList.map( (paper,paperIndex) => (
                            <ExericeList paperData={paper} key={paperIndex}></ExericeList>
                        ))}
                        </Panel>
                    )
                })}
            </Collapse>
        </div>
    }
}

export default exercise