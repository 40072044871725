/**
 * Created by 肥智 on 2019.5.30.
 */
import { combineReducers } from 'redux';
import * as type from '../constants/ActionTypes';
import user from './user'
import myClass from './class'
import exam from './exam'
import yearTruth from './yearTruth'
import intelligent from './intelligent'
import errorIntelligent from './myError';
import componentModuleReducer from './componentModule' //组件模板数据
const meunForm = {
    meunSelect:'',
    radioGroup:''
}

const handleData = (state = meunForm, action) => {
    switch (action.type) {
        case type.REQUEST_DATA:
            return action.params;
        case type.RECEIVE_DATA:
            let obj = Object.assign({},state,{tableList:action.data.data.recordList,tableTotal:action.data.data.totalCount})
            return obj;
        default:
            return {...state};
    }
};
const httpData = (state = {}, action) => {
    switch (action.type) {
        case type.RECEIVE_DATA:
        case type.REQUEST_DATA:
            return {
                ...state,
                [action.category]: handleData(state[action.category], action)
            };
        default:
            return {...state};
    }
};

export default combineReducers({
    handleData,
    httpData,
    user,
    myClass,
    exam,
    yearTruth,
    intelligent,
    errorIntelligent,
    componentModuleReducer
});
