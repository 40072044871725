import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import MainBox from '../../components/MainBox'
import MyHeader from '../../components/Header/index'
import { Layout } from 'antd'
import NewLivingPlayer from '../../views/NewVedioStudy/NewLivingPlayer'
import tool from '../../utils/commonFuc'
const { Header, Content } = Layout;
class NewLivingPlayContainers extends React.Component{
    state = {
        section:{
        },
        liveId:null,
        liveCourseName:'',
        isFree:null,
    }
    
    componentWillMount(){
        this.setState({isFree:tool.getQueryString2('isFree')})
        console.log("this.props",tool.getQueryString2('id'));
        // if(this.props.location.state){
        //     console.log("课程详情跳转播放视频页",this.props.location.state);
        //     // return
        //     sessionStorage.setItem('livingSection',JSON.stringify(this.props.location.state)) 
        //     this.setState({
        //         section:this.props.location.state,
        //     })
        // }else{
        //     this.setState({
        //         section:JSON.parse(sessionStorage.getItem('livingSection')),

        //     })
        // }
        this.setState({
            liveId:tool.getQueryString2('id'),
            liveCourseName:tool.getQueryString2('liveCourseName'),
            isFree:tool.getQueryString2('isFree'),
            status:tool.getQueryString2('status') || 0,
        })
    }
    componentWillUnmount(){  //离开页面时，清除reducer缓存
       
    }
    render(){
        const {section,liveCourseName,liveId} = this.state
        return(
            <div>
                <Layout style={{minHeight:'100vh'}}>
                    {/* 头部 */}
                    <Header style={{backgroundColor:'#ffffff'}}>
                        <MyHeader pathname={this.props.location.pathname} {...this.props.history} showContactBtn={false}></MyHeader>
                    </Header>
                    {/* <MainBox> */}
                    <NewLivingPlayer liveCourseName={liveCourseName} liveId={liveId} section={section} isFree={this.state.isFree}></NewLivingPlayer>
                    {/* </MainBox> */}
                </Layout>
            </div>
        )
    }
}
export default withRouter(NewLivingPlayContainers)