import React, { Component } from 'react';
import {} from 'antd'
import './index.scss'
import noFoundImg from '../../assets/img/404.png'

class MyComponent extends Component {    
    render() {
        return (
            <div className="noFoundContent">
                <div className="noFoundBox">
                        <img src={noFoundImg} alt=""/>
                        <p>很抱歉，页面不存在~</p>
                        <p>请刷新试试或者联系管理员</p>
                </div>
            </div>
        )
    }
}
export default MyComponent;