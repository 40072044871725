import React from 'react'

import './iframe.scss'
import api from '../../api/class'
import { Icon } from 'antd'
import moment from 'moment'
/* eslint-disable */
class BjyLivePlayer extends React.Component{
    state = {
        channelId:'',
        sectionInfo:{},
        startTime:undefined,
        liveStatus:'unknow',//直播状态
        timer:null,//定时器
        timer2:null,//监听进入直播间后，还没开始直播到直播状态的定时器
        params:{
            room_id:'string',//房间ID
            user_number:'int',//合作方账号体系下的用户ID号
            user_name:'string',//显示的用户昵称
            user_role:'int',//0:学生 1:老师 2:管理员
            sign:'string',//签名
            user_avatar:'',//用户头像
            // group_id:int,//分组号，默认0不分组，只有分组直播才用到
        },
        sign:'',
        randomNumber:undefined,//随机数
        isFullScreen:false
    }
    componentWillMount(){
        let personId = this.props.sectionInfo.freeLive?parseInt(Math.random()*10000000):''//试看用随机
        let userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
        this.setState({
            channelId:this.props.channelId + '',
            sectionInfo:this.props.sectionInfo,
            params:{
                room_id:this.props.channelId+'',
                user_number:userInfo.id || personId,
                user_name:userInfo.name || `游客${parseInt(Math.random()*10000)}`,
                user_role:0,
                user_avatar:userInfo.avatarUrl || '',
            }
        })
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.sectionInfo.id != this.props.sectionInfo.id){
            console.log(999,nextProps,this.props,this.state)
            clearInterval(this.state.timer)//清除计时器
            //保存上一个视频的学习时间
            this.saveStudyRecord()
            location.reload()//BJY不支持单页面应用
        }
    }
    componentDidMount(){
        //获取百家云直播签名
        const {params} = this.state
        api.getBaijiayunLiveSign({
            channelId:this.props.channelId+'',
            personId:params.user_number,
            name:params.user_name,
            avatarUrl:params.user_avatar,
        },res => {
            this.setState({
                sign:res.sign
            })
            this.getStatusByChannelId(1)
        })
        // 检测是否全屏
        window.onresize = ()=> {
            let isFull = this.getfullscreenelement()?true:false
            this.setState({
                isFullScreen:isFull
            })
        }
    }
    componentWillUnmount(){
        if(this.state.sectionInfo.freeLive){//免费试看
            return
        }
        this.saveStudyRecord()
        clearInterval(this.state.timer)//清楚计时器
    }
    //保存学习时间
    saveStudyRecord = () => {
        if(this.state.sectionInfo.freeLive){//免费试看
            return
        }
        const { sectionInfo, channelId, startTime } = this.state;
        // 判断是否新页面打开了直播
        let liveArr = localStorage.getItem('liveArr')
        if(liveArr){
            liveArr = JSON.parse(liveArr)
            for(let v of liveArr){
                if(parseInt(v.channelId) === parseInt(channelId)){
                    if(v.randomNumber !== this.state.randomNumber){
                        console.log('旧的直播页面')
                        clearInterval(this.state.timer)//清楚计时器
                        return
                    }
                    break
                }
            }
        }
        let nowTime = (new Date()).getTime();
        let time = nowTime - startTime
        console.log('startTime------',startTime)
        console.log('nowTime------',nowTime)
        console.log('time------',time)
        if(time>=10000 && startTime>0){
            let obj = {
                sectionType:'1',
                sectionId:sectionInfo.id,
                productId:localStorage.getItem('productId'),
                channelId:channelId,
                studyTime:Math.round(time/1000),
                subjectId:localStorage.getItem('subjectId'),
                beginStudyDate:moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
                endStudyDate:moment(nowTime).format('YYYY-MM-DD HH:mm:ss'),
            }
            let addStudyRecordData = JSON.parse(localStorage.getItem('addStudyRecordData'))
            obj = Object.assign({},obj,addStudyRecordData)
            api.addStudyRecord(obj,(res) => {
                if(res){
                    // 更新课程目录
                    this.props.getChapterSection({moduleId:localStorage.getItem('moduleId')})
                    this.setState({ 
                        startTime:nowTime,
                    })
                }
            });
        }
    }
    //获取直播状态
    getStatusByChannelId = (flag) =>{
        api.getStatusByChannelId({type:2,channelId:this.state.channelId},res => {
            let liveStatus = this.state.liveStatus
            if(liveStatus==='unknow'){
                if(res.status===true){//开始直播了
                    this.setState({
                        liveStatus:'live',
                        startTime:new Date().getTime()
                    })
                    if(flag){//第一次
                        if(flag===1){
                            this.setRandomNumber()//设置随机数
                        }
                        setTimeout(()=>{
                            this.saveStudyRecord()
                        },10000)
                        if(this.state.timer2){
                            clearInterval(this.state.timer2)
                        }
                        console.log(`-----开始直播了，正则监听，5mins一次-----`)
                        this.setState({
                            timer:setInterval(()=>{
                                this.getStatusByChannelId()
                            },300000),
                            timer2:null
                        })
                    }
                }else if(res.status===false){//还没开始直播
                    if(!this.state.timer2){
                        this.setState({
                            timer2:setInterval(()=>{
                                console.log(`-----还没开始直播，正则监听，60s/次-----`)
                                this.getStatusByChannelId(1)
                            },60000)
                        })
                    }
                }
            }else if(liveStatus==='live'){
                if(res.status===true){//还在直播
                    if(this.state.startTime>0){
                        this.saveStudyRecord()
                    }
                }else if(res.status===false){//从直播→下播
                    console.log(`-----中场休息/直播结束，提交学习记录-----`)
                    clearInterval(this.state.timer)//清除计时器
                    if(this.state.startTime>0){//直播结束
                        this.saveStudyRecord()
                    }
                    this.setState({
                        liveStatus:'unknow',
                        timer2:setInterval(()=>{
                            console.log(`-----中场休息，正则监听，60s/次-----`)
                            this.getStatusByChannelId(2)
                        },60000)
                    })
                }
            }
            /* if(this.state.liveStatus==='unknow' && res.status===true){//开始直播了
                this.setState({
                    liveStatus:'live',
                    startTime:new Date().getTime()
                })
                if(flag){//第一次
                    if(flag===1){
                        this.setRandomNumber()//设置随机数
                    }
                    setTimeout(()=>{
                        this.saveStudyRecord()
                    },10000)
                    if(this.state.timer2){
                        clearInterval(this.state.timer2)
                    }
                    console.log(`-----开始直播了，正则监听，5mins一次-----`)
                    this.setState({
                        timer:setInterval(()=>{
                            this.getStatusByChannelId()
                        },300000),
                        timer2:null
                    })
                }
            }else if(this.state.liveStatus==='unknow' && res.status===false){//已经进入直播间了，还没开始直播
                this.setState({
                    timer2:setInterval(()=>{
                        console.log(`-----还没开始直播，正则监听，60s一次-----`)
                        this.getStatusByChannelId(1)
                    },60000)
                })
            }else if(this.state.liveStatus==='live' && res.status===false){//从直播→下播
                clearInterval(this.state.timer)//清除计时器
                if(this.state.startTime>0){//直播结束
                    console.log(`-----中场休息/直播结束，提交学习记录-----`)
                    this.saveStudyRecord()
                }
                this.setState({
                    liveStatus:'unknow',
                    timer2:setInterval(()=>{
                        console.log(`-----中场休息，正则监听，60s一次-----`)
                        this.getStatusByChannelId(2)
                    },60000)
                })
            }
            //每5分钟检索一次，并提交学习记录
            if(this.state.startTime>0 && this.state.liveStatus==='live'){
                this.saveStudyRecord()
            } */
        })
    }

    // 防止多个页面观看上传学习记录，设置随机数
    setRandomNumber = ()=>{
        let randomNumber = Math.floor(Math.random()*100000000)
        this.setState({
            randomNumber:randomNumber
        })
        let obj = {
            channelId:parseInt(this.state.channelId),
            randomNumber:randomNumber
        }
        console.log(`randomNumber=${this.state.randomNumber}`)
        let liveArr = localStorage.getItem('liveArr')
        if(liveArr){
            liveArr = JSON.parse(liveArr)
            let len = 0
            for(let v of liveArr){
                if(parseInt(v.channelId) === parseInt(this.state.channelId)){
                    // if(v.randomNumber)
                    v.randomNumber = randomNumber
                    break
                }else{
                    len++
                }
            }
            if(len === liveArr.length){//没保存
                liveArr.push(obj)
            }
        }else{
            liveArr = [obj]
        }
        localStorage.setItem('liveArr',JSON.stringify(liveArr))
    }
    // 全屏
    getreqfullscreen () {
        var root = document.documentElement
        return root.requestFullscreen || root.webkitRequestFullscreen || root.mozRequestFullScreen || root.msRequestFullscreen
    }
    // 退出全屏
    getexitfullscreen(){
        return document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen
    }
    // 检查全屏
    getfullscreenelement(){
        return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
    }
    // 全屏
    fullScreen(obj){
        let liveing = document.getElementsByClassName('liveing_poliv')[0]
        if(obj.getfullscreenelement()){//已经全屏
            let exitFullscreen = obj.getexitfullscreen()
            exitFullscreen.call(document)
        }else{//非全屏
            let fullscreene = obj.getreqfullscreen()
            fullscreene.call(liveing)
        }
    }
    render(){
        return (
            <div style={{width:'100%',height:'100%'}} id="live-player">
                {this.state.sign&&<iframe style={{width:'100%',height:'100%'}} 
                scrolling="no" frameBorder="0" src={`https://b53939712.at.baijiayun.com/web/room/enter?room_id=${this.state.params.room_id}&user_number=${this.state.params.user_number}&user_role=${this.state.params.user_role}&user_name=${this.state.params.user_name}&user_avatar=${this.state.params.user_avatar}&sign=${this.state.sign}`}></iframe>}
                <div className="full-screen-btn" onClick={()=>{this.fullScreen(this)}}>
                    <Icon type={this.state.isFullScreen?"fullscreen-exit":"fullscreen"} style={{fontSize: '20px'}} />
                    全屏
                    <div className="hover-mask"></div>
                </div>
            </div>
        )
    }
}

export default BjyLivePlayer            