import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import {useKeepAliveEffect} from 'react-keep-alive'
import { Breadcrumb, Tabs, Select, Icon, message, Collapse} from 'antd'
import api from '../../api/homePage.js'
import myClassApi from "../../api/class"
import tenantApi from '../../api/tenant'
import tool from '../../utils/commonFuc'
import ContactUsModal from '../../components/contactUsModal/index'
import FreePlayerModal from '../../components/freePlayerModal/index'
import FreeAuditionVideoModal from './component/FreeAuditionVideoModal'
import icon_suo from '../../assets/homePage/icon_suo@2x.png'
import icon_file from '../../assets/homePage/icon_huoquziliao@2x.png'

const { TabPane } = Tabs
const { Option } = Select
const CourseDetail = (props)=>{
    const [state,setState] = useState({
        productId: null, // 产品id
        courseDetail:{},//课程详情
        courseList:[],//课程列表
        showPlayerModal:false,//视频播放模态框
        luboVideoUrl:'',//外部套餐录播视频url
        iframeUrl:'',//直播地址

        luboCharpterInx: -1, // 录播——点击章展开节，默认折叠
        subjectIndex:-1,//套餐的直播按科目展示，默认折叠
        courseIndex:-1, //套餐的直播科目下展示课程，默认折叠

        luboSubjectIndex:-1,//套餐的录播课程按科目展示，默认折叠
        charpterIndex:-1,//套餐的录播按课程展示，默认折叠

        classInfo:{},//录播课程对象
        liveInfo:{},//直播课程对象
        packageInfo:{},//套餐详情对象
        courseClassList: [], //套餐录播科目课程
        liveCourseList: [], //套餐直播科目课程
        sectionList:[], //套餐录播课程章节详情
        liveList:[],   //套餐直播课程详情
        isFree:null,   //套餐直播课程:是否免费(1.是 2.否)
        showCoursePrice: '',     
        yearList: [], //年份列表
        teacherInfoList: [], //教师列表
        selectYear: '',
        selectTeacher: '',
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }

    const [auditionList,setAuditionList] = useState({
        list:[],//试看列表
        flag:0,//是否含不可试看的节，0否1是
    })
    const [modalState,setModalState] = useState({
        showContactUsModal:false,//报考模态框
        modalType:'reserve',//模态框类型
    })
    const [freeAuditionState,setFreeAuditionState] = useState({
        modalStatus:false,//自营课程试看模态框
		sectionInfo:{},//节信息
    })
    useKeepAliveEffect(()=>{ //更新。第一次进来以及参数有变化时会执行
        let id = tool.getQueryString2('id')
        if(id != state.productId){
            // init(id)
            if (sessionStorage.getItem('rentId')) {
                init(id,sessionStorage.getItem('rentId'))
            } else {
                let pcUrl = window.location.hostname
                let rentObj = {}
                const rentInfo = async ()=>{
                    rentObj = await tenantApi.getTenantInfo({pcUrl:pcUrl})
                    init(id,rentObj.id)
                }
                // setTimeout(() => {
                    rentInfo()
                // }, 2000)
            }
        }
        return ()=>{
            
        }
    })
    const init = (id,rentId)=>{
        let showCourseObj = {}
        let yearList = []
        let teacherInfoList = []
        //查询其他设置
        const getShowCourseObj = async ()=>{
            showCourseObj =  await api.getOtherConfig({clientType: 2, rentId:rentId})
        }

        if(id){
            //查询其他设置
            let showCoursePrice = ''
            api.getOtherConfig({clientType: 3, rentId:rentId}, resObj => {
                showCoursePrice = resObj.showCoursePrice
            })
            api.getCourseDetail({id:id,rentId:rentId},res=>{
                if(res.productOriginType === 1) { //当该产品标识为外部api接入产品，展示课程目录tab，目录根据直播/录播/套餐类型显示不同样式 productOriginType =0 自营 =1 外部产品
                    //externalProductType:外部API产品类型，0-录播，1-直播，2-套餐
                    if (res.externalProductType === 2) { // 2-套餐
                        myClassApi.getNewCourseModularForFree({id:id},re=>{
                                console.log("获取外部课程 -套餐数据",re);
                                updateState({
                                    productId:res.id,
                                    courseDetail:res,
                                    courseList:res.courses || [],
                                    classInfo:re.classInfo || {},
                                    liveInfo:re.liveInfo || {},
                                    courseClassList: re.packageInfo.courseList || [],
                                    liveCourseList: re.packageInfo.liveCourseList || [],
                                    showCoursePrice: showCourseObj.showCoursePrice,
                                    // yearList: yearList,
                                    // teacherInfoList: teacherInfoList
                                })
                            })
                        } else if (res.externalProductType === 1) { //1-直播
                            myClassApi.getNewCourseModularForFree({id:id},re=>{
                                console.log("获取外部课程 -直播数据",re);
                                updateState({
                                    productId:res.id,
                                    courseDetail:res,
                                    courseList:res.courses || [],
                                    liveInfo:re.liveInfo || {},
                                    showCoursePrice: showCourseObj.showCoursePrice
                                })
                            })
                        } else if (res.externalProductType === 0) { //0-录播
                            api.getTeacherFilterParams({externalCourseId: res.externalProductId}, teacherListObj => {
                                yearList = teacherListObj
                                teacherInfoList = teacherListObj[0].teacherInfoList
                                myClassApi.getNewCourseModularForFree({id:id, year: yearList[0].year, externalTeacherId: teacherInfoList[0].externalTeacherId},re=>{
                                    console.log("获取外部课程 -录播数据",re);
                                    
                        
                                    updateState({
                                        productId:res.id,
                                        courseDetail:res,
                                        courseList:res.courses || [],
                                        classInfo:re.classInfo || {},
                                        showCoursePrice: showCourseObj.showCoursePrice,
                                        yearList: yearList,
                                        selectYear: yearList[0].year,
                                        teacherInfoList: teacherInfoList,
                                        selectTeacher: teacherInfoList[0].externalTeacherName
                                    })
                                })
                        })
                    }
                } else {
                    updateState({courseDetail:res,courseList:res.courseList || [],productId:res.id, showCoursePrice: showCoursePrice})
                    // 教学计划
                    // if(res.teachPlans.length>0){
                    //     api.getAuditionListForTeachPlan({teachPlanId:res.teachPlans[0].id},res1=>{
                    //         setAuditionList({list:res1.auditionList || [],flag:res1.flag || 0})
                    //     })
                    // }
                    let data = {
                        list:res.auditionList || [],
                        flag:res.flag || 0
                    }
                    if(data.flag){//是否含不可试看的节，0否1是
                        data.list.push({})
                    }
                    setAuditionList(data)
                }
            })
        }
    }
    const jumpToCourse = (id)=>{
        if(tool.getQueryString2('courseComponentId')){
            props.history.push({pathname:'/course',search:`courseComponentId=${tool.getQueryString2('courseComponentId')}`})
        }else{
            props.history.push({pathname:'/course'})
        }
       
    }

    // 切换报考地区
    const changePlan = (val)=>{
        /* api.getCourseDetailList({teachPlanId:val},res=>{
            updateState({courseList:res || []})
        }) */
        api.getAuditionListForTeachPlan({teachPlanId:val},res=>{
            let data = {
                list:res.auditionList || [],
                flag:auditionList.flag,
            }
            if(data.flag){//是否含不可试看的节，0否1是
                data.list.push({})
            }
            setAuditionList(data)
            updateState({courseList:res.courseList || []})
        })
        
    }
    //切换录播课程年份筛选
    const changeYear = (val) =>{
        console.log(22222222,val);
        updateState({
            teacherInfoList: val.teacherInfoList,
            selectYear: val.year,
            selectTeacher: val.teacherInfoList.length > 0 ? val.teacherInfoList[0].externalTeacherName : '',
        })
    }

    //切换录播课程老师筛选
    const changeTeacher = (val, id) => {
        if(id){
            myClassApi.getNewClassInfoForFree({classId:id,productId: state.productId, year: val.year, externalTeacherId: val.externalTeacherId},res=>{
                updateState({
                    sectionList:res.sectionList || [],
                    selectTeacher: val.externalTeacherName
                })
            })
        }else{
            myClassApi.getNewCourseModularForFree({id: tool.getQueryString2('id'), year: val.year, externalTeacherId: val.externalTeacherId}, res=>{
                updateState({
                    classInfo:res.classInfo || {},
                    selectTeacher: val.externalTeacherName
                })
            })
        }
    }


    // 设置模态框
    const setContactUsModalStatus = (type=false,v={})=>{
        setModalState({
          showContactUsModal:!modalState.showContactUsModal,
          modalType:type,
          productInfo:{
              productName:state.courseDetail.name,
              productId:state.courseDetail.id,//产品id（type=0时必须）
              courseName:v.courseName,//模块名称（type=0时必须）
              sectionId:v.sectionId,//节id（type=0时必须）
              startTime:v.startTime,//直播开始时间（type=0时必须）
          }
        })
    }
    // 设置视频播放模态框
    const setPlayerModalStatus = (section,course)=>{
        console.log('section-------',section,course)
        props.history.push({pathname:'/newVedioPlay',search:`id=${section.charterId}&productId=${state.productId}&isFree=1`,state:Object.assign({},section,{externalProductType:state.courseDetail.externalProductType,classId:course.courseId})})
    }
    //获取直播凭证
    const getPlayLiveForFree= (section)=>{
        console.log('section-------',section)
        if(section.status === 1) { //status 直播状态(1:未直播,2:正在直播,3:直播结束) 
            message.info("直播未开始，请晚点再来~")
            return
        }
        props.history.push({pathname:'/newLivingPlay',search:`id=${section.liveId}&liveCourseName=${section.liveName}&isFree=1&productId=${state.productId}`})
    }

    
    const isShowLuboChater = (i) =>{  //点击录播章展开节
        if(state.luboCharpterInx === i){
            updateState({
                luboCharpterInx:-1
            })
        }else{
            updateState({
                luboCharpterInx:i
            })
        }
    }

    const isShowLiveCourse = (i) =>{  //点击套餐 - 直播科目展开课程
        if(state.subjectIndex === i){
            updateState({   
                subjectIndex:-1
            })
        }else{
            updateState({
                subjectIndex:i,
                courseIndex:-1
            })
        }
    }
    const isShowLiveDetail = (i,id) =>{  //点击套餐 - 直播展开课程
        console.log('点击套餐 - 直播展开课程',i)
        if(state.courseIndex === i){
            updateState({
                courseIndex:-1
            })
        }else{
            //点击展开请求数据
            myClassApi.getNewLiveInfoForFree({classId:id,productId: state.productId},res=>{
                console.log("获取套餐直播详情数据",res);
                updateState({
                    courseIndex:i,
                    charpterIndex: -1,
                    liveList:res.liveList || [],
                    isFree:res.isFree,
                })
            })
        }

    }
    const isShowClassCourse = (i) =>{  //点击套餐录播展开收起科目
        if(state.luboSubjectIndex === i){
            updateState({
                luboSubjectIndex:-1
            })
        }else{
            updateState({
                luboSubjectIndex:i,

            })
        }

    }
    const isShowChater = (i,id) =>{  //点击套餐录播展开收起章节信息
        console.log('点击录播课程id',id,state.charpterIndex)
        if(state.charpterIndex === i){
            updateState({
                charpterIndex:-1
            })
        }else{
            console.log('展开的？')
            //点击展开请求数据
            api.getTeacherFilterParams({externalCourseId: id}, teacherListObj => {
                myClassApi.getNewClassInfoForFree({classId:id,productId: state.productId, year: teacherListObj[0].year, externalTeacherId: teacherListObj[0].teacherInfoList[0].externalTeacherId},res=>{
                    console.log("获取套餐录播详情数据",res);
                    updateState({
                        charpterIndex: i,
                        sectionList:res.sectionList || [],
                        yearList: teacherListObj,
                        teacherInfoList: teacherListObj[0].teacherInfoList
                    })
                })
            })
        }

    }
    // 试看
    const toSee = (seeType,v,i)=>{
        if(!v.sectionName){//课程试看补充名字
            v.sectionName = v.mainTitle
        }
        if(seeType==1 && (auditionList.flag==1 && i==auditionList.list.length-1)){//不可试看
            setContactUsModalStatus('reserve')//报名
            return
        }
        if(seeType==2 && v.isAudition==0){
            setContactUsModalStatus('reserve')//报名
            return
        }
        switch (v.type){
            case 1://直播
                switch (v.courseStatus){
                    case 0://未开始
                        v.sectionId = v.sectionId || v.id//课程详情进来字段名修正
                        v.startTime = v.startTime || v.beginTime//课程详情进来字段名修正
                        setContactUsModalStatus('freeLiveCourse',v)//预约
                        break
                    case 1://直播中
                        tool.buriedPointSave('试看直播/','试看直播','试看直播')
						setFreeAuditionState({
							modalStatus:true,
							sectionInfo:Object.assign({},v,{freeLive:true})
						})
                        break
                    case 2://已结束
                        message.info("当前直播已结束")
                        break
                }
                break
            case 2://录播
                tool.buriedPointSave('试看录播回放/','试看录播回放','试看录播回放课程')
				setFreeAuditionState({
					modalStatus:true,
					sectionInfo:Object.assign({},v,{freeLive:true})
				})
                break
            case 4://回放
                tool.buriedPointSave('试看录播回放/','试看录播回放','试看录播回放课程')
				setFreeAuditionState({
					modalStatus:true,
					sectionInfo:Object.assign({},v,{freeLive:true})
				})
                break
        }
    }
    return <div className="home-page-wrap">
        <div className="course-detail-wrap">
            {/* 面包屑 */}
            <Breadcrumb separator=">" className="bread-crumb">
                <Breadcrumb.Item>当前位置：</Breadcrumb.Item>
                <Breadcrumb.Item href="javascript:void(0)" onClick={() => jumpToCourse(state.courseDetail)}>选课程</Breadcrumb.Item>
                <Breadcrumb.Item>课程详情</Breadcrumb.Item>
            </Breadcrumb>
            {/* 基本信息 */}
            <div className="course-detail-base-info-wrap">
                <div className="top-info">
                    {state.courseDetail.isAudition==1 && <div className='free-live-icon'>免费试看</div>}
                    <img src={state.courseDetail.productUrl} />
                    <div className='top-info-txt-wrap'>
                        <div className="top-info-title clamp2">{state.courseDetail.name}</div>
                        <div className="price-bar">
                            {state.showCoursePrice == 1 && <div className="price-text">￥{state.courseDetail.minPrice?`${state.courseDetail.minPrice} ~ ￥${state.courseDetail.maxPrice}`: `${state.courseDetail.maxPrice}`}</div>}
                            <div className="top-info-contact-btn" onClick={()=>{setContactUsModalStatus('reserve')}}>立即报名</div>
                        </div>
                    </div>
                </div>
                {/* 试看列表 */}
                {state.courseDetail.isAudition==1 && auditionList.list.length>0 && <div className='free-audition-list-wrap'>
                    <div className='free-audition-list'>
                        {auditionList.list.map((v,i)=>{
                            return <div className='free-audition-list-item' key={i} style={{background:`url(${state.courseDetail.productUrl})`}} onClick={()=>{toSee(1,v,i)}}>
                            <div className={`free-audition-list-item-tag ${auditionList.flag==1 && i==auditionList.list.length-1?'pay-txt':'free-txt'}`}>{auditionList.flag==1 && i==auditionList.list.length-1?'付费':'试看'}</div>
                            <div className='free-audition-list-item-mask'></div>
                            {/* 可试看 */}
                            {!(auditionList.flag==1 && i==auditionList.list.length-1) && <div className='free-audition-list-item-title clamp2'>{v.subjectName} {v.charterName} {v.sectionName}</div>}
                            {/* 不可试看 */}
                            {auditionList.flag==1 && i==auditionList.list.length-1 &&  <div className='free-audition-list-item-pay'>解锁全部</div>}
                            </div>
                        })}
                    </div>
                </div>}
            </div>
            {/*  */}
            <Tabs className="course-detail-tab" size="large" tabBarGutter={100}>
                <TabPane tab="课程简介" key="1">
                    <div className="course-intro-wrap">
                        {/* <img src={require('../../assets/homePage/course-intro.png')} /> */}
                        {/* 班型 */}
                        <div className="course-intro-info">
                            <div className="course-intro-info-item">
                                <div className="course-intro-info-item-title">班型</div>
                                <div className="course-intro-info-item-desc">{state.courseDetail.classTypeName || '-'}</div>
                            </div>
                            {/* 慧简学V1.15需求：当该产品标识为外部api接入产品，取系统内对应字段，并隐藏“报考地区”字段。productOriginType 产品来源类型，0-内部，1-外部API接入 */}
                            {state.courseDetail.productOriginType === 0 && <div className="course-intro-info-item">
                                <div className="course-intro-info-item-title">报考地区</div>
                                <div className="course-intro-info-item-desc">
                                    {state.courseDetail.teachPlans && state.courseDetail.teachPlans.map((v,i)=>{return <span key={i}>{i>0?'、':''}{v.areaName}</span>})}
                                    {(!state.courseDetail.teachPlans || state.courseDetail.teachPlans.length==0) && <span>暂无</span>}
                                </div>
                            </div>}
                            <div className="course-intro-info-item">
                                <div className="course-intro-info-item-title">服务周期</div>
                                <div className="course-intro-info-item-desc">{state.courseDetail.serviceYear||'-'} 年</div>
                            </div>
                        </div>
                        <div className="course-intro-desc">
                            <div dangerouslySetInnerHTML={{__html:state.courseDetail.productDesc}}></div>
                        </div>
                    </div>
                </TabPane>
                {state.courseDetail.teachPlans && state.courseDetail.teachPlans.length > 0 && state.courseDetail.productOriginType === 0 && <TabPane tab="课程目录" key="2">
                    <div className="course-intro-wrap">
                        {/* <img src={require('../../assets/homePage/course-list.png')} /> */}
                        <div>
                            <span className="area-name">报考地区</span>
                            <Select defaultValue={state.courseDetail.teachPlans[0].id} style={{ width: 150 }} onChange={changePlan}
                                suffixIcon={<Icon type="caret-down" style={{fontSize:24,color:'#343537'}} />}
                                dropdownMatchSelectWidth={false}
                                dropdownClassName="area-selection"
                            >
                                {state.courseDetail.teachPlans.map((v,i)=>{
                                    return <Option value={v.id} key={i}>{v.areaName}</Option>
                                })}
                            </Select>
                        </div>
                        {/* 课程 */}
                        <Collapse className="course-list-wrap"
                            bordered={false}
                            defaultActiveKey={['1']}
                            // style={{ background: token.colorBgContainer }}
                            // items={getItems(panelStyle)}
                        >
                            {state.courseList.map((v,i)=>{
                                // 文盲后端拼错单词,charter→chapter
                                return <Collapse.Panel header={v.courseName}  className="course-list-item" key={i}>
                                    {v.charterList && v.charterList.map((d,j)=>{
                                        return <Collapse key={j}
                                            bordered={false}
                                        >
                                            <Collapse.Panel header={d.mainTitle} className='course-list-charter'>
                                                {d.sectionList.map((c,k)=>{
                                                    return <div className='course-list-section' key={k}>
                                                        <div className='live-icon' style={{visibility:c.type==1?'visible':'hidden'}}>直播</div>
                                                        <div className='course-list-section-title'>{c.mainTitle}</div>
                                                        {/* 可试看 */}
                                                        {c.isAudition==1 && <div className='free-btn' onClick={()=>{toSee(2,Object.assign({},c,{courseName:v.courseName}))}}>试看</div>}
                                                        {/* 不可试看 */}
                                                        {c.isAudition==0 && <div className='icon-lock' style={{background:`url(${icon_suo})`}} onClick={()=>{toSee(2,Object.assign({},c,{courseName:v.courseName}))}}></div>}
                                                    </div>
                                                })}
                                            </Collapse.Panel>
                                        </Collapse>
                                    })}
                                </Collapse.Panel>
                            })}
                        </Collapse>
                            {/* {state.courseList.map((v,i)=>{
                                return <div className="course-list-item" key={i}>
                                    <div className="course-list-item-index">0{i+1}</div>
                                    <div className="course-list-item-title-circle"></div>
                                    <div className="course-list-item-title-bar">
                                        <div className="c-line"></div>
                                        <div className="clamp2">{v.courseName}</div>
                                    </div>
                                </div>
                            })} */}
                    </div>
                </TabPane>}
                {state.courseDetail.productOriginType === 1 && <TabPane tab="课程目录" key="2">
                    <div className="course-intro-wrap">
                        <img src={require('../../assets/homePage/course-list.png')} />
                        {/* 外部产品——录播 */}
                        {state.courseDetail.externalProductType === 0 && <div className="class-info-wrap">
                            {state.yearList && state.yearList.length > 0 && <div className='class-info-wrap-select'>
                                <div className="class-info-wrap-select-item">课程年份:
                                <Select value={state.selectYear}  style={{ width: 120 }}
                                    suffixIcon={<Icon type="caret-down" style={{fontSize:24,color:'#343537', marginLeft: '20px'}} />}
                                    dropdownMatchSelectWidth={false}
                                    dropdownClassName="area-selection"
                                    >
                                {state.yearList.map((v,i)=>{
                                    return <Option onClick={() => changeYear(v)} value={v.year} key={i}>{v.year}年</Option>
                                })}
                                </Select>
                                </div>
                                <div className="class-info-wrap-select-item">教师:
                                <Select value={state.selectTeacher} style={{ width: 120 }}
                                    suffixIcon={<Icon type="caret-down" style={{fontSize:24,color:'#343537'}} />}
                                    dropdownMatchSelectWidth={false}
                                    dropdownClassName="area-selection"
                                    >
                                        {state.teacherInfoList.map((v, i)=>{
                                            return <Option onClick={() => changeTeacher(v)} value={v.externalTeacherId} key={i}>{v.externalTeacherName}</Option>
                                        })}
                                </Select>
                                </div>
                            </div>}
                            <div className="class-info-item">
                                <div className="course-info-item-coursename">{state.classInfo.courseName}</div>
                                {state.classInfo.sectionList && state.classInfo.sectionList.length > 0 ? state.classInfo.sectionList.map((item,i)=>(
                                    <div className="course-list-item-charter-wrap" key={i} >
                                        <div className="course-list-item-charter-item" onClick={(e)=>{item.videoInfo ? setPlayerModalStatus(item,state.classInfo) : isShowLuboChater(i,item.courseId)}}>
                                            <div className="course-list-item-chartername">{item.charterName}</div>
                                            {item.videoInfo && item.isLook==1 && <div className='free-study-wrap' onClick={()=>setPlayerModalStatus(item,state.classInfo)}>
                                                <img className="img-play" src={require('../../assets/homePage/icon_mianfeixuexi.png')} />
                                                <div className="free">免费学习</div>
                                            </div>}
                                            {!item.videoInfo && item.child && <div>
                                                <Icon className="new-up-icon" type={state.luboCharpterInx === i ?'up':'down'}></Icon>
                                            </div>}
                                        </div>
                                        {/* 下面是点击后再展示节 */}
                                        {state.luboCharpterInx === i && item.child && item.child.length > 0 ? item.child.map((h,k)=>(<div style={{paddingLeft: '24px'}}>
                                            <div className="course-list-item-charter-item" key={h.charterId} >
                                                <div className="course-list-item-chartername">{h.charterName}</div>
                                                {h.videoInfo && h.isLook==1 && <div className='free-study-wrap' onClick={()=>setPlayerModalStatus(h,state.classInfo)}>
                                                    <img className="img-play" src={require('../../assets/homePage/icon_mianfeixuexi.png')} />
                                                    <div className="free">免费学习</div>
                                                </div>}
                                            </div>
                                        </div>)):''}
                                    </div>)):''
                                }
                            </div>
                            {/* <div className="course-list-item-intro">仅展示免费试看章节，购买课程后可观看所有章节视频</div> */}
                        </div>}
                        {/* 外部产品——直播 */}
                        {state.courseDetail.externalProductType === 1 && <div className="live-info-wrap">
                            <div className="live-info-item-coursename">{state.liveInfo.liveCourseName}</div>
                            {state.liveInfo.liveList && state.liveInfo.liveList.length > 0 ? state.liveInfo.liveList.map((item,i)=>(
                                <div className="live-info-item" key={i} >
                                    <div className="live-info-item-course-wrap">
                                        <div className="live-info-item-coursename">{item.liveName}</div>
                                        <div className="live-info-item-time">直播时间：{item.startTime.slice(0,16)}~{item.endTime.slice(11,16)}</div>
                                    </div>
                                    {/* isFree  是否免费(1.是 2.否)   直播 */}
                                    {state.liveInfo.isFree === 1 && <div className='free-study-wrap' onClick={()=>getPlayLiveForFree(item)}>
                                        <img className="img-play" src={require('../../assets/homePage/icon_mianfeixuexi.png')} />
                                        <div className="free">免费学习</div>
                                    </div>}
                                </div>)):''
                            }
                        </div>}
                        {/* 外部产品——套餐 */}
                        {state.courseDetail.externalProductType === 2 && <div className="package-info-wrap">
                            {state.liveCourseList  && state.liveCourseList.length > 0 && <div className="package-info-live-wrap">
                                <div className="package-info-live-header">
                                    <img className="img" src={require('../../assets/homePage/icon_zhibo.png')} />
                                    <div className="name">直播</div>
                                </div>
                                {state.liveCourseList  && state.liveCourseList.length > 0 ? state.liveCourseList.map((item,i)=>(
                                    <div className="package-info-live-item-wrap" key={i} >
                                        <div className="package-info-live-coursename-wrap" onClick={()=>isShowLiveCourse(i)}>
                                            <div className="package-info-live-coursename">{item.subjectName}</div>
                                            {item.liveCourseDetail && <Icon className="new-up-icon" type={state.subjectIndex === i ?'up':'down'}></Icon>}
                                        </div>
                                        {state.subjectIndex === i && item.liveCourseDetail &&  item.liveCourseDetail.length > 0 ? item.liveCourseDetail.map((x,y)=>(
                                            <div key={x.liveCourseId} >
                                                <div className="zhibo-course" onClick={()=>isShowLiveDetail(y,x.liveCourseId)}>
                                                    <div className="live-info-item-coursename">{x.liveCourseName}</div>
                                                    {state.liveList && <Icon className="new-up-icon" type={state.courseIndex=== y ?'up':'down'}></Icon>}
                                                </div>
                                                {state.courseIndex=== y && state.liveList &&  state.liveList.length > 0 ? state.liveList.map((o,p)=>(
                                                    <div key={o.liveId} >
                                                        <div className="package-info-live-item">
                                                            <div className="live-info-item-course-wrap">
                                                                <div className="live-info-item-coursename">{o.liveName}</div>
                                                                <div className="live-info-item-time">直播时间：{o.startTime.slice(0,16)}~{o.endTime.slice(11,16)}</div>
                                                            </div>
                                                            {state.isFree === 1 && <div className='free-study-wrap' onClick={()=>getPlayLiveForFree(o)}>
                                                                <img className="img-play" src={require('../../assets/homePage/icon_mianfeixuexi.png')} />
                                                                <div className="free">免费学习</div>
                                                            </div>}
                                                        </div>
                                                    </div>)):''}
                                            </div>)):''}
                                        <div className="line"></div>
                                    </div>)):''}
                            </div>}
                            {state.courseClassList && state.courseClassList.length > 0 && <div className="package-info-class-wrap">
                                <div className="package-info-class-header">
                                    <img className="img" src={require('../../assets/homePage/icon_lubo.png')} />
                                    <div className="name">录播</div>
                                </div>
                                {state.courseClassList && state.courseClassList.length > 0 ? state.courseClassList.map((lubo,i)=>(
                                    <div className="package-info-class-item-wrap" key={i} >
                                        <div className="package-info-class-coursename-wrap" onClick={()=>isShowClassCourse(i)}>
                                            <div className="package-info-class-coursename">{lubo.subjectName}</div>
                                            {lubo.courseDetail && <Icon className="new-up-icon" type={state.luboSubjectIndex === i ?'up':'down'}></Icon>}
                                        </div>    
                                        {state.luboSubjectIndex === i && <div className="package-info-class-item">
                                            {lubo.courseDetail  && lubo.courseDetail.length > 0 ? lubo.courseDetail.map((v,j)=>(
                                                <div className="class-info-item" key={v.courseId} >
                                                    <div className="package-info-class-coursename-wrap" onClick={()=>{v.videoInfo ? setPlayerModalStatus(v,v) : isShowChater(j,v.courseId)}}>
                                                        <div className="course-info-item-coursename">{v.courseName}</div>
                                                        <Icon className="new-up-icon" type={state.charpterIndex === j ?'up':'down'}></Icon>
                                                    </div>
                                                    {state.charpterIndex === j && state.yearList && state.yearList.length > 0 &&
                                                    <div className='package-info-class-select'>
                                                        <div className="package-info-class-select-item">课程年份:
                                                            <Select defaultValue={state.yearList[0] && state.yearList[0].year}  style={{ width: 120 }}
                                                                suffixIcon={<Icon type="caret-down" style={{fontSize:24,color:'#343537'}} />}
                                                                dropdownMatchSelectWidth={false}
                                                                dropdownClassName="area-selection"
                                                                >
                                                            {state.yearList.map((z,x)=>{
                                                                return <Option onClick={() => changeYear(z)} value={z.year} key={x}>{z.year}年</Option>
                                                                })
                                                            }
                                                            </Select>
                                                        </div>
                                                        <div className="package-info-class-select-item">教师:
                                                            <Select defaultValue={state.teacherInfoList[0] && state.teacherInfoList[0].externalTeacherName} style={{ width: 120 }}
                                                                suffixIcon={<Icon type="caret-down" style={{fontSize:24,color:'#343537'}} />}
                                                                dropdownMatchSelectWidth={false}
                                                                dropdownClassName="area-selection"
                                                                >
                                                                    {state.teacherInfoList.map((z, x)=>{
                                                                        return <Option onClick={() => changeTeacher(z, v.courseId)} value={z.externalTeacherId} key={x}>{z.externalTeacherName}</Option>
                                                                    })}
                                                            </Select>
                                                        </div>
                                                    </div>}
                                                    {/* 下面是点击后再请求接口展示  */}
                                                    {state.charpterIndex === j && state.sectionList && state.sectionList.length > 0 ? state.sectionList.map((h,k)=>(<div key={h.charterId} >
                                                        <div className='course-wrap'>
                                                            <div className="course-info-item-coursename">{h.charterName}</div>
                                                            {h.videoInfo && h.isLook==1 && <div className='free-study-wrap' onClick={()=>setPlayerModalStatus(h,v)}>
                                                                <img className="img-play" src={require('../../assets/homePage/icon_mianfeixuexi.png')} />
                                                                <div className="free">免费学习</div>
                                                            </div>}
                                                        </div>
                                                        {h.child && h.child.length > 0 ? h.child.map((m,n)=>(
                                                            <div className="course-list-item-charter-wrap" key={n} >
                                                                <div className="course-list-item-charter-item">
                                                                    <div className="course-list-item-chartername">{m.charterName}</div>
                                                                    {m.videoInfo && m.isLook==1 && <div className='free-study-wrap' onClick={()=>setPlayerModalStatus(m,v)}>
                                                                        <img className="img-play" src={require('../../assets/homePage/icon_mianfeixuexi.png')} />
                                                                        <div className="free">免费学习</div>
                                                                    </div>}
                                                                </div>
                                                                {/* <div className="course-list-item-intro">仅展示免费试看章节，购买课程后可观看所有章节视频</div> */}
                                                            </div>)):''
                                                        }
                                                    </div>)):''
                                                    }
                                                </div>)):''
                                            }
                                        </div>}
                                        <div className="line"></div>
                                    </div>)):''
                                }
                            </div>}
                        </div>}
                    </div>
                </TabPane>}
                <TabPane tab="课程资料" key="3">
                    <div className='file-module-item-wrap' onClick={()=>{message.info("请联系机构老师获取课程报考资料~")}}>
						<img className='file-module-item-icon' src={icon_file} />
                        获取报考资料
                    </div>
                </TabPane>
            </Tabs>
        </div>
        {/* 模态框 */}
        <ContactUsModal type={modalState.modalType} showContactUsModal={modalState.showContactUsModal} setContactUsModalStatus={setContactUsModalStatus} productInfo={modalState.productInfo}></ContactUsModal>
        {/* 视频播放模态框 */}
        <FreePlayerModal showPlayerModal={state.showPlayerModal} setPlayerModalStatus={setPlayerModalStatus} luboVideoUrl={state.luboVideoUrl} iframeUrl={state.iframeUrl}></FreePlayerModal>
		{/* 自营的视频试看 */}
		<FreeAuditionVideoModal {...freeAuditionState} setFreeAuditionState={setFreeAuditionState}></FreeAuditionVideoModal>
    </div>
}
export default withRouter(CourseDetail)