import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { Icon, message, Collapse } from 'antd'
import api from '../../api/newExam'
import yuanImg from '../../assets/img/yuan@2x.png'
import ExericeList from './ExericeList'
import tool from '../../utils/commonFuc'

const { Panel } = Collapse;

const XqdExamList = (props) => {
    const [state,setState] = useState({
        sectionList:[],//章节列表
        subjectId:'',
    })
    //更新state
    const updateState = (newState)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    useEffect(()=>{
        // 获取试卷列表
        api.getPaperList({
            subjectId: sessionStorage.getItem('subjectId'),
            paperTypeId:tool.getQueryString2('paperTypeId')
        },res=>{
            if(res){
                updateState({sectionList:res,subjectId:sessionStorage.getItem('subjectId')})
            }
        })
    },[])
    // 跳转去试题
    const toExam = (v)=>{
        // 章节类型ID和章节ID
        let search = `subjectId=${props.subjectId}&charterSectionId=${v.charterId}&title=${v.charterName}`
        // props.history.push({pathname:'/newExamTest',search:search})
        window.open(`${window.location.origin}${window.location.pathname}#/newExamTest?${search}`)
        // props.history.push({ pathname: `/newExamTest`, search: `search`})
    }
    // 展示子试题
    const showChildren = (v,i)=>{
        // state.sectionList[i].show = !v.show
        // updateState({sectionList:state.sectionList})
        // console.log(v)
    }
    const createTestItem = (arr,img=true)=>{
        return arr.map((d,j)=>{
            if(d.paperList && d.paperList.length>0){
                return <Collapse defaultActiveKey={['0']} onChange={showChildren} expandIconPosition="right" key={`${d.charterId}`}>
                        {arr.map((v, i) => {
                            return <Panel header={
                                <div style={{display:'flex',alignItems:'center'}}>
                                    {img && <img width="16px" height="16px" style={{marginRight:'9px'}} src={yuanImg} alt="图片"></img>}
                                    <b className="moduleName">{v.charterName}</b>
                                </div>}
                                key={v}>
                                {(v.paperList && v.paperList.length>0) && v.paperList.map( (c,k) => (
                                    <ExericeList paperData={c} key={c.paperId}></ExericeList>
                                ))}
                                {d.child && d.child.length>0 && createTestItem(d.child,false)}
                            </Panel>
                        })}
                    </Collapse>
            }
            if((!d.paperList || d.paperList.length==0) && d.child && d.child.length>0){
                return <Collapse defaultActiveKey={['0']} onChange={showChildren} expandIconPosition="right" key={d.charterId}>
                {arr.map((v, i) => {
                    return <Panel header={
                        <div style={{display:'flex',alignItems:'center'}}>
                            <img width="16px" height="16px" style={{marginRight:'9px'}} src={yuanImg} alt="图片"></img>
                            <b className="moduleName">{v.charterName}</b>
                        </div>}
                        key={i}>
                        {createTestItem(d.child,false)}
                    </Panel>
                })}
            </Collapse>}
        })
    }
    return <div className='new-exam-list-wrap'>
        <div className="modular_content_box">
            {state.sectionList.map((v,i)=>{
                // 成套题
                if(!v.charterName && v.paperList && v.paperList.length>0){
                    return v.paperList.map((d, j)=>{
                        return <ExericeList paperData={d} key={j} noCollapse={true}></ExericeList>
                    })
                }
                // 折叠层级
                return createTestItem([v])
            })}
        </div>
    </div>
}
export default withRouter(XqdExamList)