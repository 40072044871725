import * as type from '../constants/ActionTypes';

const initialState = {
    intelligentList: []
}

const intelligentData = (state=initialState,action) => {
    switch (action.type) {
        case type.GET_INTELLIGENT :
                console.log(action)
            return Object.assign({},{...state},{
                intelligentList: action.data.exercises
            })
        default:
            return {...state}
    }
}

const deff = (arr, level) =>  {
    console.log(arr);
    arr.forEach((element,index) => {
        element.level = level
        if (element.children && element.children.length > 0) {
            // console.log(element.children);
            deff(element.children, level + 1)
        }
    })
}

export const myIntelligentData = state => {
    console.log(state)
    deff(state.intelligentList,1)
    return state.intelligentList
}

export default intelligentData