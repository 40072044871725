// import { debug } from "webpack";
import api from '../api/homePage'
import Session from '../utils/Session'
export default {
    inputFilfter(value, status) {
        switch (status) {
          case 1: //只能输入字母和数字
            return value.replace(/[^\w]/g, '');
            break;
          case 2: //只能输入数字
            return value.replace(/[^\d]/g, '');
            break;
          case 3: //判断固话，只能输入+-和数字
            return value.replace(/[^\d\-\+]/g, '');
            break;    
          case 4: //只能输入数字，2位有效小数
            value = value.replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符  
            value = value.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的  
            value = value.replace(".","$#$").replace(/\./g,"").replace("$#$","."); 
            value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数  
            if(value.indexOf(".")< 0 && value !=""){//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额 
                value= parseFloat(value); 
            }           
            return value;                
            break;               
        }
      },    
      getNowFormatDate(needYear = false) { //为导出的文件添加日期
        let date = new Date();
        let seperator1 = "";
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
    
        let currentdate = needYear == true ? year + month + seperator1 + strDate : month + seperator1 + strDate;
        return currentdate;
      },      
      sessionStorage:{
        storage:window.sessionStorage,
        getItem: function (key){         
          let value = this.storage.getItem(key);        
          if (value === null){
              return false;
          }
          if (value && value.substring(0, 1) === "{" || value.substring(0, 1) == "[") {  // 判断字符串是否是对象或则数组结构
              value = JSON.parse(value);  //将字符串转成json
          }
          return value
        },
        setItem: function (key,value){
            if(this.getType(value) === 'Object' || this.getType(value) === 'Array') {            
                value = JSON.stringify(value);
            }
            this.storage.setItem(key, value);
        },
        // 删除项
        removeItem: function (key) {
            this.getItem(key) && this.storage.removeItem(key);
        },
        //  清空
        clear: function () {
          this.storage.clear(); // 清空本地存储
        },      
        getType:(object) => {
          /**
           * 方法来源：prototype.js
           * getType(5); // => "Number"
           * getType({}); // => "Object"
           * getType(/foo/); // => "RegExp"
           * getType(''); // => "String"
           * getType(true); // => "Boolean"
           * getType([]); // => "Array"
           * getType(undefined); // => "Window"
           * getType(Element); // => "Constructor"
           **/
          return Object.prototype.toString.call(object).match(/^\[object\s(.*)\]$/)[1];
      }          
    }, 
    arrayEquals(array1,array2){//对比数组是否相等      
      Array.prototype.equals = function(array) {
        // if the other array is a falsy value, return
        if(!array)
          return false;
  
        // compare lengths - can save a lot of time 
        if(this.length != array.length)
          return false;
  
        for(let i = 0, l = this.length; i < l; i++) {
          // Check if we have nested arrays
          if(this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if(!this[i].equals(array[i]))
              return false;
          } else if(this[i] != array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
          }
        }
        return true;
      }
      return array1.equals(array2)
    },
    getDays(strDateStart,strDateEnd){  //判断两个日期相差的天数
        let strSeparator = "-"; //日期分隔符
        let oDate1;
        let oDate2;
        let iDays;
        oDate1= strDateStart.substr(0,10).split(strSeparator);
        oDate2= strDateEnd.substr(0,10).split(strSeparator);
        let strDateS = new Date(oDate1[0], oDate1[1]-1, oDate1[2]);
        let strDateE = new Date(oDate2[0], oDate2[1]-1, oDate2[2]);
        iDays = parseInt(Math.abs(strDateS - strDateE ) / 1000 / 60 / 60 /24)//把相差的毫秒数转换为天数
        return iDays + 1 ;
    },    
    getDate(type,timeData){//统计时间范围以及计算相隔天数
      let _mydate=new Date();
      let _myDay = _mydate.getDate() < 10 ?  `0${_mydate.getDate()}` : _mydate.getDate();
      let _myMonth = _mydate.getMonth() + 1;
      let _nowDate = `${_mydate.getFullYear()}-${_myMonth < 10 ? `0${_myMonth}` : _myMonth}-${_myDay}`;//当期日期
      let _day = 0//相差天数
      switch (type) {
        case 'today':
          _day = 1;
          
          break;
        case 'week':
          //计算周
          _day = _mydate.getDay() === 0 ? 7 : _mydate.getDay();
          _mydate.setDate(_mydate.getDate() - _day + 1);     
          // 如果当期天数小于相差天数，则月份要减1
          _myMonth = _mydate.getDate() < _day ? _myMonth - 1 : _myMonth;
          break;
        case 'month':
          // 计算月
          _day = _myDay;
          _mydate.setDate(_mydate.getDate() - _myDay + 1);   
          break;
        case 'year':
            // 计算年
            let dateArr = new Array(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);
            let date = new Date();
            let day = date.getDate();
            let month = date.getMonth(); //getMonth()是从0开始
            let year = date.getFullYear();
            for (let i = 0; i < month; i++) {
              _day += dateArr[i];
            }
            _day += day;
            //判断是否闰年
            if (month > 1 && (year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
              _day += 1;
            }  
            _myMonth = 1;
            _mydate.setDate(_mydate.getDate() - _day + 1);
            break; 
        case 'time':
            //传入时间
            return {
              beginDate:timeData.beginDate,
              endDate:timeData.endDate,
              days:this.getDays(timeData.beginDate,timeData.endDate)
            };             
          break;         
        default:
          break;
      }
      _myMonth = _myMonth < 10 ? `0${_myMonth}` : _myMonth;

      return {
        beginDate:`${_mydate.getFullYear()}-${_myMonth}-${_mydate.getDate() < 10 ? `0${_mydate.getDate()}` : _mydate.getDate()}`,
        endDate:_nowDate,
        days:_day
      };  
    },
    countDay(time,day){//将传入的日期进行加减
      let _time = new Date(Date.parse(new Date(time)) + (day - 1) * 24 * 60 * 60 * 1000);      

      var year=_time.getFullYear(); 
      var month=_time.getMonth()+1; 
      var date=_time.getDate(); 
      
      return `${year}-${month > 10 ? month : '0'+month}-${date > 10 ? date : '0'+date}`;
    },
    digitTransform(value,type){//将传进来的数值进行取整，type==1时数值加逗号
        return type ? parseFloat(parseFloat(value).toFixed(0)).toLocaleString() : parseFloat(value).toFixed(0)
    },
    getQueryString(history, name) {//解析url中的字段
      try {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        var r = history.location.search.substr(1).match(reg);
        if (r != null) {
          return unescape(r[2]);
        }
      } catch(e) {}
      return null;
    },
    getQueryString2(name) {//解析url中的字段
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      const urlObj = window.location;
      if (urlObj.href.indexOf('?') > -1) {
        var r = urlObj.href.indexOf('#') > -1 ? urlObj.hash.split("?")[1].match(reg) : urlObj.search.substr(1).match(reg);
      }
      if (r != null) return decodeURI(r[2]); return null;
    },
    isHJXTenant() { // 是否新慧简学的机构
      let json = localStorage.getItem('tenantInfo');
      if(json && json.length > 0) {
        let tenantInfo = JSON.parse(json);
         // originOrgType : 0-自营，1-新慧简学机构
        if(tenantInfo.originOrgType === 1) {
          return true;
        }
      }
      return false;
    },
    appendRentId(url) {
      if(!url && url.length === 0) {
        return ''
      }
      let json = localStorage.getItem('tenantInfo')
      if(json && json.length > 0) {
        let tenantInfo = JSON.parse(json)
        if(tenantInfo) {
          let str
          if(url.indexOf('?') === -1) {
            str = '?id=' + tenantInfo.id
          } else {
            str = '&id=' + tenantInfo.id
          }
          console.log('appendRentId===', url + str)
          return url + str
        }
      }
      localStorage.removeItem('tenantInfo')
      console.log('appendRentId===', url)
      return url
    },
    getPushLoginParam() {
      let url = '/phoneLogin/index'
      let rentId = sessionStorage.getItem('rentId')
      let search = ''
      if(rentId && rentId != 'null'){
        url = '/homePage'
      }
      if(!url && url.length === 0) {
        return { pathname: 'url' }
      }
      let json = localStorage.getItem('tenantInfo')
      if(json && json.length > 0) {
        let tenantInfo = JSON.parse(json)
        if(tenantInfo) {
          search = `id=${tenantInfo.id}`
          if(rentId){
            search += `&rentId=${rentId}`
          }
          let param = {pathname: url, search: search}
          console.log('getPushParam===', param)
          return param
        }
      }
      localStorage.removeItem('tenantInfo')
      console.log('getPushParam===', { pathname: url })
      return { pathname: url }
    },
    getTimeDom(startTime ,endTime ,duration ,type){    //课程时间转换
      if(type === 0){ //录播
          let _t = parseInt(Number(duration))
          let m = Math.floor((_t/60)%60)
          let hours = Math.floor(duration / 3600 ) < 10 ? '0' + Math.floor(_t  / 3600 ) : Math.floor(_t  / 3600 )
          let minute = m  < 10 ? '0' + m : m
          let second = Math.floor(_t %60) > 9  ? Math.floor(_t %60) : '0' + Math.floor(_t %60)
          // 录播课程返回视频时长
          return `${hours}:${minute}:${second}`
      }else if(type === 1){ 
          // 直播
          // let year = new Date(startTime).getFullYear() 
          // let month = (new Date(startTime).getMonth()) + 1 < 10 ? '0' + (new Date(startTime).getMonth() + 1) :(new Date(startTime).getMonth()) + 1;
          // let day = new Date(startTime).getDate() < 10 ? '0' + new Date(startTime).getDate() : new Date(startTime).getDate();
          // let hour = new Date(startTime).getHours() < 10 ? '0' + new Date(startTime).getHours()  :new Date(startTime).getHours() ;
          // let minute = new Date(startTime).getMinutes() < 10 ? '0' + new Date(startTime).getMinutes()  :new Date(startTime).getMinutes() ;
          // let endHour = new Date(endTime).getHours() < 10 ? '0' + new Date(endTime).getHours()  :new Date(endTime).getHours() ;
          // let endMinute = new Date(endTime).getMinutes() < 10 ? '0' + new Date(endTime).getMinutes()  :new Date(endTime).getMinutes() ;
          let _t = `${startTime}-${endTime.substr(11,19)}`
          // console.log("startTime",`${year}.${month}.${day} ${hour}:${minute}`);
          // return `${year}.${month}.${day} ${hour}:${minute}~${endHour}:${endMinute}`
          return _t
      }
    },
    // 埋点
    buriedPointSave(p='',e='',o=''){//路径、功能、操作类型
      let obj = {
          level:2,//菜单层次
          nameLevelPath:'',//菜单名称层级路径（如果是功能，传的是上级菜单的层级）
          no:'PC001',//菜单编码（如果是功能，传的是上级菜单编码）
          function:e,//功能
          parentFunction:'',//父功能
          operationType:o,//操作类型
          moduleCode:'PC',//系统编码
          rentId:sessionStorage.getItem('rentId')
      }
      let str = `/PC学员端/`
      obj.nameLevelPath = `${str}${p}`
      api.buriedPointSave(obj)
    },
}