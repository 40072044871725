import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { withRouter } from 'react-router-dom'

import successful from '../../assets/newImg/successful@2x.png'

import loginApi from '../../api/login'
import tool from '../../utils/commonFuc'

class setPassword extends React.Component{
    state = {
        isClick:false,
        registerStatus:false,
        mustSetPassword:false,// 是否可以跳过设置密码
        loading:false,
        isHjx: false, // 是否是慧简学机构
    }

    componentDidMount(){
        // 赋值，是否可以跳过设置密码
        this.setState({
            mustSetPassword:JSON.parse(tool.getQueryString(this.props.history,'mustSetPassword')),
            isHjx: tool.isHJXTenant()
        })
    }

    handleSubmit = () => {

    }
    changePass = (e) => {
        let value = e.target.value;
        if(value.length >= 8){
            this.setState({
                isClick:true
            })
        }else{
            this.setState({
                isClick:false
            })
        }
    }
    timer = null
    solveLogin = () => {// 点击完成事件
        if(this.state.isClick){// 注册
            let actionToken = JSON.parse(sessionStorage.getItem('actionToken'))
            if(actionToken){
                let obj = {
                    actionToken:actionToken
                }
                this.props.form.validateFields((err,values) => {
                    if(!err){
                        this.setState({
                            loading:true
                        })
                        obj.password = values.passwd;
                        loginApi.setPasswordAndRegister(obj, res => {
                            this.setState({
                                loading:false
                            })
                            if(res){
                                let obj2 = {
                                    userName:JSON.parse(sessionStorage.getItem("userPhone")).phone,
                                    type:'pass',
                                    passwd:obj.password
                                }
                                console.log(obj2)
                                loginApi.login(obj2, res => {})//再次密码登录
                                sessionStorage.removeItem("userPhone");  //清除验证码登录界面的缓存
                                sessionStorage.removeItem("actionToken");  //清除token缓存
                                this.timer = setTimeout(()=>{
                                    this.props.history.push("/phoneLogin/bindingInfo")
                                },2000)
                                this.setState({
                                    registerStatus:true
                                })
                            }
                        })
                    }
                })
            }else{// 设置密码
                this.props.form.validateFields((err,values) => {
                    if(!err){
                        this.setState({
                            loading:true
                        })
                        loginApi.setNewPassword(values, res => {
                            this.setState({
                                loading:false
                            })
                            if(res){
                                message.success('设置密码成功')
                                this.props.history.push({pathname:'/home'}) 
                            }
                        })
                    }
                })
            }
        }
    }
    gotoStudyHome = () => {  //进入学习主页事件
        this.props.history.push("/phoneLogin/bindingInfo")
    }
    // 跳转去学习页面
    jumpToHome = () =>{
        this.props.history.push({pathname:'/home'});
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const { isHjx} = this.state;
        let colorStyle = ''
        if (isHjx) {
            colorStyle = '#396BF0'
        } else {
            colorStyle = '#FFBD30'
        }
        // 下一步按钮，有三种状态： 未输入内容时为灰色；机构型且有内容为蓝色，否则为橙色
        const study_btn_styles = {
            color: this.state.isClick && !isHjx ? "#332A2A" : "#FFFFFF",
            backgroundColor: this.state.isClick ? colorStyle : "#D9D9D9",
            borderRadius:'24px'
        }
        const whatShow = () => {
            if(this.state.registerStatus){
                return (
                    <div className="register_success_box">
                        <div className="success_tip">
                            <img src={successful} alt="图片加载失败" width="45" height="45"></img>
                            <span>注册成功</span>
                        </div>
                        <p>
                            注册成功后
                            <span>2</span>
                            秒自动跳转"
                            <span>学习主页</span>
                            "
                        </p>
                        <div className="footer_btn">
                            <button onClick={this.gotoStudyHome}>进入学习主页</button>
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="right_content_box">
                        <p>
                            设置密码
                        </p>
                        <div className="tip_text">   
                            温馨提示：设置后，下次可用手机号验证码登录，也可用学员账户密码登录哦
                        </div>
                        {/* form表单处 */}
                        <div className="form_box">
                            <Form onSubmit={this.handleSubmit}>
                                <h2>设置密码</h2>
                                <Form.Item label="">
                                    {getFieldDecorator('passwd', {
                                        rules: [
                                            {
                                                validator:(rule, value, callback) => {
                                                    let reg = /(?!.*[\u4E00-\u9FA5\s])(?!^[a-zA-Z]+$)(?!^[\d]+$)(?!^[^a-zA-Z\d]+$)^.{8,12}$/
                                                    if(value && reg.test(value)){
                                                        callback()
                                                    }else{
                                                        callback('密码至少由字母，数字或符号中2种字符组成8~12位，区分大小写')
                                                    }
                                                }
                                            }
                                        ],
                                    })(<Input type="password" placeholder="密码至少由字母，数字或符号中2种字符组成8~12位，区分大小写" allowClear maxLength={12} />)}
                                </Form.Item>
                                <h2>再次输入密码</h2>
                                <Form.Item label="">
                                    {getFieldDecorator('repeatPassWord', {
                                        rules: [
                                            {
                                                validator:(rule, value, callback) => {
                                                    let passwd = this.props.form.getFieldValue('passwd')
                                                    if(value === passwd){
                                                        callback()
                                                    }else{
                                                        callback('请和密码一样')
                                                    }
                                                }
                                            }
                                        ],
                                    })(<Input type="password" placeholder="请输入8~12位字符" onChange={this.changePass} onPressEnter={this.solveLogin} allowClear maxLength={12} />)}
                                </Form.Item>
                            </Form>
                            <div className="footer_btn over_solve_box">
                                <Button type="primary" loading={this.state.loading} style={study_btn_styles} onClick={this.solveLogin}>完成</Button>
                                {this.state.mustSetPassword?'':
                                    <a style={{marginLeft:30}} onClick={this.jumpToHome}>跳过</a>
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return (
            <div className="phone_login_content">
                {whatShow()}
            </div>
        )
    }
}

export default Form.create({})(withRouter(setPassword))