import api from '../api/class'
import * as types from '../constants/ActionTypes'

const myClass = data => ({
    type: types.GET_MY_CLASS_DATA,
    data,
})

export const getMyClassData = (data) => dispatch =>{
    return api.getMyClass(data,(res)=>{
        if(res){
            dispatch(myClass(res))
            // let arr = res.filter((v)=>{return v.productOriginType==0})//内部产品
            // if(arr.length>0){
            dispatch(getMyClassSubjectData({myClassList: res}))
            // }
        }   
    })
}

const termData = data => ({
    type: types.GET_TERM_DATA,
    data,
})

export const getTermData = (data) => dispatch =>{
    return api.getTermData(data,(res)=>{
        /* if(res instanceof Array && res.length>0){
            let obj = Object.assign({},data,{term:res[0].term})
            dispatch(getModularData(obj))
            dispatch(termData(res))
        }else{
            dispatch(getModularData(data))
            dispatch(termData(res))
        } */
        let arr = []
        if(res){
            arr = res.progressList.concat(res.termList)
        }
        dispatch(termData(arr))
    })
}
const modularData = data => ({
    type: types.GET_MODULAR_DATA,
    data,
})

export const getModularData = (data) => dispatch =>{
    return api.getModularData(data,(res)=>{
        if(res){
            // dispatch(modularData(res.subjectList))
            dispatch(modularData(res))
        }else{
            dispatch(modularData([]))
        }
    })
}

export const clertModularData = () => dispatch => {
    dispatch(modularData([]))
}

const chapterSectionData = data => ({
    type: types.GET_CHAPTER_SECTION_DATA,
    data,
})

export const getChapterSection = (data) => dispatch =>{
    return api.getChapterSection(data,(res)=>{
        if(res){
            dispatch(chapterSectionData(res))
        }   
    })
}
export const getChapterSection2 = (data) => dispatch =>{
    dispatch(chapterSectionData(data))
}
export const clearChapterSection = data => dispatch => {  //清楚章节数据缓存
    return dispatch(chapterSectionData(data))
}

const editPerson = data => ({
    type:types.CHANGE_EDIT_PERSON_MODAL,
    data
})

export const changeEditPerson = (data) => dispatch =>{
    return dispatch(editPerson(data))
}


const myClassSubject = data => ({
    type:types.GET_MYCLASS_SUBJECT,
    data
})

export const getMyClassSubjectData = (data) => dispatch =>{  //获取题库首页的科目数据
    console.log('获取题库首页的科目数据1111111111')
    return api.getMyClassSubject(data,(res)=>{
        if(res){
            const arr = res.map((item,i)=>{
                return {
                    value: item.teachPlanId || `xqd_${i}`,//新起点产品没有教学计划ID，处理联动组件添加ID
                    label: item.productName,
                    children: (item.subjectList && item.subjectList.length!==0) ? item.subjectList.map(_item=>{
                        return {
                            value: _item.id,
                            label: _item.name,
                            subjectType: _item.subjectType
                        }
                    }) : []
                }
            })
            console.log(arr)
            if(res.length>0 && !sessionStorage.getItem('subjectType')){
                let subject = res[0].subjectList[0]
                sessionStorage.setItem('teachPlanId',res[0].teachPlanId)
                sessionStorage.setItem('subjectId',subject.id)
                sessionStorage.setItem('subjectName',subject.name)
                sessionStorage.setItem('subjectType',subject.subjectType)
                sessionStorage.setItem('defaultValue',JSON.stringify([res[0].teachPlanId,subject.id]))
            }else {
                // sessionStorage.removeItem('subjectId')
            }
            dispatch(myClassSubject(arr))
        }   
    })
}
