import { postData } from './index'

export default{
    changeTenantData:(data, callback) => {  //用户切换设置租户机构
        return postData('/sys/login/setTenantShowState',data).then( res => {
            callback(res)
        })
    },
    getTenantInfo:(data, callback) => { // 获取机构信息，根据rentId获取或者pcUrl获取
        return postData('/sys/tenant/getTenantInfo',data).then((res) => {    
            if(res) {
                localStorage.setItem('tenantInfo',JSON.stringify(res));
            }
            if(callback){
                callback(res)
            }
            return res
        }).catch( (err) => {
            console.log(err)
            if(callback){
                callback(false)
            }
            return false
        })
    },
    getCurrentPersonInfo: async (data, callback) => { // 我的资料-获取当前登录用户资料
        return postData('/web/usercenter/getCurrentPersonInfo',data).then((res) => {    
            if(callback){
                callback(res)
            }
            return res
        }).catch( (err) => {
            console.log(err)
            if(callback){
                callback(false)
            }
            return false
        })
    },
}