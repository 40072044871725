import * as type from '../constants/ActionTypes';

const initialState = {
    myExam:{
        myExamList:[]
    },
    moduleTest: []
}

const examData = (state=initialState,action) => {
    switch (action.type) {
        case type.GET_EXAM_HOME :
            console.log(action)
            return Object.assign({},{...state},{
                myExam:{
                    myExamList: action.data.contentList
                }
            })
        case type.GET_MODULE_TEST :
            console.log(action)
            return Object.assign({},{...state},{
                moduleTest: action.data
            })
        default:
            return {...state}
    }
}

export const myExamData = state => {
    return state.myExam
}

export const moduleTestData = state => {
    return state.moduleTest
}

export default examData