import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Button, Form, message, Input } from 'antd'

import logoImg from ".././../assets/img/logo.png"
import picImg from ".././../assets/img/pic.png"

import loginApi from '../../api/login'
import './index.scss'
import tool from '../../utils/commonFuc'

const FormItem = Form.Item;
class myClass extends Component {
    state = {
        sendCodeText: "发送验证码",
        sendStatus: false,
    }


    handleSubmit = (e) => {
        e.preventDefault();
        let newPass = this.props.form.getFieldValue('newPass');
        let newPass2 = this.props.form.getFieldValue('newPass2');
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (newPass && newPass2 && newPass != newPass2) {
                    message.error("两次密码不一致")
                }else{
                    loginApi.resetPass(values, (data) => {
                        if (data) {
                            //重置成功，跳转页面             
                            this.props.history.push(tool.appendRentId('/phoneLogin/index'));
                            window.location.reload()
                        }
                    })
                }
                // console.log("Received values of form: ", values);
            } 

        });
    }

    // 发送验证码
    handleSendCode = () => {
        let _data = {
            phone: this.props.form.getFieldValue("phone"),
            validType: "CZMM"
        };
        if (_data.phone) {
            loginApi.sendCode(_data, (res) => {
                if (res) {
                    //成功拿到验证码，修改“发送验证码”按钮状态,60秒倒计时      
                    let _num = 60;
                    let _interval = setInterval(() => {
                        this.setState({
                            sendCodeText: `${--_num}S后重发`,
                            sendStatus: true
                        })
                        if (_num == 0) {
                            this.setState({
                                sendCodeText: "发送验证码",
                                sendStatus: false
                            })
                            clearInterval(_interval);
                        }
                    }, 1000);
                }
            })
        } else {
            message.error("请输入手机号码")
        }

    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { sendCodeText, sendStatus } = this.state;

        return (
            <div className="login-page">
                <img src={logoImg} alt="" />
                <div className="formBox">
                    <div className="titleBox font20 oh">
                        <span className="cp color333">找回密码</span>
                    </div>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <FormItem>
                            {getFieldDecorator("phone", {
                                rules: [{ required: true, message: "请输入手机号码" }],
                            })(
                                <Input size="large" placeholder="请输入手机号码" />
                            )}
                        </FormItem>
                        <FormItem >
                            {getFieldDecorator("validCode", {
                                rules: [{ required: true, message: "请输入验证码" }],
                            })(
                                <Input className="fl" size="large" placeholder="请输入验证码" style={{ width: 210, }} />
                            )}
                            <div className="getCodeBox fr cp" onClick={this.handleSendCode} style={{ color: sendStatus ? "#949699" : "#EF323B" }} >
                                {sendCodeText}
                            </div>
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator("newPass", {
                                rules: [{ required: true, message: "请输入密码" }],
                            })(
                                <Input size="large" type="password" placeholder="请输入密码" />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator("newPass2", {
                                rules: [{ required: true, message: "请再次输入新密码" }],
                            })(
                                <Input size="large" type="password" placeholder="请再次输入新密码" />
                            )}
                        </FormItem>
                        <Button onClick={this.handleSubmit} className="login-form-button" style={{ width: 320, marginTop: 12, marginBottom: 10 }}>
                            确&nbsp;&nbsp;&nbsp;&nbsp;定
                            </Button>
                    </Form>
                </div>
                <img className="fr picImg" src={picImg} alt="" />
            </div>
        )
    }
}
export default withRouter(Form.create()(myClass));