import React,{useState,useEffect} from 'react'
import { Modal} from 'antd'

import PlayerBox from '../../VedioStudy/PlayerBox'

//视频模块
const FreeAuditionVideoModal = (props)=>{
    
    const [state,setState] = useState({
        
    })
    const handleCancel = ()=>{
        props.setFreeAuditionState({modalStatus:false,sectionInfo:{}})
    }
    return <Modal title={props.sectionInfo.sectionName} visible={props.modalStatus} onCancel={handleCancel} footer={null} width={1248}>
        <div className='liveing_poliv' style={{width:1200,height:675}}>
            {props.sectionInfo.freeLive && <PlayerBox sectionInfo={props.sectionInfo}></PlayerBox>}
        </div>
    </Modal>
}

export default FreeAuditionVideoModal;