import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Icon, message, Modal, Form, Button } from 'antd'

import api from '../../api/homePage.js'
import loginApi from '../../api/login'
import tool from '../../utils/commonFuc'

const ContactUsModal = (props)=>{
    const [state,setState] = useState({
        sendCodeText:'获取验证码',
        sendStatus:false,
        num:'',
        formData:{
            name:'',
            phone:'',
            smsCode:''
        },
        btnLoading:false,
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
        if(props.showContactUsModal){
            props.form.resetFields()
            if(!!sessionStorage.getItem('token')){//已登录的情况下不弹窗
                if(props.type=='freeLiveCourse' || props.type=='reserve'){
                    handleCancel()
                    let postData = {
                        productName:props.productInfo.productName,//产品名称
                        type:props.type=='freeLiveCourse'?0:(props.type=='reserve'?1:''),//操作类型：0免费预约，1立即报名
                        rentId:sessionStorage.getItem('rentId'),
                    }
                    if(postData.type==0){
                        postData = Object.assign({},postData,{
                            productId:props.productInfo.productId,//产品id（type=0时必须）
                            courseName:props.productInfo.courseName,//模块名称（type=0时必须）
                            sectionId:props.productInfo.sectionId,//节id（type=0时必须）
                            startTime:props.productInfo.startTime,//直播开始时间（type=0时必须）
                        })
                        // tool.buriedPointSave('免费预约/','免费预约','预约直播试看')
                    }else{
                        tool.buriedPointSave('报名/','报名','报名试看课程')
                    }
                    api.freeAuditionReserve(postData,res=>{
                        updateState({btnLoading:false})
                        if(res){
                            message.success(`${postData.type==0?'预约':'报名'}成功`)
                        }
                    })
                }
            }
        }
    },[props.showContactUsModal])
    const handleCancel = ()=>{
        props.setContactUsModalStatus()
    }
    // 修改密码
    const showPasswordModal = (e)=>{
        e.preventDefault()
        updateState({passwordModal: true})
    }
    const addConsultRecord = (e)=>{
        e.preventDefault()
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                updateState({btnLoading:true})
                let postData = Object.assign({},values,{rentId:sessionStorage.getItem('rentId')})
                console.log(values)
                if(props.type=='freeLiveCourse' || props.type=='reserve'){//预约直播、立即报名
                    postData = {
                        productName:props.productInfo.productName,//产品名称
                        type:props.type=='freeLiveCourse'?0:(props.type=='reserve'?1:''),//操作类型：0免费预约，1立即报名
                        rentId:sessionStorage.getItem('rentId'),
                    }
                    if(!sessionStorage.getItem('token')){//是否登录
                        postData = Object.assign({},postData,{
                            userName:values.userName,//姓名
                            receiveNumber:values.phone,//手机号
                            smsCode:values.smsCode,//验证码
                        })
                    }
                    if(postData.type==0){
                        postData = Object.assign({},postData,{
                            productId:props.productInfo.productId,//产品id（type=0时必须）
                            courseName:props.productInfo.courseName,//模块名称（type=0时必须）
                            sectionId:props.productInfo.sectionId,//节id（type=0时必须）
                            startTime:props.productInfo.startTime,//直播开始时间（type=0时必须）
                        })
                        // tool.buriedPointSave('免费预约/','免费预约','预约直播试看')
                    }else{
                        tool.buriedPointSave('报名/','报名','报名试看课程')
                    }
                    api.freeAuditionReserve(postData,res=>{
                        updateState({btnLoading:false})
                        if(res){
                            message.success(`${postData.type==0?'预约':'报名'}成功`)
                            handleCancel()
                        }
                    })

                }else{
                    api.addConsultRecord(postData,res=>{
                        updateState({btnLoading:false})
                        if(res){
                            message.success('提交成功')
                            handleCancel()
                        }
                    })
                }

            }
        })
    }
    const formItemLayout = {
        labelCol: {
          xs: { span: 0 },
          sm: { span: 0 },
        },
        wrapperCol: {
          xs: { span: 16 },
          sm: { span: 24 },
        },
    }
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 0,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 0,
            },
        },
    }
    const getCode = () => {
        let _data = {
            phone: props.form.getFieldValue("phone"),
            rentId:sessionStorage.getItem('rentId') //增加租户ID
        }
        if (_data.phone) {
            api.getValidCode(_data, (res) => {
                if (res) {
                    //成功拿到验证码，修改“发送验证码”按钮状态,60秒倒计时      
                    let _num = 60;
                    let _interval = setInterval(() => {
                        updateState({
                            sendCodeText: `后重发`,
                            sendStatus: true,
                            num:(--_num) + 'S'
                        })
                        if (_num === 0) {
                            updateState({
                                sendCodeText: "重新发送",
                                sendStatus: false,
                                num:''
                            })
                            clearInterval(_interval);
                        }
                    }, 1000);
                }
            })
        } else {
            message.error("请输入手机号码")
        }
    }
    const codeNode = () => {
        const styles = {
            color:state.sendCodeText!=='获取验证码'?'#8B8B8B':'#396BF0',
        }
        return (
            <span onClick={getCode} style={styles}>
                <span style={{color:'#396BF0'}}>
                    {state.num}
                </span>
                {state.sendCodeText}
            </span>
        )
    }
    // 按钮样式
    const submitBtnStyle = ()=>{
        let values = props.form.getFieldsValue()
        if(!values.userName || !values.phone || !values.smsCode){
            return false
        }
        return true
    }

    // 模态框头部
    const creatModalTitle = ()=>{
        let title = ''
        switch(props.type){
            case 'freeLiveCourse':
                title = '预约直播'
                break
            case 'reserve':
                title = '立即报名'
                break
            default :
                if(sessionStorage.getItem('rentId') == '1208') {
                    title = '免费注册'
                } else {
                    title = '免费咨询专业老师'
                }
        }
        return <div>{title}</div>
    }
    const { getFieldDecorator } = props.form;

    return <div>
        {((props.type=='freeLiveCourse' || props.type=='reserve') && !sessionStorage.getItem('token') || !props.type) && <Modal title="" visible={props.showContactUsModal} onCancel={handleCancel} footer={null} width={440}>
        <div className="contact-modal-title">
            {creatModalTitle()}
            <div style={{visibility:props.type != 'freeLiveCourse' && props.type!='reserve'?'visible':'hidden'}}>填写信息，后续会有专业老师联系你</div>
        </div>
        <div className="contact-modal-form-wrap">
            <Form {...formItemLayout} onSubmit={addConsultRecord} className="update-password-form" style={{width:'344px',margin:'0 auto'}}>
                <Form.Item label="">
                    {getFieldDecorator('userName', {
                        rules: [
                            {
                                required: true,
                                message: '请输入你的姓名',
                            },
                        ]
                    })(<Input placeholder="请输入你的姓名" size="large" allowClear maxLength={20} />)}
                </Form.Item>
                <Form.Item label="">
                    {getFieldDecorator('phone', {
                        rules: [
                            {
                                required: true,
                                message: '请输入手机号码',
                            },
                        ]
                    })(<Input placeholder="请输入手机号码" size="large" allowClear maxLength={11} />)}
                </Form.Item>
                <Form.Item label="">
                    {getFieldDecorator('smsCode', {
                        rules: [
                            {
                                required: true,
                                message: '请输入验证码',
                            },
                        ],
                    })(<Input placeholder="请输入验证码" size="large" allowClear addonAfter={codeNode()} onPressEnter={addConsultRecord} />)}
                </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" disabled={!submitBtnStyle()} htmlType="submit" loading={state.btnLoading} style={{fontSize: '18px',color:'#fff',background:submitBtnStyle()?'#396BF0':'#D9D9D9',width:'344px',height:'50px',border:'none',borderRadius:25}}>点击提交</Button>
                </Form.Item>
            </Form>
        </div>
    </Modal>}
        </div>
}
export default Form.create({})(withRouter(ContactUsModal))