import * as type from '../constants/ActionTypes';

const initialState = {
    meunData: [],
    routerArr:[],
}

const meunData = (state=initialState,action) => {
    switch (action.type) {
        case type.GET_MEUN_DATA :
            return Object.assign({},{...state},{meunData:action.meun,routerArr:action.arr})
        default:
            return {...state}
    }
}

export const getMeunData = state => {
    return state.meunData
}
export const getRouterArr = state => {
    return state.routerArr
}

export default meunData