import React,{useState,useEffect} from 'react'
// import PropTypes from 'prop-types'
import { Cascader, Icon } from 'antd';
import { withRouter } from 'react-router-dom'

import './selectSubject.scss'

const SubejectSwitcher = (propsObj)=>{

    const [state,setState] = useState({
        userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
        subjectName:'暂无科目',
        defaultValue:[],
    })
    useEffect(()=>{
        if(propsObj.history.location.pathname == '/home'){
            if(sessionStorage.getItem("subjectId")){
                propsObj.refreshPropsData({subjectId:sessionStorage.getItem("subjectId")})
            }
        }
    },[propsObj.myClassSubjectData])
    //更新state
    const updateState = (newState)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }

    const onChange = (value, selectedOptions)=>{
        console.log('切换机构Value',value)
        console.log('切换机构：',selectedOptions)
        if(value && value.length > 0){
            const { refreshPropsData, refreshData } = propsObj;
            sessionStorage.setItem('teachPlanId',selectedOptions[0].value);
            sessionStorage.setItem('subjectId',selectedOptions[1].value);
            sessionStorage.setItem('subjectName',selectedOptions[1].label);
            sessionStorage.setItem('subjectType',selectedOptions[1].subjectType);//缓存科目类型：1-自营；2-XQD
            sessionStorage.setItem("defaultValue",JSON.stringify(value))
            updateState({
                defaultValue:value,
                subjectName:selectedOptions[1].label,
                text: selectedOptions[selectedOptions.length-1].label
            })
            // 获取练习tag
            refreshPropsData && refreshPropsData({ //如果父组件传方法过来了，就执行
                teachPlanId: selectedOptions[0].value,
                subjectId: selectedOptions[1].value,
            })
            // 
            refreshData && refreshData()
            if(propsObj.history.location.pathname != '/home'){
                propsObj.history.push({pathname:'/home',search:`tabIndex=2`})
                // propsObj.history.go(-1)
                window.location.reload()
            }
        }else{
            sessionStorage.removeItem("teachPlanId");
            sessionStorage.removeItem("subjectId");
        }
    }

    return <div className="topBg">
        <div className="top-bg-txt-wrap">
            <span style={{display:'inline-block',marginRight:'23px',fontSize:'18px'}} className="topFont">
                {sessionStorage.getItem("subjectName") || state.subjectName}
            </span>
            <Cascader
                defaultValue={JSON.parse(sessionStorage.getItem("defaultValue"))||state.defaultValue}
                options={propsObj.myClassSubjectData}
                expandTrigger="hover"
                onChange={onChange}
                placeholder="切换科目">
                    <div className="chooseBtn">
                        <span className="sub">切换科目</span>
                        <Icon type="down" />
                    </div>
            </Cascader>
        </div>
    </div>
}
export default withRouter(SubejectSwitcher)