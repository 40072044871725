import React from 'react'
import { Form, Input, message, Button, Checkbox, Modal } from 'antd'
import { withRouter } from 'react-router-dom'

import loginApi from '../../api/login'
import RulesModal from './rulesModal'
import "./login.scss"
import logoIcon from '../../assets/newImg/logo2@2x.png'
import tool from '../../utils/commonFuc'

class phoneVerification extends React.Component{
    state = {
        isLogin:false,
        sendCodeText:'获取验证码',
        sendStatus:false,
        num:'',
        formData:{
            phone:'',
            validCode:''
        },
        btnLoading:false,
        isAgree: false, // 是否同意协议
        visible: false,
        content: '',
        title: '',
        rentId: '', // 机构id
        isHjx: false, // 是否是慧简学机构
        isRegister: false,
        showTenantCode: false, // 是否展示填写机构码
        tenantCodeForm: {
            tenantCode: ''
        },
        canStudy: false, // 是否可以点击学习
        codeClick: false // 是否点击“发送验证码”
    }

    componentDidMount(){
        if(sessionStorage.getItem("userPhone")){
            this.setState({
                formData: JSON.parse(sessionStorage.getItem("userPhone")),
                isLogin:true
            })
        }
        if (sessionStorage.getItem("rentId")) {
            this.setState({
                rentId: sessionStorage.getItem("rentId")
            })

        }
        this.setState({
            isHjx: tool.isHJXTenant()
        })
    }

    chagePass = (e) => {
        if(e.target.value && e.target.value !== ''){
            this.setState({
                isLogin:true  
            })
        }else{
            this.setState({
                isLogin:false  
            })
        }
    }
    changeTenantCode = (e) => {
        if(e.target.value && e.target.value !== '' && e.target.value.length === 6){
            this.setState({
                canStudy:true  
            })
        }else{
            this.setState({
                canStudy:false  
            })
        }
    }
    getCode = () => {
        let _data = {
            phone: this.props.form.getFieldValue("phone"),
            validType: "DL",
            tenantId: this.state.rentId
        };
        this.setState({
            codeClick: true
        })
        window.trackEvent('PCH5-get-code', 'PC H5-获取验证码')
        if (_data.phone) {
            loginApi.getValidCode(_data, (res) => {
                if (res) {
                    // 标志是否是注册
                    this.setState({
                        isRegister: res.validType === 'ZC'
                    })
                    //成功拿到验证码，修改“发送验证码”按钮状态,60秒倒计时      
                    let _num = 60;
                    let _interval = setInterval(() => {
                        this.setState({
                            sendCodeText: `后重发`,
                            sendStatus: true,
                            num:(--_num) + 'S'
                        })
                        if (_num === 0) {
                            this.setState({
                                sendCodeText: "重新发送",
                                sendStatus: false,
                                num:''
                            })
                            clearInterval(_interval);
                        }
                    }, 1000);
                }
            })
        } else {
            message.error("请输入手机号码")
        }
    }
    goLogin = () => {
        window.trackEvent('PCH5-code-study', 'PC H5-验证码登录-开始学习')
        if (!this.state.isAgree) {
            message.error("请先勾选同意《用户协议》和《隐私政策》")
            return
        }
        if (!this.state.codeClick) {
            message.error('请点击获取验证码')
            return
        }
        if(this.state.isLogin){
            this.props.form.validateFields((err,values) => {
                if(!err) {
                    // 更新手机验证码信息
                    this.setState({
                        formData: values
                    })
                    // 如果是注册且没带机构id的，需要填写机构码
                    if (this.state.isRegister && !this.state.rentId) {
                        this.chcekCode(values)
                    } else {
                        this.doLogin(values)
                    }
                }
            })
        }   
    }
    chcekCode = (values) => {
        loginApi.chcekCode(values, res => {
            console.log('----------', res)
            this.setState({
                showTenantCode: true,
                isLogin: false
            })
        })
    }
    tenantCodeLogin = () => {
        if(this.state.canStudy){
            this.props.form.validateFields((err, values) => {
                // 更新机构码的信息
                this.setState({
                    tenantCodeForm: values
                })
                if(!err) {
                    const param = Object.assign(this.state.formData, values)
                    this.doLogin(param)
                }
            })
        }
    }
    doLogin = (param) => {
        let Fun = this.state.isRegister ? loginApi.loginRegister : loginApi.login
        // 注册时需要传机构码且无需传机构id
        if (this.state.isRegister && this.state.tenantCodeForm.tenantCode !== '') {
            param.tenantCode = this.state.tenantCodeForm.tenantCode
        } else {
            param.tenantId = this.state.rentId
        }
        param.registerType = '2' // 注册渠道:0、小程序，1、移动H5  2、PC H5
        this.setState({
            btnLoading:true
        })
        Fun(param, res => {
            this.setState({
                btnLoading:false
            })
            if(res){
                localStorage.setItem('lastLoginType',JSON.stringify({showPassLogin:false}))
                // 注册账号
                if(res.loginStatus === 2){
                    sessionStorage.setItem('actionToken',JSON.stringify(res.actionToken))
                    sessionStorage.setItem('userPhone',JSON.stringify(param))
                    this.props.history.push({pathname:'/phoneLogin/setPassword',search:`mustSetPassword=true`})
                }else{
                    // 是否强制设置密码
                    if(res.isForcePassword === 1){
                        sessionStorage.setItem('userPhone',JSON.stringify(param))
                        this.props.history.push({pathname:'/phoneLogin/setPassword',search:`mustSetPassword=true`})
                        return
                    }
                    // v2.2.6上线后首次登录，提示修改密码
                    if(res.hadLoginIn===0){
                        this.props.history.push({pathname:'/phoneLogin/setPassword',search:`mustSetPassword=false`})
                        return
                    }
                    if(res.cardNo && res.cardNo != ''){
                        this.props.history.push({pathname:'/home'});
                    }else{
                        this.props.history.push({pathname:'/phoneLogin/bindingInfo'})
                    }
                }
            }
        })
    }
    passLogin = () => {  //密码登录点击事件
        window.trackEvent('PCH5-password-login', 'PC H5-密码登录')
        this.props.history.push({pathname:'/phoneLogin/passLogin'})
    }
    agreeChange = () => {
        this.setState({
            isAgree: !this.state.isAgree
        })
    }
    showModal = (val) => {
        if (val == 2) {
            window.trackEvent('PCH5-view-agreement', 'PC H5-查看服务协议')
        }
        if (val == 3) {
            window.trackEvent('PCH5-view-privacy', 'PC H5-查看隐私政策')
        }
        loginApi.getAgreement({type:val}, res => {
            this.setState({
                content:res.content,
                title:res.title,
                visible:true
            })
        });
    }
    handleOk = () => {
        this.setState({
            visible:false
        })
    }
    handleCancel = () => {
        this.setState({
            visible:false
        })
    }
    render(){
        
        console.log(this.props.form, this.props)
        const { getFieldDecorator } = this.props.form;
        const { isHjx} = this.state;
        let colorStyle = ''
        if (isHjx) {
            colorStyle = '#396BF0'
        } else {
            colorStyle = '#FFBD30'
        }
        // 下一步按钮，有三种状态： 未输入内容时为灰色；机构型且有内容为蓝色，否则为橙色
        const next_btn_styles = {
            color: this.state.isLogin && !isHjx ? "#332A2A" : "#FFFFFF",
            backgroundColor: this.state.isLogin ? colorStyle : "#D9D9D9",
            borderRadius:'24px'
        }
        const study_btn_styles = {
            color: this.state.canStudy && !isHjx ? "#332A2A" : "#FFFFFF",
            backgroundColor: this.state.canStudy ? colorStyle : "#D9D9D9",
            borderRadius:'24px'
        }
        const codeNode = () => {
            const { sendCodeText} = this.state;
            let codeColor = '#8B8B8B'
            if (sendCodeText === '获取验证码') {
                codeColor = colorStyle
            }
            const styles = {
                color: codeColor
            }
            return (
                <span onClick={this.getCode} style={styles}>
                    <span style={{color:'#FF4021'}}>
                        {this.state.num}
                    </span>
                    {this.state.sendCodeText}
                </span>
            )
        }
        const { formData, tenantCodeForm } = this.state;
        return (
            <div className={`${this.state.isHjx ? 'blue_style':'orange_style'} phone_login_content`}>
                <div className="right_content_box">
                    {/* poko时显示图标 */}
                    {!this.state.isHjx && 
                        <div className='logo_box'>
                        <img src={logoIcon} alt="图片无法加载" height="86px"></img>
                        </div>
                    }
                    {!this.state.showTenantCode &&<div>
                        <p>
                            手机验证登录
                        </p>
                        {/* form表单处 */}
                        <div className="form_box">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Item label="">
                                    {getFieldDecorator('phone', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入手机号',
                                            },
                                        ],
                                        initialValue:formData.phone
                                    })(<Input placeholder="请输入手机号" size="large" allowClear maxLength={11} />)}
                                </Form.Item>
                                <Form.Item label="" style={{marginBottom:'14px'}}>
                                    
                                </Form.Item>
                                <Form.Item label="">
                                    {getFieldDecorator('validCode', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入验证码',
                                            },
                                        ],
                                    })(<Input placeholder="请输入验证码" size="large" allowClear addonAfter={codeNode()} onPressEnter={this.goLogin} onChange={this.chagePass} />)}
                                </Form.Item>
                            </Form>
                            <div className={`${this.state.isHjx ? 'blue_hover':'orange_hover'} password_login_text`} onClick={this.passLogin} style={{cursor:'pointer'}}>
                                密码登录
                            </div>
                            <div className="agree_box" style={{cursor:'pointer'}}>
                                <Checkbox onChange={this.agreeChange} defaultChecked={this.state.isAgree}></Checkbox>&nbsp;
                                我已同意<span className="remark_text" onClick={() => this.showModal(2)}>《用户协议》</span>和<span className="remark_text" onClick={() => this.showModal(3)}>《隐私政策》</span>  
                            </div>
                            <div className="footer_btn">
                                <Button className="study-btn" style={next_btn_styles} onClick={this.goLogin} loading={this.state.btnLoading}>下一步</Button>
                            </div>
                        </div>
                    </div>}
                    {/* 填写机构码 */}
                    {this.state.showTenantCode &&<div className='tenantCode_box'>
                        <p>输入机构码</p>
                        <div className='tip'>机构码可咨询老师获取</div>
                        <div className="form_box">
                            <Form name="codeForm">
                                <Form.Item label="">
                                    {getFieldDecorator('tenantCode', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入机构码',
                                            },
                                            { validator: (rule, value, cb) => {
                                                if (!/^[0-9]*$/.test(value)) {
                                                    return cb(new Error('机构码仅限输入数字'))
                                                } else if (value && value.length < 6){
                                                    return cb(new Error('请输入6位数的机构码'))
                                                } else {
                                                    return cb() 
                                                }
                                            },
                                            trigger: 'blur'
                                            }
                                        ],
                                        initialValue:tenantCodeForm.tenantCode
                                    })(<Input placeholder="请输入机构码" size="large" allowClear maxLength={6} onChange={this.changeTenantCode}/>)}
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="footer_btn">
                            <Button className="study-btn" style={study_btn_styles} onClick={this.tenantCodeLogin} loading={this.state.btnLoading}>开始学习</Button>
                        </div>
                    </div>}
                </div>
                <div>
                    {/* 协议弹框 */}
                    <Modal
                        title={this.state.title}
                        visible={this.state.visible} 
                        onOk={this.handleOk} cancelText="取消" okText="确定"
                        onCancel={this.handleCancel}>
                        <RulesModal content={this.state.content}></RulesModal>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(phoneVerification))