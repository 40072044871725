import api from '../api/exam'
import * as types from '../constants/ActionTypes'

const myClass = data => ({
    type: types.GET_MY_CLASS_DATA,
    data,
})

export const getMyClassData = (data) => dispatch =>{
    return api.getMyClass(data,(res)=>{
        if(res){
            dispatch(myClass(res))
        }   
    })
}

const termData = data => ({
    type: types.GET_TERM_DATA,
    data,
})

export const getTermData = (data) => dispatch =>{
    return api.getTermData(data,(res)=>{
        /* if(res instanceof Array && res.length>0){
            let obj = Object.assign({},data,{term:res[0].term})
            dispatch(getModularData(obj))
            dispatch(termData(res))
        }else{
            dispatch(getModularData(data))
            dispatch(termData(res))
        } */
        let arr = []
        if(res){
            arr = res.progressList.concat(res.termList)
        }
        dispatch(termData(arr))
    })
}
const modularData = data => ({
    type: types.GET_MODULAR_DATA,
    data,
})

export const getModularData = (data) => dispatch =>{
    return api.getModularData(data,(res)=>{
        if(res){
            dispatch(modularData(res.subjectList))
        }   
    })
}

export const clertModularData = () => dispatch => {
    dispatch(modularData([]))
}

const chapterSectionData = data => ({
    type: types.GET_CHAPTER_SECTION_DATA,
    data,
})

export const getChapterSection = (data) => dispatch =>{
    return api.getChapterSection(data,(res)=>{
        if(res){
            dispatch(chapterSectionData(res))
        }   
    })
}
export const clearChapterSection = data => dispatch => {  //清楚章节数据缓存
    return dispatch(chapterSectionData(data))
}

const editPerson = data => ({
    type:types.CHANGE_EDIT_PERSON_MODAL,
    data
})

export const changeEditPerson = (data) => dispatch =>{
    return dispatch(editPerson(data))
}

const examHome = data => ({
    type:types.GET_EXAM_HOME,
    data
})

export const getExamHomeData = (data) => dispatch =>{
    let subjectType = sessionStorage.getItem('subjectType')//1-内部产品；2-外部产品（新起点）
    return api[subjectType==2?'getApiExamHome':'getExamHome'](data,(res)=>{
        if(res){
            dispatch(examHome(res))
        }   
    })
}

const moduleTest = data => ({
    type:types.GET_MODULE_TEST,
    data
})

export const getModuleTestData = (data) => dispatch =>{
    let subjectType = sessionStorage.getItem('subjectType')//1-内部产品；2-外部产品（新起点）
    return api[subjectType==2?'getApiModuleTest':'getModuleTest'](data,(res)=>{
        if(res){
            dispatch(moduleTest(res))
        }   
    })
}
