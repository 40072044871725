import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { Icon, message, Carousel } from 'antd'



import './index.scss'
import api from '../../api/homePage.js'
import ContactUsModal from '../../components/contactUsModal/index'
// 各模块组件
import BannerSwiperMoudle from './component/BannerSwiperMoudle/BannerSwiperMoudle'  //轮播图模块
import HotCourseModule from './component/HotCourseModule/HotCourseModule' //课程模块
import GraphicModule from './component/GraphicModule/GraphicModule' //图文导航模块
import VideoIntroductMoudle from './component/VideoIntroductMoudle/VideoIntroductMoudle' //视频模块
import TeacherIntroductMoudle from './component/TeacherIntroductMoudle/TeacherIntroductMoudle'//教师介绍模块
const Index = (propsObj)=>{
    const [state,setState] = useState({
        // bannerArr:[],//banner图片数组
        moduleComponentsArr:[],// 模块组件数据
        isLogin:false,
        courseComponentId:'',
        showCoursePrice: null, //展示课程价格，0=不展示，1=展示
        showContactUsModal:false,//模态框
        // 名片.当前页面地址没有带rentId时。需要默认展示静态数据
        cardArr:[
            {
                icon:require('../../assets/homePage/icon-1.png'),
                title:'精品课程定制',
                desc:'通过现场棚拍、实操拍摄及场景抠图等方式制作教学课程，以高清大屏的形式呈现每一帧内容，并配套详情PPT，为你提供实用课程',
            },
            {
                icon:require('../../assets/homePage/icon-2.png'),
                title:'专业教师团队',
                desc:'拥有行业内全职教师团队，追求行业领先人才资源，严选师资力量，一步一个脚印，为你提供优质的教学服务',
            },
            {
                icon:require('../../assets/homePage/icon-3.png'),
                title:'行业口碑',
                desc:'通过专业、专注、专心的教学沉淀，提供标准教学服务，学员反馈良好，在教育行业内享有声誉',
            },
            {
                icon:require('../../assets/homePage/icon-4.png'),
                title:'独家资料',
                desc:'精心整理系统性教辅资料、学习规划，根据教学规划配套真题试卷，在满足日常学习的基础上，强化练习和考试逻辑',
            }
        ],
        contentCardArr:[
            {
                title:'专业指导，正确选择',
                desc:'根据你的考期，合理定制科目规划，完成报考和学习，准确抓住考证节点，迅速进入考证节奏。不错过每一次成长机会，用心指导，为你助力走好成长每一步，快速提升自我',
                btnTxt:'免费指导',
                imgUrl:require('../../assets/homePage/content-1.png')
            },
            {
                title:'线上学习，随时随地',
                desc:'多端口线上教学，利用碎片时间学习，或集中时间全力突击。不受环境限制，通勤路上也可持续学习，在职在校两不误。灵活的学习方式，给你带来高效的学习和高质量的生活',
                btnTxt:'线上体验',
                imgUrl:require('../../assets/homePage/content-2.png')
            },
            {
                title:'学员交流，共同进步',
                desc:'学习过程中，针对课程、考点、题库测评，组织学员讨论，共同解决疑难问题，举一反三，互相帮助。借鉴学习，共享考证资讯，了解专业考证优势及难易程度，共同进步',
                btnTxt:'了解专业',
                imgUrl:require('../../assets/homePage/content-3.png')
            },
        ],
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
        const init = async ()=>{
            let searchParams = {
                // page:1,
                // rows:8,
                clientType: 3,
                tryFlag:0,
                rentId:sessionStorage.getItem('rentId')
            }
            if(searchParams.rentId){
                // 获取后台装修页面配置的模板数据
               
                let moduleRes = await api.getHomeComponentList(searchParams,res=>{
                })
                
                // 获取poko学装修后台其他设置中的参数
                let showCourseObj = await api.getOtherConfig({clientType: 3, rentId:sessionStorage.getItem('rentId')}, res=>{
                    
                })
               
                // let bannerArr = await api.getQryIndex({configType:1,displayLocation:1,rentId:sessionStorage.getItem('rentId')},res=>{
                // })
                updateState({
                    moduleComponentsArr:moduleRes.componentList.length > 0 && moduleRes.componentList || [],
                    // bannerArr:bannerArr || [],
                    showCoursePrice: showCourseObj.showCoursePrice,
                    isLogin:sessionStorage.getItem('token')?true:false
                })
            }
        }
        init()
    },[])
    // 设置模态框
    const setContactUsModalStatus = ()=>{
        updateState({showContactUsModal:!state.showContactUsModal})
    }

    return <div className="home-page-wrap">
        {
            state.moduleComponentsArr.length > 0 && state.moduleComponentsArr.filter(val=>val.componentType !== 3).map((item,i)=>{
                return <div key={item.id}>
                    {/* 热门课程组件 */}
                     {
                        item.componentType == 1 && <HotCourseModule  courseInfo={item} showCoursePrice={state.showCoursePrice}/>
                    }
                    {/* 轮播·图组件 */}
                    {
                        item.componentType == 2 &&  < BannerSwiperMoudle bannerArr={item.bannerList} />
                    }
                        {/* 图文导航组件 */}
                    {
                        item.componentType == 4 && <GraphicModule graphicInfo={item}/>
                    }
                        {/* 教师介绍组件 */}
                    {
                        item.componentType == 5 &&   <TeacherIntroductMoudle moudleInfo={item}/>
                    }
                    {/* 视频介绍组件 */}
                    {
                        item.componentType == 6 &&   <VideoIntroductMoudle videoInfo={item}/>
                    }
                </div>
               
            })
        }
        {
            state.moduleComponentsArr.length === 0 && 
            <div className="choice-us-wrap">
                <img className="home-page-content-title" src={require('../../assets/homePage/title-2.png')} />
                <div className="choice-us-card-module">
                    {state.cardArr.map((v,i)=>{
                        return <div className="choice-us-card-item" key={i}>
                            <div className="icon-wrap">
                                <img src={v.icon} />
                            </div>
                            <div className="card-item-title">{v.title}</div>
                            <div className="card-item-desc">{v.desc}</div>
                        </div>
                    })}
                </div>
                {/* 分割线 */}
                <div className="choice-us-line"></div>
                {/* <img src={require('../../assets/homePage/choiceUs.png')} /> */}
                <div className="content-card-wrap">
                    {state.contentCardArr.map((v,i)=>{
                        return <div className="content-card-item" key={i} style={{backgroundImage:`url(${v.imgUrl})`}}>
                            <div className="content-card-item-title">{v.title}</div>
                            <div className="content-card-item-desc">{v.desc}</div>
                            <div className="content-card-item-btn-wrap">
                                <div className="content-card-item-btn" onClick={setContactUsModalStatus}>{v.btnTxt}</div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        }
        {/* 模态框 */}
        <ContactUsModal showContactUsModal={state.showContactUsModal} setContactUsModalStatus={setContactUsModalStatus}></ContactUsModal>
    </div>
}
export default withRouter(Index)