export function isLogin() {
    return sessionStorage.getItem('token')?true:false;
}

let version = process.env.Text[7];

let openUrl = [
    '/open/video/live',
    '/open/video/playback',
    '/open/video/playVideo',
]
export default {
    version: version,
    openUrl:openUrl,
    homeTrendList:[{//首页趋势分析下拉列表
        label:'流水',
        value:'FEE_AMOUNT'
    },{
        label:'订单数',
        value:'ORD_NUM'
    },{
        label:'学员数',
        value:'STUDY_NUM'
    },{
        label:'ARPU',
        value:'ARPU'
    },{
        label:'ROI',
        value:'ROI'
    },{
        label:'退费 ',
        value:'BACK_AMOUNT'
    },{
        label:'机会转化率',
        value:'CHANCE_RATE'
    },{
        label:'名片转化率',
        value:'CARD_RATE'
    },],
    trendTimeList:[{//趋势分析时间颗粒列表
        key:"DAY",
        name:'日',
        days:1,
    },{
        key:"WEEK",
        name:'周',
        days:7,
    },{
        key:"MONTH",
        name:'月',
        days:31,
    },{
        key:"YEAR",
        name:'年',
        days:365,
    },],
    questionTypeList:[{
        id:1,
        name:"单选题"
    },{
        id:2,
        name:"多选题"
    },{
        id:3,
        name:"不定项选择题"
    },{
        id:4,
        name:"判断题"
    },{
        id:5,
        name:"简答题"
    },{
        id:6,
        name:"名词解释题"
    },{
        id:7,
        name:"理解题"
    },{
        id:8,
        name:"案例题"
    },{
        id:9,
        name:"论述题"
    },{
        id:10,
        name:"分析题"
    },{
        id:11,
        name:"综合题"
    },{
        id:12,
        name:"计算题"
    },{
        id:13,
        name:"填空题"
    },{
        id:14,
        name:"完形填空题"
    }],
}