import React from 'react'
import './MyHeader.css'
import api from '../../api/login'
import { Modal, Select, message, Form, Input, Button  } from 'antd'
import PerfectModal from '../home/PerfectModal'
import tenantApi from '../../api/tenant.js'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { getMyClassData } from '../../actions/class'
import loginApi from '../../api/login'
import { withRouter } from 'react-router-dom'
import logo from '../../assets/newImg/logo.png'

import tool from '../../utils/commonFuc'
import AccountModal from '../../views/home/AccountModal'

const { Option } = Select;
class MyHeader extends React.Component{
    state = {
        userName:'GZ00923',
        avatarUrl:'./imgs/default@2x.png',
        visible:false,
        showCode:false,//APP下载二维码
        showBtn:false,//头像下的按钮
        passwordModal:false,//修改密码模态框
        tenantId: this.$tool.sessionStorage.getItem('tenantId') ? [Number(this.$tool.sessionStorage.getItem('tenantId'))] : [],
        tenantList:this.$tool.sessionStorage.getItem('tenantList') ? this.$tool.sessionStorage.getItem('tenantList') : [],
        showTenant:false,
        loading:false,
        logoPath: '',
        isCustomEdition: true,
        accountModal: false, // 切换账号        
    }

    componentDidMount() {
        let json = localStorage.getItem('tenantInfo')
        let b = false
        let path = ''
        if(json && json.length > 0) {
            let tenantInfo = JSON.parse(json)
            b = tenantInfo.originOrgType === 1
            path = tenantInfo.logo
        }
        this.setState({
            logoPath: path,
            isCustomEdition: b
        })
    }

    componentWillMount(){
        if(sessionStorage.getItem('userInfo')){
            this.setState({
                userName:JSON.parse(sessionStorage.getItem('userInfo')).name,
                avatarUrl:JSON.parse(sessionStorage.getItem('userInfo')).avatarUrl || "./imgs/default@2x.png",
            })
        }
        this.checkTenant(this.props);
    }

    componentWillReceiveProps(newProps){
        this.checkTenant(newProps);
    }
    checkTenant = (props) => {
        if(props.pathname == "/home"){
            this.setState({
                showTenant:this.$tool.sessionStorage.getItem('showTenant') ? this.$tool.sessionStorage.getItem('showTenant') : false,        
            })
        }else{
            this.setState({
                showTenant:false,        
            })
        }
    }
    loginOut = () => {
        Modal.confirm({
            title:'即将退出登录',
            // content:"确认退出？",
            onOk: () => {
                api.loginOut({},res => {
                    if(res){
                        this.props.push(tool.getPushLoginParam())
                        window.location.reload()
                    }
                })
            },
            onCancel: () => {

            },
        })
    }
    showATag = () => {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {}
        /* if(userInfo.accountId && userInfo.accountId!==''){
            return (
                <span className="inline_block user_tip">
                    学员
                </span>
            )
        }else {
            return(
                <a onClick={this.handleATag} style={{marginLeft:'12px',textDecoration:'underline'}}>
                    完善身份信息
                </a>
            )
        } */
        if(userInfo.accountId && userInfo.accountId!==''){
            return <div style={{cursor:'default'}}>学员：{this.state.userName}</div>
        }else {
            return <div style={{cursor:'pointer'}} onClick={this.handleATag}>完善身份信息</div>
        }
    }
    handleATag = (e) => {
        e.preventDefault();
        this.setState({
            visible: true,
        });
    }
    handleCancel = (e) => {
        this.setState({
            visible: false,
            accountModal: false,
            passwordModal:false,
        })
        this.props.form.resetFields()
    }
    exportMouseEnter = () => {
        this.setState({
            showCode:true
        })
    }
    exportMouseLeave = () => {
        this.setState({
            showCode:false
        })
    }
    // 头像hover
    showBtnList = ()=>{
        this.setState({
            showBtn:true
        })
    }
    hideBtnList =()=>{
        this.setState({
            showBtn:false
        })
    }
    handleSelectChange(tenantId) {
        let _data = {
            tenantId:tenantId
        }   
        console.log('1. 准备切换机构：',this.state.tenantList)     
        tenantApi.changeTenantData(_data,(res) => {
            console.log('切换成功:',res)
            message.success('切换成功');        

            this.setState({
                tenantId,
            })            
            // 更新缓存
            this.$tool.sessionStorage.setItem('tenantId',tenantId);            

            sessionStorage.removeItem('subjectId')
            sessionStorage.removeItem('subjectName')
            sessionStorage.removeItem('subjectType')
            sessionStorage.removeItem('defaultValue')
            sessionStorage.removeItem('teachPlanId')
            sessionStorage.removeItem('selectedClass')

            // 1.2.1 新增
            // 第一步切换机构成功， 第二步请求一次用户信息
            let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
            let infoData = {
                id: null,
                rentId: tenantId,
                qryParam: userInfo.qryParam
            }
            this.state.tenantList.map(item =>{
                console.log('personId:',item.personId)
                if (item.value === tenantId) {
                    console.log('配对成功')
                    infoData.id = item.personId
                }
            })
            console.log('参数表：',infoData)
            tenantApi.getCurrentPersonInfo(infoData, res =>{
                console.log('切换账号之后，刷新用户信息：',res)
                tenantApi.getTenantInfo({rentId:tenantId}, (res1)=>{            
                    console.log('第三步重新获取机构信息',res1)
                    window.location.reload();
                })
            })
        })
        
        
        

        
    }
    // 切换tab
    changeTab(tabIndex){
        localStorage.setItem('tabIndex',tabIndex)
        console.log(this.props,'==============================',tabIndex)
        this.props.history.push({pathname:'/home',search:`tabIndex=${tabIndex}`})
        if(tabIndex===1){

        }else if(tabIndex===2){

        }
    }
    
    // 修改密码
    showPasswordModal = (e)=>{
        e.preventDefault();
        this.setState({
            passwordModal: true,
        })
    }
    updatePassword = (e)=>{
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    loading:true
                })
                loginApi.updatePassword(values,res=>{
                    this.setState({
                        loading:false
                    })
                    if(res){
                        message.success('密码修改成功，请重新登录，2秒后自动跳转到首页')
                        this.handleCancel()
                        setTimeout(() => {
                            api.loginOut({},res => {
                                console.log('logout->',res)
                                if(res){
                                    this.props.push(tool.getPushLoginParam())
                                    window.location.reload()
                                }
                            })
                        }, 2000);
                    }
                })
            }
        });
    }

    //1.2.1 判断是否手机号、身份证号登录
    filterAccount() {
        let data = JSON.parse(sessionStorage.getItem('userInfo'))
        let qryParam = ''
        let phone =  ''
        let cardNo = ''
        if (data && data.qryParam) {
            qryParam =  data.qryParam 
        }
        if (data && data.phone) {
            phone =  data.phone 
        }
        if (data && data.cardNo) {
            cardNo =  data.cardNo 
        }
        if (qryParam === phone || qryParam === cardNo) {
            console.log('手机号或证件号登录，不展示已切换账号')
            return false
        }else {
            console.log('账户名登录，展示切换账号')
            return true
        } 
    }
    toAgreementList(){
        this.props.history.push({pathname:'/home/agreementList'})
    }
    render(){
        let { tenantId, showTenant, tenantList } = this.state;
        let tabIndex = localStorage.getItem('tabIndex')
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 0 },
              sm: { span: 0 },
            },
            wrapperCol: {
              xs: { span: 16 },
              sm: { span: 24 },
            },
        }
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 0,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 0,
                },
            },
        }
        // 切换账号
    const showAccountModal = (e) => {
        this.setState({
            accountModal: true
        })
    }
        return(
            <div className="header_box">
                <div className="title_content">
                    <div style={{height:'40px',display:'flex',alignItems:'center'}}>
                        <div style={{width:'270px',height:'48px'}}>
                            <img height="100%" alt="这里是一张图片" src={this.state.isCustomEdition ? this.state.logoPath : logo} />
                            {/* <img width="100%" height="100%" alt="这里是一张图片" src={'./imgs/logo.png'} /> */}
                        </div>
                        <div className="main-tab">
                            <div onClick={()=>{this.changeTab(1)}} className={tabIndex==='1'?'main-tab-item active-tab':'main-tab-item'}>班级学习</div>
                            <div onClick={()=>{this.changeTab(2)}} className={tabIndex==='2'?'main-tab-item active-tab':'main-tab-item'}>题库</div>
                            <div onClick={()=>{this.changeTab(3)}} className={tabIndex==='3'?'main-tab-item active-tab':'main-tab-item'}>学习资料</div>
                        </div>
                        <Select className={showTenant == "true" ? '' : 'dn'} value={tenantId} style={{ width: 220, marginLeft: 100 }} onChange={(val)=>{this.handleSelectChange(val)}}>
                            {
                                tenantList.map((e)=>(
                                    <Option key={e.value} value={e.value}>{e.label}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div className='inline_block title_right_box'>
                        {/* APP下载 */}
                        <span className="APP-download" onMouseEnter={this.exportMouseEnter} onMouseLeave={this.exportMouseLeave} style={{cursor:'pointer'}}>APP下载</span>
                        {/* 头像 */}
                        <div className="avatar-module" onMouseEnter={this.showBtnList} onMouseLeave={this.hideBtnList}>
                            <div className='avatarUrl'>
                                <img width="100%" height="100%" alt="这里是一张图片" src={this.state.avatarUrl} />
                            </div>
                            <div className="avatar-btn-list" style={{display:this.state.showBtn?'block':'none'}}>
                                {this.showATag()}
                                <div style={{cursor:'pointer'}} onClick={()=>{this.toAgreementList()}}>查看签署协议</div> 
                                <div style={{cursor:'pointer'}} onClick={this.showPasswordModal}>修改密码</div> 
                                {this.filterAccount() && <div style={{cursor:'pointer'}} onClick={showAccountModal}>切换账号</div> }
                                <div style={{cursor:'pointer'}} onClick={this.loginOut}>退出登录</div>
                            </div>
                        </div>
                        {/* <i style={{cursor:'pointer'}} onClick={this.loginOut}>退出登录</i> */}
                        {/* APP二维码 */}
                        <div className={`${this.state.showCode?'show_code':'code_box'}`}>
                            <div style={{width:'85px',height:'85px'}}>
                                <img width="100%" height="100%" alt="这里是一张图片" src={"./imgs/code.png"} />
                            </div>
                            <p style={{marginTop:'14px'}}>扫码下载</p>
                        </div>
                    </div>
                </div>
                <Modal
                    title="完善身份信息"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={419}
                >   
                    <PerfectModal visible={this.state.visible}></PerfectModal>
                </Modal>
                {/* 切换账号模态框 */}
                <Modal title="切换账号" visible={this.state.accountModal} onCancel={this.handleCancel} width={419} footer={null}>
                    <AccountModal></AccountModal>
                </Modal>
                {/* 修改密码模态框 */}
                <Modal
                    title="修改密码"
                    visible={this.state.passwordModal}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={419}
                >
                    <Form {...formItemLayout} onSubmit={this.updatePassword} className="update-password-form" style={{width:'344px',margin:'0 auto'}}>
                        <Form.Item label="">
                            {getFieldDecorator('passwd', {
                                rules: [
                                    {required: true,message: '请输入原密码',whitespace:true},
                                ],
                            })(<Input.Password style={{height:'50px'}} maxLength={12} placeholder="请输入原密码" />)}
                        </Form.Item>
                        <Form.Item label="">
                            {getFieldDecorator('newPasswd', {
                                rules: [
                                    // {required: true,message: '请输入8-12位新密码',whitespace:true},
                                    {
                                        validator:(rule, value, callback) => {
                                            let reg = /(?!.*[\u4E00-\u9FA5\s])(?!^[a-zA-Z]+$)(?!^[\d]+$)(?!^[^a-zA-Z\d]+$)^.{8,12}$/
                                            console.log(reg.test(value))
                                            if(value && reg.test(value)){
                                                callback()
                                            }else{
                                                callback('请输入8~12位字符，密码至少由字母，数字或特殊符号中2种字符组成区分大小写')
                                            }
                                          }
                                    }
                                ],
                            })(<Input.Password style={{height:'50px'}} maxLength={12} placeholder="请输入新密码" />)}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{fontSize: '18px',color:'inherit',background:'#fede24',width:'344px',height:'50px',border:'none',borderRadius:25}}>确定</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

MyHeader.propTypes = {
    getMyClassData:PropTypes.func,
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({  
    getMyClassData:bindActionCreators(getMyClassData,dispatch),
})

export default Form.create({})(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyHeader)))
