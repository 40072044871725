import React, { Component } from 'react';
import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
// import PropTypes from 'prop-types'
import './App.css';
import { Layout } from 'antd';
import { Route } from 'react-router-dom'
import Routes from './router'
import { getRouterArr } from './reducer/user' 
import MyHeader from './views/header/MyHeader.js'
import NewMyHeader from './components/Header/index.js'


const { Header, Content } = Layout;


class App extends Component {
    state = {
        isHjx:sessionStorage.getItem('rentId') || false
    } 
    render() {
        const pathname = this.props.location.pathname;
        return (
            <Layout style={{minHeight:'100vh'}}>
                <Header style={{backgroundColor:'#ffffff'}}>
                    {/* 招服 */}
                    {this.state.isHjx && <NewMyHeader pathname={pathname} {...this.props.history}></NewMyHeader>}
                    {/* 自营 */}
                    {!this.state.isHjx && <MyHeader pathname={pathname} {...this.props.history}></MyHeader>}
                </Header>
                {/* <Content style={{width:'1252px',margin:'0 auto'}}> */}
                    <Routes auth={this.state}></Routes>
                {/* </Content> */}
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    routerArr: getRouterArr(state.user),
})

const mapDispatchToProps = (dispatch) => ({  
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
