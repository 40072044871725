import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { Icon, message, Carousel } from 'antd'
import api from '../../api/homePage.js'
import tenantApi from '../../api/tenant'
const Index = (propsObj)=>{
    const [state,setState] = useState({
        aboutUsContent:''
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
        const init = async ()=>{
            let pcUrl = window.location.hostname
            let rentInfo = await tenantApi.getTenantInfo({pcUrl:pcUrl})
            if(rentInfo){
                let rentId = rentInfo.id
                api.getAboutus({rentId:rentId},res=>{
                    updateState({aboutUsContent:res.aboutUsContent})
                })
            }
        }
        if (!sessionStorage.getItem('rentId')) {
            init()
            return
        }
        // 获取关于我们
        api.getAboutus({rentId:sessionStorage.getItem('rentId')},res=>{
            updateState({aboutUsContent:res.aboutUsContent})
        })
    },[])

    const onChange = ()=>{

    }

    return <div className="home-page-wrap">
        <div className="about-us-wrap">
            <div className="about-us-title">让学员享受到极致的教育服务</div>
            <div className="about-us-org-intro">
                <img src={require('../../assets/homePage/org-intro.png')} />
                {/* 富文本 */}
                <div className="org-intro-desc">
                    <div dangerouslySetInnerHTML={{__html:state.aboutUsContent}}></div>
                </div>
            </div>
        </div>
    </div>
}
export default withRouter(Index)