import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import PolvyVideo from '../../../views/Open/Video/PolvyVideo'
import tool from '../../../utils/commonFuc'

const PolyvVideoContainers = (props) => {
    const [state,setState] = useState({
        
    })
    //更新state
    const updateState = (newState)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    useEffect(()=>{
        
    },[])
    return <PolvyVideo></PolvyVideo>
}
export default withRouter(PolyvVideoContainers)