import React from 'react'
import { withRouter } from 'react-router-dom'
import { message } from 'antd'

import './exericeList.scss'
import '../yearTruth/yearTruth.scss'
import yuanImg from '../../assets/img/yuan@2x.png'
class MyClass extends React.Component{

    handleExam = (paperData) => {
        if(paperData.deleted){
            message.warning("该试卷已删除下架，当前仅支持查看做题记录哦~")
            return
        }
        if(paperData.testStatus === 4){
            this.props.history.push({pathname:"/home/examResult",query:{examUserId:paperData.examUserId}})
        }else{
            const url = process.env.Text[3];
            let aLink = document.createElement("a");
            aLink.target = '_blank';
            let json = localStorage.getItem('tenantInfo')
            let originOrgType = 0
            if(json && json.length > 0) {
                let tenantInfo = JSON.parse(json)
                originOrgType = tenantInfo.originOrgType
            }
            let str = `/examPC/#/home?token=${sessionStorage.getItem('token')}&id=${paperData.examId}&subjectId=${sessionStorage.getItem('subjectId')}&originOrgType=${originOrgType}`
            if(paperData.testUserId){
                str = `/examPC/#/home?token=${sessionStorage.getItem('token')}&id=${paperData.examId}&testUserId=${paperData.testUserId}&subjectId=${sessionStorage.getItem('subjectId')}&originOrgType=${originOrgType}`
            }
            aLink.href = url + str;
            document.querySelector("body").appendChild(aLink);
            aLink.click();
            // this.props.history.push({pathname:"/home/examResult",query:{examUserId:item.examUserId}})
        }
    }

    handleSeeResult = (union,event) => {
        if(union.testUserId){
            this.props.history.push({pathname:"/home/examResult",query:{examUserId:union.testUserId}})
        }else{
            message.error("缺少考试ID参数，无法查看结果")
        }
    }
    render(){
        const { paperData, noCollapse} = this.props;
        if(noCollapse){
            return <div className="exercise-bar-wrap">
                <div className="left_text_box" style={{display:'flex',alignItems:'center'}}>
                    {paperData.paperName}
                </div>
                <div className="center_text_box center_style">当前做题进度：{paperData.testView}&nbsp;&nbsp;|&nbsp;&nbsp;上次做题正确率：{`${paperData.userUnionList && paperData.userUnionList.length>0?paperData.userUnionList[0].correctPercent:'-'}`}</div>
                <div className="right_text_box">
                    {paperData.userUnionList && paperData.userUnionList.length>0 && <div className='default_btn' onClick={this.handleSeeResult.bind(this,paperData.userUnionList[0])}>做题详情</div>}
                    <div className='has_bg_color' onClick={this.handleExam.bind(this,paperData)}>{paperData.testUserId?'继续练习':'开始练习'}</div>
                </div>
            </div>
        }
        return (
            <div className="exercise-bar-wrap">
                <div className="left_text_box left_style">
                    {paperData.paperName}
                </div>
                <div className="center_text_box center_style">当前做题进度：{paperData.testView}&nbsp;&nbsp;|&nbsp;&nbsp;上次做题正确率：{`${paperData.userUnionList && paperData.userUnionList.length>0?paperData.userUnionList[0].correctPercent:'-'}`}</div>
                <div className="right_text_box">
                    {paperData.userUnionList && paperData.userUnionList.length>0 && <div className='default_btn' onClick={this.handleSeeResult.bind(this,paperData.userUnionList[0])}>做题详情</div>}
                    <div className='has_bg_color' onClick={this.handleExam.bind(this,paperData)}>{paperData.testUserId?'继续练习':'开始练习'}</div>
                </div>
            </div>
        )
    }
}

export default withRouter(MyClass)