import React, { memo } from 'react';

import './index.scss'
//视频模块
const VideoIntroductMoudle = memo((props) => {
    
    const {mainTitle,subTitle,videoUrl} = props.videoInfo
    return (
        <div className='video-intro-wrap'> 
            <div className='header-main-box'>
                <span className='header-title'>{mainTitle}</span>
                <span className='header-subTitle'>{subTitle}</span>

            </div>
            <div className='video-player-box'>
                <video controlsList="nodownload" controls className='video-content'   src={videoUrl}></video>
            </div>
            
        </div>
    );
});

export default VideoIntroductMoudle;