import React from 'react'
import { withRouter } from 'react-router-dom'
import api from '../../../api/class'

import { Icon, message } from 'antd'
import moment from 'moment'
import tool from '../../../utils/commonFuc'
/* eslint-disable */
class BjyLivePlayer extends React.Component{
    state = {
        channelId:'',
        sectionId:'',//提交学习记录用
        productId:'',//产品ID，提交学习记录用
        subjectId:'',//科目ID，提交学习记录用
        teachPlanId:'',//教学计划ID，提交学习记录用
        startTime:undefined,
        liveStatus:'unknow',//直播状态
        timer:null,//定时器
        timer2:null,//监听进入直播间后，还没开始直播到直播状态的定时器
        params:{
            room_id:'string',//房间ID
            user_number:'int',//合作方账号体系下的用户ID号
            user_name:'string',//显示的用户昵称
            user_role:'int',//0:学生 1:老师 2:管理员
            sign:'string',//签名
            user_avatar:'',//用户头像
            // group_id:int,//分组号，默认0不分组，只有分组直播才用到
        },
        sign:'',
        randomNumber:undefined,//随机数
    }
    componentWillMount(){
        document.title = ''
        message.loading('',0)
        let openAppId = tool.getQueryString(this.props.history,'appId')
        let openParam = tool.getQueryString(this.props.history,'param')
        if(openAppId && openParam){
            sessionStorage.setItem('openAppId',openAppId)
            // openParam = decodeURIComponent(openParam)
            sessionStorage.setItem('openParam',openParam)
            window.location.replace(`${window.location.protocol}//${window.location.host}${window.location.pathname}#/open/video/live`)
            this.init(openAppId,openParam)//初始化页面
        }else{
            let openAppId = sessionStorage.getItem('openAppId')
            let openParam = sessionStorage.getItem('openParam')
            if(openAppId && openParam){
                this.init(openAppId,openParam)//初始化页面
            }
        }
    }
    componentWillUnmount(){
        this.saveStudyRecord()
        clearInterval(this.state.timer)//清除计时器
    }
    // 页面初始化
    init(openAppId,openParam){
        //获取视频播放参数
        api.getVideoParameters({appId:openAppId,param:openParam},res=>{
            if(res){
                sessionStorage.setItem('token',res.token)
                this.setState({
                    channelId:res.channelId,
                    sectionId:res.sectionId,
                    productId:res.productId,
                    subjectId:res.subjectId,
                    teachPlanId:res.teachPlanId,
                    isAudition:res.isAudition,//1-游客
                    params:{
                        room_id:res.channelId+'',
                        user_number:res.id,
                        user_name:res.name,
                        user_role:0,
                        user_avatar:res.avatarUrl?res.avatarUrl:'',
                    }
                },()=>{
                    const {params} = this.state
                    //获取百家云直播签名
                    api.getBaijiayunLiveSign({
                        channelId:this.state.channelId,
                        personId:params.user_number,
                        name:params.user_name,
                        avatarUrl:params.user_avatar,
                    },res => {
                        message.destroy()
                        this.setState({
                            sign:res.sign
                        })
                        this.getStatusByChannelId(1)
                    })
                })
            }else{
                message.destroy()
            }
        })
    }
    //保存学习时间
    saveStudyRecord = () => {
        const { sectionId, channelId, productId, subjectId, teachPlanId, startTime, isAudition } = this.state;
        if(isAudition==1){//游客
            return
        }
        // 判断是否新页面打开了直播
        let liveArr = localStorage.getItem('liveArr')
        if(liveArr){
            liveArr = JSON.parse(liveArr)
            for(let v of liveArr){
                if(parseInt(v.channelId) === parseInt(channelId)){
                    if(v.randomNumber !== this.state.randomNumber){
                        console.log('旧的直播页面')
                        clearInterval(this.state.timer)//清除计时器
                        return
                    }
                    break
                }
            }
        }
        let nowTime = (new Date()).getTime();
        let time = nowTime - startTime
        console.log('startTime------',startTime)
        console.log('nowTime------',nowTime)
        console.log('time------',time)
        if(time>=10000 && startTime>0){
            let obj = {
                sectionType:'1',
                channelId:channelId,
                sectionId:sectionId,
                productId:productId,
                subjectId:subjectId,
                teachPlanId:teachPlanId,
                studyTime:Math.round(time/1000),
                beginStudyDate:moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
                endStudyDate:moment(nowTime).format('YYYY-MM-DD HH:mm:ss'),
            }
            api.addStudyRecord(obj,(res) => {
                if(res){
                    this.setState({ 
                        startTime:nowTime,
                    })
                }
            });
        }
    }
    //获取直播状态
    getStatusByChannelId = (flag) =>{
        api.getStatusByChannelId({type:2,channelId:this.state.channelId},res => {
            let liveStatus = this.state.liveStatus
            if(liveStatus==='unknow'){
                if(res.status===true){//开始直播了
                    this.setState({
                        liveStatus:'live',
                        startTime:new Date().getTime()
                    })
                    if(flag){//第一次
                        if(flag===1){
                            this.setRandomNumber()//设置随机数
                        }
                        setTimeout(()=>{
                            this.saveStudyRecord()
                        },10000)
                        if(this.state.timer2){
                            clearInterval(this.state.timer2)
                        }
                        console.log(`-----开始直播了，正则监听，5mins一次-----`)
                        this.setState({
                            timer:setInterval(()=>{
                                this.getStatusByChannelId()
                            },300000),
                            timer2:null
                        })
                    }
                }else if(res.status===false){//还没开始直播
                    if(!this.state.timer2){
                        this.setState({
                            timer2:setInterval(()=>{
                                console.log(`-----还没开始直播，正则监听，60s/次-----`)
                                this.getStatusByChannelId(1)
                            },60000)
                        })
                    }
                }
            }else if(liveStatus==='live'){
                if(res.status===true){//还在直播
                    if(this.state.startTime>0){
                        this.saveStudyRecord()
                    }
                }else if(res.status===false){//从直播→下播
                    console.log(`-----中场休息/直播结束，提交学习记录-----`)
                    clearInterval(this.state.timer)//清除计时器
                    if(this.state.startTime>0){//直播结束
                        this.saveStudyRecord()
                    }
                    this.setState({
                        liveStatus:'unknow',
                        timer2:setInterval(()=>{
                            console.log(`-----中场休息，正则监听，60s/次-----`)
                            this.getStatusByChannelId(2)
                        },60000)
                    })
                }
            }
        })
    }

    // 防止多个页面观看上传学习记录，设置随机数
    setRandomNumber = ()=>{
        let randomNumber = Math.floor(Math.random()*100000000)
        this.setState({
            randomNumber:randomNumber
        })
        let obj = {
            channelId:parseInt(this.state.channelId),
            randomNumber:randomNumber
        }
        console.log(`randomNumber=${this.state.randomNumber}`)
        let liveArr = localStorage.getItem('liveArr')
        if(liveArr){
            liveArr = JSON.parse(liveArr)
            let len = 0
            for(let v of liveArr){
                if(parseInt(v.channelId) === parseInt(this.state.channelId)){
                    // if(v.randomNumber)
                    v.randomNumber = randomNumber
                    break
                }else{
                    len++
                }
            }
            if(len === liveArr.length){//没保存
                liveArr.push(obj)
            }
        }else{
            liveArr = [obj]
        }
        localStorage.setItem('liveArr',JSON.stringify(liveArr))
    }
    render(){
        return (
            <div style={{width:'100%',height:'100%'}} id="live-player">
                {this.state.sign&&<iframe style={{width:'100%',height:'100%'}} 
                scrolling="no" frameBorder="0" src={`https://b53939712.at.baijiayun.com/web/room/enter?room_id=${this.state.params.room_id}&user_number=${this.state.params.user_number}&user_role=${this.state.params.user_role}&user_name=${this.state.params.user_name}&user_avatar=${this.state.params.user_avatar}&sign=${this.state.sign}`}></iframe>}
            </div>
        )
    }
}

export default withRouter(BjyLivePlayer)