import React, { memo, useRef, useState } from 'react';
// swiper start
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './index.scss'

SwiperCore.use([Navigation, Pagination, A11y, Autoplay])

const GraphicModule = memo((props) => {
    const [activeIndex,setActiveIndex]= useState(0)
    const [autoPlayStatus ,setAutoPlayStatus] = useState(true)
    const {showStyle,imageNavigationList,mainTitle,subTitle,lineNum} = props.graphicInfo
    const setActiveItem = (index) =>{
        console.log("mouseEnter=========",index);
        if(index == activeIndex){
            return
        }
        setActiveIndex(index)
        swiperRef.current.swiper.slideTo(index,1000,false) //跳转到指定页码
    }
    const mouseMove = () =>{
        // 鼠标移上去
        // console.log("mouseEnter=========",index);
        if(autoPlayStatus){
            // setAutoPlayStatus(false)
        }
        // if(index == activeIndex){
        //     return
        // }
        // setActiveIndex(index)
        // swiperRef.current.swiper.slideTo(index,1000,false) //跳转到指定页码
    }
    const mouseLeave = () =>{
        // 鼠标离开
        console.log("mouseLeave======",);
        // setAutoPlayStatus(true)

    }
    const jumpToNewTab = (v) =>{
        console.log("点击跳转",v);
        v.clickUrl && window.open(v.clickUrl)
    }
    const swiperRef = useRef(null)
    return (
        <div className="graphic-wrap">
            {/* <img className="home-page-content-title" src={require('../../../../assets/homePage/title-2.png')} /> */}
            <div className='header-main-box'>
                <span className='header-title'>{mainTitle}</span>
                <span className='header-subTitle'>{subTitle}</span>
            </div>
            {
                // 图文导航有两种样式，组件样式，1-样式1（多张图片），2-样式2 （单张图片）一种是一行显示4张，一种是显示三张
                showStyle == 1 ? <div  className={`graphic-card-module ${lineNum == 3 ?'graphic-card-module-style-3':''} ` }>
                {imageNavigationList.map((v, i) => {
                    return <div  className={`graphic-card-item ${lineNum == 3 ?'graphic-card-item-line-style-3' : 'graphic-card-item-line-style-4'}`} key={i} onClick={() => jumpToNewTab(v)}>
                            <img  className="icon-wrap" src={v.pictureUrl} />
                        {/* <div className="card-item-title">{v.title}</div>
                        <div className="card-item-desc">{v.desc}</div> */}
                    </div>
                })}
            </div>:<div className='graphic-swiper-warp'>
                <div className='graphic-swiper-left-tab-warp'>
                    {
                        imageNavigationList.map((val,i)=>{
                            return <div  className='swiper-tab-item-box' key={val.id} onMouseOver={()=>setActiveItem(i)} onMouseMove={mouseMove} onMouseLeave={mouseLeave}>
                                    <div className={`item-content ${activeIndex == i ?'active-select':''}`} onClick={() => jumpToNewTab(val)}>
                                        <p className='item-content-title'>{val.mainTitle}</p>
                                        <p className='item-content-subtitle'>{val.subTitle}</p>
                                    </div>
                                </div>
                        })
                    }

                </div>
            <Swiper
                ref={swiperRef}
                className="graphic-swiper-right"
                // spaceBetween={50}
                slidesPerView={1}
                // onSlideChange={(swiper) => console.log('slide change',swiper.activeIndex)}
                onActiveIndexChange={(swiper)=> setActiveIndex(swiper.activeIndex)}
                // onSwiper={(swiper) => console.log(swiper)}
                // navigation
                // loop={true}
                height={500}
                width={800}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                direction='vertical'
                initialSlide={activeIndex}
                pagination={{ clickable: false,type:'custom' }}
            // onSlideChange={() => console.log('slide change')}
            >
                {imageNavigationList.map((v, i) => {
                    return <SwiperSlide key={i} onClick={() => jumpToNewTab(v)}>
                        <img className="banner-img" src={v.pictureUrl}  />
                    </SwiperSlide>
                })}
            </Swiper>

            </div>
            }
        </div>
    );
});

export default GraphicModule;