import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss'
const HotCourseModule = memo((props) => {
    const jumpToCourseDetail = (id,compontentId)=>{
        // let obj = val.courseComponentId ? `id=${val.id}&courseComponentId=${val.courseComponentId}` : `id=${val.id}`
        props.history.push({pathname:'/courseDetail',search:`id=${id}&courseComponentId=${compontentId}`})
        // sessionStorage.setItem('showCoursePrice', showCoursePrice)
    }

    const jumpToCourseMorePage = (id) => {
        props.history.push({pathname: '/course',search:`courseComponentId=${id}`})
        sessionStorage.setItem('courseComponentId',id)
        // sessionStorage.setItem('showCoursePrice', showCoursePrice)
    }
    const {mainTitle,subTitle,productList,courseComponentId,hasMoreProduct} = props.courseInfo
    return (
        <div className="hot-course-wrap">
            <div className="hot-course-content-main" >
                <div className='header-main-box'>
                    <span className='header-title'>{mainTitle}</span>
                    <span className='header-subTitle'>{subTitle}</span>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '32px', width: '1220px' }}>
                    {productList.length > 0 && productList.map((product, productIndex) => {
                        return <div key={productIndex}>
                            <div className="hot-course-content-item" onClick={() => { jumpToCourseDetail(product.id, courseComponentId) }}>
                                {product.isAudition==1 && <div className='free-live-icon'>免费试看</div>}
                                <div className="hot-course-content-img" style={{ backgroundImage: `url(${product.productUrl})` }}></div>
                                <div className="hot-course-content-text">
                                    <div className="hot-course-content-title clamp2">{product.name}</div>
                                    {props.showCoursePrice == 1 && <span className="hot-course-content-price">￥{product.minPrice ? `${product.minPrice} ~ ￥${product.maxPrice}` : `${product.maxPrice}`}</span>}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                { hasMoreProduct && <div className="more-btn-box" onClick={() => { jumpToCourseMorePage(courseComponentId) }}>
                    <div>更多课程</div>
                </div>}
            </div>
        </div>
    );
});

export default withRouter(HotCourseModule);