import React, { memo } from 'react';
import { Tooltip } from 'antd';
const TeacherInfoCard = memo((props) => {
    const {name,imgUrl,introduce} = props.info
    const lineNum = props.lineNum
    return (
        <div className={`teacher-info-card-item ${lineNum == 3 ?'teacher-info-item-line-style-3' : 'teacher-info-item-line-style-4'}`}>
            <img className='teacher-info-img' src={imgUrl} alt="" />
            
            <div className='teacher-info-card-content'>
                <p className='teacher-info-name'>{name}</p>
                <div className='teacher-info-desc'>
                    <Tooltip placement="bottom" title={introduce }>
                        <p >{introduce}</p>
                    </Tooltip>

                </div >
            </div>
        </div>
    );
});

export default TeacherInfoCard;