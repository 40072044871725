import React, { Component } from 'react';
import { HashRouter as Router, Route ,Redirect, Switch } from 'react-router-dom';
import Login from './containers/Login/startPageContainers'
import HomePage from './containers/HomePage/HomePageContainers'
import Course from './containers/HomePage/CourseContainers'
import CourseDetail from './containers/HomePage/CourseDetailContainers'
import AboutUs from './containers/HomePage/AboutUsContainers'
import ForgetPass from './views/ForgetPass/index'
import PhoneVerification from './containers/Login/phoneVerificationContainers'
import NotFount from './views/NotFound/index'
import Exercise from './containers/Exercise/ExerciseContainers'
// import SetPassword from './containers/Login/setPasswordContainers'
// 对外开放页面
import BjyLivePlayerContainers from './containers/Open/Video/BjyLivePlayerContainers'
import BjyVideoContainers from './containers/Open/Video/BjyVideoContainers'
import PolyvVideoContainers from './containers/Open/Video/PolyvVideoContainers'
/* 外部API引入新起点题库start */
import NewExamTest from './containers/NewExam/NewExamTestContainers'
/* 外部API引入新起点题库end */

/* 外部API引入新起点课程start */
import NewVedioPlayContainer from './containers/NewVedioStudy/NewVedioPlayContainers'
import NewLivingPlayContainer from './containers/NewVedioStudy/NewLivingPlayContainers'

/* 外部API引入新起点课程end */

import { message } from 'antd'
import App from './App'
import {
    Provider,
    KeepAlive,
} from 'react-keep-alive'
import TeacherIntroContainers from './containers/HomePage/TeacherIntroContainers';  //更多教师页面
class Page extends Component {
    componentDidMount(){
        
    }
    routerView = () => {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
            "SymbianOS", "Windows Phone",
            "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        if(flag){  //为电脑端才让访问，移动端限制访问
            return (
                <Provider>
                <Switch>
                    <Route exact path="/" render={() => <Redirect to="/homePage" push />}></Route>
                    <Route path="/home" component={App}></Route>
                    {/* 首页 */}
                    <Route path="/homePage" component={HomePage}></Route>
                    {/* open——直播、回放、录播 */}
                    <Route path="/open/video/live" component={BjyLivePlayerContainers}></Route>
                    <Route path="/open/video/playback" component={BjyVideoContainers}></Route>
                    <Route path="/open/video/playVideo" component={PolyvVideoContainers}></Route>
                    <Route path="/course" component={Course}></Route>
                    <Route path="/teacher" component={TeacherIntroContainers}></Route>

                    {/* <Route path="/courseDetail" component={CourseDetail}></Route> */}
                    <Route path="/courseDetail">
                        <KeepAlive name="CourseDetail">
                            <CourseDetail />
                        </KeepAlive>
                    </Route>
                    <Route path="/aboutUs" component={AboutUs}></Route>

                    <Route path="/newExamTest" component={NewExamTest}></Route>
                    <Route path="/newLivingPlay" component={NewLivingPlayContainer}></Route>
                    <Route path="/newVedioPlay" component={NewVedioPlayContainer}></Route>

                    <Route path="/404" component={NotFount}></Route>
                    <Route exact path="/login" render={() => <Redirect to="/phoneLogin/index" push />}></Route>
                    
                    <Route exact path="/forgetPass" component={ForgetPass}></Route>       
                    <Route path="/phoneLogin" component={PhoneVerification}></Route>   
                    {/* <Route path="/phoneLogin/setPassword" component={SetPassword}></Route>  */}
                    <Route component={NotFount} />
                </Switch>
                </Provider>
            )
        }else{
            return (
                <div>
                    
                </div>
            )
        }
    }
    render() {
        return (
            <Router>
                {this.routerView()}
                {/* <Switch>
                    <Route exact path="/" render={() => <Redirect to="/home" push />}></Route>
                    <Route path="/home" component={App}></Route>
                    <Route path="/404" component={NotFount}></Route>
                    <Route exact path="/login" component={Login}></Route>
                    <Route exact path="/forgetPass" component={ForgetPass}></Route>                    
                    <Route component={NotFount} />
                </Switch> */}
            </Router>
            );
        }
    }

export default Page;
