import React from 'react';
import { message } from 'antd'
import api from '../../../api/class'
import { withRouter } from 'react-router-dom'

import tool from '../../../utils/commonFuc'
import moment from 'moment'
/* eslint-disable */
class BjyVideo extends React.Component{
    state = {
        startTime:undefined,
        lastStudyTime:null,
        timer:null,//定时器
        roundTime:10000,//定时器秒数
        option:{
            // width: 375,
            // height: 211,
            show_rate:2,   //  允许播放的最高清晰度 1（流畅）、2（高清）
            autoplay:true,
            hideRepeat:true,  //播放结束后是否显示重播面板
            // flash: true,  //是否有限选择flash播放
            hideSwitchPlayer:true,  // 是否禁止显示HTML5与flash播放器切换按钮
            speed: [2, 1.5, 1.2, 0.5],
            showHd: true
        },
        originOrgType:0,
    }
    player = null;
    componentWillMount(){
        document.title = ''
        message.loading('',0)
        let openAppId = tool.getQueryString(this.props.history,'appId')
        let openParam = tool.getQueryString(this.props.history,'param')
        if(openAppId && openParam){
            sessionStorage.setItem('openAppId',openAppId)
            // openParam = decodeURIComponent(openParam)
            sessionStorage.setItem('openParam',openParam)
            window.location.replace(`${window.location.protocol}//${window.location.host}${window.location.pathname}#/open/video/playVideo`)
            this.init(openAppId,openParam)
        }else{
            let openAppId = sessionStorage.getItem('openAppId')
            let openParam = sessionStorage.getItem('openParam')
            if(openAppId && openParam){
                this.init(openAppId,openParam)
            }
        }
    }
    // 页面初始化
    init(openAppId,openParam){
        //获取视频播放参数
        api.getVideoParameters({appId:openAppId,param:openParam},res=>{
            message.destroy()
            if(res){
                sessionStorage.setItem('token',res.token)
                localStorage.setItem('token',res.token)
                this.setState({
                    // videoId:res.videoId,
                    // videoUrl:res.videoUrl,
                    sessionId:res.sessionId,
                    sectionId:res.sectionId,
                    productId:res.productId,
                    subjectId:res.subjectId,
                    teachPlanId:res.teachPlanId,
                    lastStudyTime:res.lastStudyTime || 0,
                    isAudition:res.isAudition,//1-游客
                    userInfo:{
                        id:res.id,
                        name:res.name,
                        avatarUrl:res.avatarUrl
                    }
                },()=>{
                    // 创建保利威录播播放
                    const option = JSON.parse(JSON.stringify(this.state.option))
                    if(res.videoId){
                        option.vid = res.videoId
                    }else{
                        option.url = res.videoUrl
                    }
                    option.wrap = "#player"
                    option.watchStartTime = res.lastStudyTime
                    this.player = polyvPlayer(option)
                    window.s2j_onVideoPause = () => {
                        // console.log('onpause',new Date().getTime() - this.state.startTime)
                        // this.saveStudyRecord()
                        if(this.state.timer){
                            clearInterval(this.state.timer)
                            this.setState({timer:null})
                        }
                    }
                    window.s2j_onVideoPlay = () => {
                        if(this.state.timer){
                            clearInterval(this.state.timer)
                            this.setState({timer:null})
                        }
                        this.setState({
                            startTime:(new Date()).getTime(),
                            timer:setInterval(()=>{
                                console.log(`-----跑定时器啦-----${this.state.roundTime/1000}s/次(偶尔${this.state.roundTime/1000*2}s/次)`)
                                this.saveStudyRecord()
                            },this.state.roundTime)
                        })
                    }
                    window.s2j_onVideoOver = () => {
                        if(this.state.timer){
                            clearInterval(this.state.timer)
                            this.setState({timer:null})
                        }
                    }
                })
            }
        })
    }
    componentDidMount(){
        
    }
    componentWillUnmount(){
        console.log(999,'-----')
        if(this.state.timer){
            clearInterval(this.state.timer)
            this.setState({timer:null})
        }
        if (this.player && this.state.startTime !== 0) {
            if((new Date()).getTime() - this.state.startTime > 10000){
                this.saveStudyRecord((new Date()).getTime() - this.state.startTime,false)
            }
            this.player.destroy()
        }
    }
    saveStudyRecord = () => {
        const { sectionId, productId, subjectId, teachPlanId, startTime, isAudition } = this.state;
        if(isAudition==1){//游客
            return
        }
        let nowTime = (new Date()).getTime();
        let time = nowTime - startTime
        if(time>=10000 && startTime>0){
            let obj = {
                sectionType:'2',
                sectionId:sectionId,
                productId:productId,
                subjectId:subjectId,
                teachPlanId:teachPlanId,
                studyTime:Math.round(time/1000),
                lastStudyTime:parseInt(this.player.j2s_getCurrentTime()),
                beginStudyDate:moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
                endStudyDate:moment(nowTime).format('YYYY-MM-DD HH:mm:ss'),
            }
            api.addStudyRecord(obj,(res) => {
                if(res){
                    this.setState({ 
                        startTime:nowTime,
                    })
                }else{
                    
                }
            })
        }
    }
    render(){
        return (
            <div id="live-player" style={{width:'100%',height:'100%'}}>
                <div id="player" style={{width:'100%',height:'100%'}}>
                    
                </div>
            </div>
        )
    }
}

export default withRouter(BjyVideo)