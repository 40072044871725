import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon, message, Layout } from 'antd'

import MyHeader from '../../components/Header/index'
import NewExamTest from '../../views/NewExam/NewExamTest'

import tool from '../../utils/commonFuc'
import api from '../../api/newExam'

const { Header, Content } = Layout;
const NewExamTestContainers = (props)=>{
    const [state,setState] = useState({
        examInfo:{},//考试信息
        examTitle:'',//考试标题
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
        let examTitle = tool.getQueryString2('title')
        let charterSectionId = tool.getQueryString2('charterSectionId')
        let subjectId = tool.getQueryString2('subjectId')
        if(charterSectionId && subjectId){
            api.examStart({
                charterSectionId:charterSectionId,
                subjectId:subjectId,
            },res=>{
                if(res){
                    updateState({examInfo:res,examTitle:examTitle})
                }
            })
        }
    },[])
    
    return <div className="">
        <Layout style={{minHeight:'100vh'}}>
            {/* 头部 */}
            <Header style={{backgroundColor:'#ffffff'}}>
                <MyHeader pathname={props.location.pathname} {...props.history} showContactBtn={false}></MyHeader>
            </Header>
            <NewExamTest examInfo={state.examInfo} examTitle={state.examTitle}></NewExamTest>
        </Layout>
    </div>
}
const mapStateToProps  = (state) =>({
    
})

const mapDispatchToProps = (dispatch) => ({
    
})
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(NewExamTestContainers))