import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HashRouter as Router, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import tenantApi from '../../api/tenant'
import leftBg from '../../assets/newImg/load_bg.png'

import './phoneVerification.scss'
import '../../views/Login/phoneVerification.scss'

import PhoneVerification from '../../views/Login/phoneVerification'
import SetPassword from '../../views/Login/setPassword'
import PerfectInfo from '../../views/Login/perfectInfo'
import PasswordLogin from '../../views/Login/passwordLogin'
import RetrievePassword from '../../views/Login/retrievePassword'
import ResetPassNextStep from '../../views/Login/resetPassNextStep'
import AddAccount from '../../views/Login/addAccount'

import MyHeader from '../../components/Header/index'

import tool from '../../utils/commonFuc'

class phoneVerificationContainers extends React.Component{

    componentDidMount(){
        let logoUrl = ''
        let b = tool.isHJXTenant()
        if(b){
            // 新慧简学机构
            let json = localStorage.getItem('tenantInfo')
            let tenantInfo = JSON.parse(json)
            logoUrl = tenantInfo.logo
        }
        let json = localStorage.getItem('tenantInfo')
        if (json && json.length > 0) {
            let tenantInfo = JSON.parse(json)
            if(tenantInfo) {
                let favicon = document.createElement("link");
                favicon.rel = "icon";
                favicon.id = "favicon_icon";
                favicon.href = tenantInfo.iconImg?tenantInfo.iconImg:'https://file.mshiedu.com/POKO-icon-none.png';
                document.head.appendChild(favicon);
            }
        } else {
            const init = async ()=>{
                let pcUrl = window.location.hostname
                let arr = [
                    'localhost',
                    'aline.mshengedu.com',
                    'study.mshiedu.com',
                    'study.mshengedu.com',
                    'study.myunedu.com',
                    'grey.mshiedu.com',
                    'grey.mshengedu.com',
                ]
                let flag = true//是否独立域名
                for(let v of arr){
                    if(pcUrl.indexOf(v)>-1){
                        flag = false
                        break
                    }
                }
                if(flag){
                    let tenantInfo = await tenantApi.getTenantInfo({pcUrl:pcUrl})
                    if(tenantInfo){
                        let favicon = document.createElement("link");
                        favicon.rel = "icon";
                        favicon.id = "favicon_icon";
                        favicon.href = tenantInfo.iconImg?tenantInfo.iconImg:'https://file.mshiedu.com/POKO-icon-none.png';
                        document.head.appendChild(favicon);
                    }
                }
            }
            if (!sessionStorage.getItem('rentId')) {
                init()
            }    
        }
        this.setState({
            isCustomEdition: b,
            logoPath: logoUrl
        })
    }

    state = {
        isCustomEdition: true,
        logoPath: '',
        isHjx:sessionStorage.getItem('rentId') || false
    }

    static propTypes = {
        history: PropTypes.object,
    }
    
    render(){
        return (
            <div>
                {this.state.isHjx && <MyHeader pathname={this.props.location.pathname} {...this.props.history}></MyHeader>}
                <div className="phone_login">
                    <div className="left_box">
                        <img src={leftBg} alt="图片无法加载" width="auto" height="100%"></img>
                    </div>
                    <div className="left_blank_box">

                    </div>
                    <Router>
                        <Route exact path="/phoneLogin/index" component={PhoneVerification}></Route>
                        <Route exact path="/phoneLogin/setPassword" component={SetPassword}></Route>
                        <Route exact path="/phoneLogin/bindingInfo" component={PerfectInfo}></Route>
                        <Route exact path="/phoneLogin/passLogin" component={PasswordLogin}></Route>
                        <Route exact path="/phoneLogin/addAccount" component={AddAccount}></Route>
                        <Route exact path="/phoneLogin/retrievePassword" component={RetrievePassword}></Route>
                        <Route exact path="/phoneLogin/resetPassNextStep" component={ResetPassNextStep}></Route>
                    </Router>
                </div>
            </div>
        )
    }

}

const mapStateToProps  = (state) =>({
    // homeDateData:homeReducer.homeDateData(state.home),
})

const mapDispatchToProps = (dispatch) => ({
    // checkHomeDataOverviewData: bindActionCreators(homeAction.checkHomeDataOverviewData,dispatch),
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(phoneVerificationContainers)