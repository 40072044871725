import React from 'react'
import { Button, Form, Input, message } from 'antd';
import api from '../../api/login'

class PerfectModal extends React.Component{
    componentWillReceiveProps(nextProps){
        if(!nextProps.visible){
            nextProps.form.resetFields();
        }
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.cardType = 1
                let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
                values.studentAccount = userInfo.studentAccount
                console.log('参数:',values)
                api.editPerson(values, res => {
                    if(res){
                        this.props.form.resetFields();
                        message.success('保存成功');
                    }
                })
            }
        });
    };
    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 0 },
              sm: { span: 0 },
            },
            wrapperCol: {
              xs: { span: 16 },
              sm: { span: 24 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 0,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 0,
                },
            },
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{width:'344px',margin:'0 auto'}}>
                <Form.Item label="">
                    {getFieldDecorator('name', {
                        rules: [
                            {required: true,message: '请输入姓名',whitespace:true},
                        ],
                    })(<Input style={{height:'40px'}} placeholder="姓名" />)}
                </Form.Item>
                <Form.Item label="">
                    {getFieldDecorator('phone', {
                        rules: [
                            {required: true,message: '请输入手机号码',whitespace:true},
                        ],
                    })(<Input style={{height:'40px'}} placeholder="手机号码" />)}
                </Form.Item>
                <Form.Item label="">
                    {getFieldDecorator('cardNo', {
                        rules: [
                            {required: true,whitespace:true,validator:function(rule, value, callback){
                                const reg =  /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
                                if(value === ''){
                                    callback('请输入身份证号')
                                }else{
                                    if(reg.test(value)){
                                        callback()
                                    }else{
                                        callback('请输入正确的身份证号')
                                    }
                                }
                            }},
                        ],
                    })(<Input style={{height:'40px'}} placeholder="身份证" />)}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit" style={{fontSize: '18px',color:'#fff',background:'linear-gradient(to right, #F62C3D , #FF5837)',
                    width:'344px',height:'40px',border:'none'}}>
                        确定
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default Form.create()(PerfectModal)