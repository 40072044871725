import React from 'react'

class MainBox extends React.Component{

    render(){

        return (
            <div style={{width:'1200px',margin:'0 auto',background:'#ffffff'}}>
                {this.props.children}
            </div>
        )
    }
}

export default MainBox