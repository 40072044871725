import { message } from 'antd'
import { postData } from './index'

export default{
    getQryIndex:async (data, callback) => {  //官网轮播图列表
        return postData(`${process.env.Text[4]}/study/hjx/tenant/website/config/qryIndex`,data).then( res => {
            if(callback){
                callback(res)
            }
            return res
        }).catch( err => {
            console.log(err)
            return false
        })
    },
    getQryOfficialProdList:async (data, callback) => {  //查询官网热门产品信息
        return postData(`${process.env.Text[5]}/opensaas/operationadmin/official/qryOfficialProdList`,data).then( res => {
            if(res && res.recordList && res.recordList.length > 0) {
                for (let i = 0; i < res.recordList.length; i++) {
                    const type = res.recordList[i]
                    if(type.name.includes('(培训学院)')){
                        type.name = type.name.replace('(培训学院)','')
                        continue
                    }
                    if(type.name.includes('（培训学院）')){
                        type.name = type.name.replace('（培训学院）','')
                        continue
                    }
                    if(type.name.includes('培训学院')){
                        type.name = type.name.replace('培训学院','')
                        continue
                    }
                }
            }
            if(callback){
                callback(res)
            }
            return res
        }).catch( err => {
            console.log(err)
            return false
        })
    },
    getAboutus:(data, callback) => {  //获取关于我们
        return postData(`${process.env.Text[5]}/opensaas/enrolltenant/website/aboutus/${data.rentId}`,{}).then( res => {
            if(callback){
                callback(res)
            }
            return res
        }).catch( err => {
            console.log(err)
            return false
        })
    },
    // getCourseFilterParams:(data, callback) => {  //选课程-筛选条件获取接口
    //     // return postData(`${process.env.Text[5]}/opensaas/enrolltenant/website/filter/params`,data).then( res => {
    //     data.tryFlag = 0
    //     return postData(`${process.env.Text[4]}/study/applet/home/getConditions`,data).then( res => {
    //         if(res && res.projectTypes && res.projectTypes.length > 0) {
    //             for (let i = 0; i < res.projectTypes.length; i++) {
    //                 const type = res.projectTypes[i]
    //                 if(type.projectTypeName.includes('(培训学院)')){
    //                     type.projectTypeName = type.projectTypeName.replace('(培训学院)','')
    //                     continue
    //                 }
    //                 if(type.projectTypeName.includes('（培训学院）')){
    //                     type.projectTypeName = type.projectTypeName.replace('（培训学院）','')
    //                     continue
    //                 }
    //                 if(type.projectTypeName.includes('培训学院')){
    //                     type.projectTypeName = type.projectTypeName.replace('培训学院','')
    //                     continue
    //                 }
    //             }
    //         }
    //         callback(res)
    //     }).catch( err => {
    //         console.log(err)
    //     })
    // },
    getCourseFilterParams:(data, callback) => {  //1.21选课程-筛选条件获取接口
        // return postData(`${process.env.Text[5]}/opensaas/enrolltenant/website/filter/params`,data).then( res => {
        data.tryFlag = 0
        let url = data.courseComponentId ? `${process.env.Text[4]}/study/client/home/shopDecoration/getConditions` : `${process.env.Text[4]}/study/applet/home/getConditions`
        return postData(url,data).then( res => {
            if(res && res.projectTypes && res.projectTypes.length > 0) {
                for (let i = 0; i < res.projectTypes.length; i++) {
                    const type = res.projectTypes[i]
                    if(type.projectTypeName.includes('(培训学院)')){
                        type.projectTypeName = type.projectTypeName.replace('(培训学院)','')
                        continue
                    }
                    if(type.projectTypeName.includes('（培训学院）')){
                        type.projectTypeName = type.projectTypeName.replace('（培训学院）','')
                        continue
                    }
                    if(type.projectTypeName.includes('培训学院')){
                        type.projectTypeName = type.projectTypeName.replace('培训学院','')
                        continue
                    }
                }
            }
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getCourseList:async(data, callback) => {  //选课程-获取课程列表
        // return postData(`${process.env.Text[5]}/opensaas/enrolltenant/website/product/list`,data).then( res => {
        data.tryFlag = 0
        return postData(`${process.env.Text[4]}/study/applet/home/getProductList`,data).then( res => {
            if(res && res.recordList && res.recordList.length > 0) {
                for (let i = 0; i < res.recordList.length; i++) {
                    const type = res.recordList[i]
                    if(type.name.includes('(培训学院)')){
                        type.name = type.name.replace('(培训学院)','')
                        continue
                    }
                    if(type.name.includes('（培训学院）')){
                        type.name = type.name.replace('（培训学院）','')
                        continue
                    }
                    if(type.name.includes('培训学院')){
                        type.name = type.name.replace('培训学院','')
                        continue
                    }
                }
            }
            if(callback){
                callback(res)
            }
            return res
        }).catch( err => {
            console.log(err)
            return false
        })
    },
    getHomeComponentList:async (data, callback) => {  //1.21查询首页-所有组件列表
        return postData(`${process.env.Text[4]}/study/client/home/shopDecoration/getComponentList`,data).then( res => {
            if(callback){
                callback(res)
            }
            return res
        }).catch( err => {
            console.log(err)
            return false
        })
    },
    getCourseListData:async(data, callback) => {  //1.21选课程-获取课程列表
        // return postData(`${process.env.Text[5]}/opensaas/enrolltenant/website/product/list`,data).then( res => {
        data.tryFlag = 0
        let url = data.courseComponentId ? `${process.env.Text[4]}/study/client/home/shopDecoration/getProductList` : `${process.env.Text[4]}/study/applet/home/getProductList`
        if(data.courseComponentId){  // 这里接口需要区分是从那里进入的。  热门课程进入的选课程页面。查询时使用productName
            delete data.name
        }else{ 
            delete data.productName
        }
        return postData(url,data).then( res => {
            if(res && res.recordList && res.recordList.length > 0) {
                for (let i = 0; i < res.recordList.length; i++) {
                    const type = res.recordList[i]
                    if(type.name.includes('(培训学院)')){
                        type.name = type.name.replace('(培训学院)','')
                        continue
                    }
                    if(type.name.includes('（培训学院）')){
                        type.name = type.name.replace('（培训学院）','')
                        continue
                    }
                    if(type.name.includes('培训学院')){
                        type.name = type.name.replace('培训学院','')
                        continue
                    }
                }
            }
            if(callback){
                callback(res)
            }
            return res
        }).catch( err => {
            console.log(err)
            return false
        })
    },
    getOtherConfig: async(data, callback) => { //1.21选课程-查看其他设置
        return postData(`${process.env.Text[4]}/study/client/home/shopDecoration/getOtherConfig`, data).then( res => {
            callback(res)
            return res
        }).catch( err => {
            console.log(err)
            return false
        })
    },
    getCourseDetail:(data, callback) => {  //1.21选课程-获取课程列表
        data.clientType = 2
        return postData(`${process.env.Text[5]}/opensaas/enrolltenant/website/product/info/${data.id}`,{rentId:data.rentId, clientType: data.clientType}).then( res => {
            if(res && res.name && res.name.length > 0) {
                if(res.name.includes('(培训学院)')){
                    res.name = res.name.replace('(培训学院)','')
                }
                if(res.name.includes('（培训学院）')){
                    res.name = res.name.replace('（培训学院）','')
                }
                if(res.name.includes('培训学院')){
                    res.name = res.name.replace('培训学院','')
                }
            }
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getAuditionListForTeachPlan:(data, callback) => {  //课程详情-根据教学计划获取试看列表
        return postData(`${process.env.Text[4]}/study/applet/home/product/audition/${data.teachPlanId}`,{}).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getCourseDetailList:(data, callback) => {  //选课程-获取课程-教学计划课程
        return postData(`${process.env.Text[4]}/study/applet/home/product/course/${data.teachPlanId}`,{}).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    // 报考咨询
    getValidCode:(data, callback) => {  //获取验证码
        return postData(`${process.env.Text[5]}/opensaas/operationadmin/official/getValidCode`,data).then( res => {
            callback(res)
            message.success('发送短信成功');    
        }).catch( err => {
            console.log(err)
        })
    },
    addConsultRecord:(data, callback) => {  //报考咨询信息记录插入
        return postData(`${process.env.Text[5]}/opensaas/operationadmin/official/addConsultRecord`,data).then( res => {
            callback(res)
        }).catch( err => {
            callback(false)
            console.log(err)
        })
    },
    getTeacherFilterParams:(data, callback) => {  //T8查询录播年份教师筛选条件（用于产品详情页，不需要token）
        return postData(`${process.env.Text[4]}/study/external/forFree/course/filter/param`,data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
            return false
        })
    },        
    getContracts:(data, callback)=>{ //获取签署协议
        return postData(`${process.env.Text[0]}/admin/commonaccount/contracts`,data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
            return false
        })
    },
    // 职教邦1.4新增接口更多教师页面
    getMoreTeacher:(data,callback) =>{
        return postData(`${process.env.Text[4]}/study/client/home/shopDecoration/getMoreTeacher`,data).then(res =>{
            callback(res)
        }).catch(err =>{
            return false
        })
    },
    getFloatComponent:async (data, callback) => {  //获取悬浮组件数据
        return postData(`${process.env.Text[4]}/study/client/home/shopDecoration/getFloatComponent`,data).then( res => {
            if(callback){
                callback(res)
            }
            return res
        }).catch( err => {
            console.log(err)
            return false
        })
    },
    //免费预约/立即报名
    freeAuditionReserve:(data, callback)=>{
        data.clientType = 4
        return postData(`${process.env.Text[4]}/study/applet/home/reserve`,data).then((res) => {
            callback(res)
        }).catch((err)=>{
            callback(false)
        })
    },
    buriedPointSave:async (data, callback) => {  //学员端埋点接口
        return postData(`${process.env.Text[4]}/study/applet/home/buriedPoint/save`,data).then( res => {
            if(callback){
                callback(res)
            }
            return res
        }).catch( err => {
            console.log(err)
            return false
        })
    }, 
}