import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import './index.scss';
import './theme.scss';
import Page from './Page';
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux'
import reducer from './reducer'
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import tool from './utils/commonFuc'
import Session from './utils/Session'
// import { createLogger } from 'redux-logger'

const middleware = [thunk];
// if (process.env.NODE_ENV !== 'production') {
//     middleware.push(createLogger());
// }
const store = createStore(reducer, applyMiddleware(...middleware));


// 挂载全局方法和对象
React.Component.prototype.$tool = tool;
React.Component.prototype.$Session = Session;
/* eslint-disable */
Object.prototype.equals = function(object) {
    if(!object){
        return false
    }
    
    let aProps = Object.getOwnPropertyNames(this);
    let bProps = Object.getOwnPropertyNames(object);
    if (aProps.length !== bProps.length) {
        return false;
    }
    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];
        if (this[propName] !== object[propName]) {
            return false;
        }
    }
    return true;
}
Object.defineProperty(Object.prototype, "equals", {enumerable: false});

Array.prototype.equals = function (array) {
    if (!array)
        return false;
    
    if (this.length != array.length)
        return false;

    for (var i = 0, l = this.length; i < l; i++) {
        if (this[i] instanceof Array && array[i] instanceof Array) {
            if (!this[i].equals(array[i]))
                return false;       
        }           
        else if (this[i] != array[i]) { 
            return false;   
        }           
    }       
    return true;
}
Object.defineProperty(Array.prototype, "equals", {enumerable: false});

//拦截，是手机打开就跳转到手机页面
if(navigator.platform){
    let system ={  
        win : false,  
        mac : false,  
        xll : false 
    };
    system.win = navigator.platform.indexOf("Win") == 0;  
    system.mac = navigator.platform.indexOf("Mac") == 0;  
    system.x11 = (navigator.platform == "X11") || (navigator.platform.indexOf("Linux") == 0);  

    // if(system.win||system.mac||system.xll){
    //     console.log("您电脑的系统是"+navigator.platform)
    // }else{
        const getQueryString = (name)=>{//解析url中的字段
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
            let index = window.location.hash.indexOf('?')
            var r = window.location.hash.substr(index+1).match(reg);
            if (r != null) {
            return unescape(r[2]);
            }
            return null;
        }
        let rentId = getQueryString('rentId')
        let url = '/home'
        if(rentId && rentId != 'null'){//带机构ID
            url = `/homePage?rentId=${rentId}`
        }else{
            let pcUrl = window.location.hostname
            let arr = [
                'localhost',
                'aline.mshengedu.com',
                'study.mshiedu.com',
                'study.mshengedu.com',
                'study.myunedu.com',
                'grey.mshiedu.com',
                'grey.mshengedu.com',
            ]
            let flag = true//是否独立域名
            for(let v of arr){
                if(pcUrl.indexOf(v)>-1){
                    flag = false
                    break
                }
            }
            if(flag){
                url = `/homePage`
            }
        }
        // 开放给第三方的页面打断
        let href = window.location.href
        for(let v of Session.openUrl){
            if(href.includes(v)){
                url = window.location.hash.substring(1)
                break
            }
        }
        let origin = window.location.origin
        let pathname = window.location.pathname
        if(system.win||system.mac||system.xll){
            /* console.log("您电脑的系统是"+navigator.platform,"参数：", pathname)
            if(pathname == '/'){ //产品说独立域名，且是第一次访问，没有其他路径时要默认跳转到首页，不要跳到登录页
                console.log(" window.location", window.location);
                window.location.href = `${origin}/studyPC/#/homePage`
            } */
        }else{
            window.location.href = `${origin}${pathname==''?'':'/studymobile/'}#${url}`
        }
        
    // }
}
const test = (arr1,arr2) =>{
    let startIndex1 = 0;
    let startIndex2 = 0;
    let endIndex1 = arr1.length-1;
    let endIndex2 = arr2.length-1;
    let arr = [];
    while(startIndex1<=endIndex1&&startIndex2<=endIndex2){
        if(arr1[startIndex1] > arr2[startIndex2]){
            arr.push(arr2[startIndex2]);
            startIndex2++;
        } else if(arr1[startIndex1] < arr2[startIndex2]){
            arr.push(arr1[startIndex1]);
            startIndex1++;
        }else{
            arr.push(arr2[startIndex2]);
            arr.push(arr1[startIndex1]);
            startIndex2++;
            startIndex1++;
        }
    }
    if(startIndex1 < endIndex1){
        arr = arr.concat(arr1.slice(startIndex1));
    }
    if(startIndex2 < endIndex2){
        arr = arr.concat(arr2.slice(startIndex2));
    }
    return arr 
}
async function async1() {
    console.log('async1 start');  //  2
    await async2();
    console.log('async1 end');  //  6
}
async function async2() {
    console.log('async2');   //   3
}
console.log('script start');  //  1
setTimeout(function() {
    console.log('setTimeout'); //8
}, 0)
async1();
new Promise(function(resolve) {
    console.log('promise1'); //   4
    resolve();
}).then(function() {
    console.log('promise2');  //7
});
console.log('script end'); //5

ReactDOM.render(
<Provider store={store}>
    <ConfigProvider locale={zh_CN}>
        <Page />
    </ConfigProvider>
</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

/* try {
    if(window.location.href.indexOf('?rentId=') > -1 || (sessionStorage.getItem('rentId') && sessionStorage.getItem('rentId') != 'null')){
        let favicon = document.createElement("link");
        favicon.rel = "icon";
        favicon.id = "favicon_icon";
        favicon.href = 'https://file.mshiedu.com/POKO-icon-none.png';
        document.head.appendChild(favicon);
    }else {
        let favicon = document.createElement("link");
        favicon.rel = "icon";
        favicon.id = "favicon_icon";
        favicon.href = 'https://file.mshiedu.com/POKO-favicon.ico';
        document.head.appendChild(favicon);
    }
} catch(e) {} */
