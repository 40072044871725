import React from 'react';
import { Route ,Redirect, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import routesConfig from './config'
import AllComponents from '../views'
import { isLogin } from '../utils/Session'
import tool from '../utils/commonFuc'
const { Content } = Layout;
class Routes extends React.Component{
    render(){
        return (
            <Switch>
                {
                    Object.keys(routesConfig).map(key => 
                        routesConfig[key].map(r => {
                            const route = r => {
                                const Component = AllComponents[r.component];
                                // if (r.key === '/home'){
                                    return (
                                        <Route
                                            exact
                                            key={r.route || r.key}
                                            path={r.route || r.key}
                                            render={props => isLogin() ? (
                                                <Component {...props} />
                                            ) : (<Redirect to={tool.appendRentId('/phoneLogin/index')} />)
                                            }
                                        />
                                    )
                                // }else{
                                //     return (
                                //         <Content style={{width:'1252px',margin:'0 auto'}}>
                                //             <Route
                                //                 exact
                                //                 key={r.route || r.key}
                                //                 path={r.route || r.key}
                                //                 // component={Component}
                                //                 render={props => isLogin() ? (
                                //                     <Component {...props} />
                                //                 ) : (<Redirect to={'/login'} />)
                                //                 }
                                //             />
                                //         </Content>
                                //     )
                                // }
                                
                            }
                            return r.component ? route(r) : r.subs.map(r => route(r));
                        })
                    )
                }
                <Route render={() => <Redirect to="/404" />} />
            </Switch>
        )
    }
}

export default Routes 