import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'

import './index.scss'
const TransitPage = (props)=>{
    const [state,setState] = useState({
        
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
        
    },[])
    const toLink = ()=>{
        if(props.orgInfo.pcUrl){
          window.location.href = props.orgInfo.pcUrl
        }
    }

    return <div className="transit-page-wrap">
        <div className="transit-page-logo-wrap" style={{backgroundImage:'url('+props.orgInfo.logo+')'}}></div>
        <div className="transit-page-img"></div>
        <div className="transit-page-tips">
            您好，原地址已更新，请点击<a className="router-link" onClick={toLink}>{props.orgInfo.pcUrl}</a>新地址登录噢～
            <br />
            温馨提示：记得收藏保存最新的登录地址噢～
        </div>
    </div>
}
export default withRouter(TransitPage)