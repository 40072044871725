import React from 'react'
import { Form, Input } from 'antd'
import { withRouter } from 'react-router-dom'

import loginApi from '../../api/login'
import tool from '../../utils/commonFuc'
class perfectInfo extends React.Component{
    state = {
        isLogin:false,
        isHjx: false, // 是否是慧简学机构
    }
    componentDidMount(){
        this.setState({
            isHjx: tool.isHJXTenant()
        })
    }

    skipThisStep = () => {
        this.props.history.push({pathname:'/home'})
    }
    chagePass = (e) => {
        if(e.target.value && e.target.value !== ''){
            this.setState({
                isLogin:true  
            })
        }else{
            this.setState({
                isLogin:false  
            })
        }
    }
    loginIn = () => {
        if(this.state.isLogin){
            this.props.form.validateFields((err,values) => {
                if(!err){
                    values.cardType = 1
                    loginApi.editPerson(values,res => {
                        if(res){
                            this.props.history.push({pathname:'/home'})
                        }
                    })
                }
            })
        }
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const { isHjx} = this.state;
        let colorStyle = ''
        if (isHjx) {
            colorStyle = '#396BF0'
        } else {
            colorStyle = '#FFBD30'
        }
        // 下一步按钮，有三种状态： 未输入内容时为灰色；机构型且有内容为蓝色，否则为橙色
        const study_btn_styles = {
            color: this.state.isLogin && !isHjx ? "#332A2A" : "#FFFFFF",
            backgroundColor: this.state.isLogin ? colorStyle : "#D9D9D9",
            borderRadius:'24px'
        }
        return (
            <div className="phone_login_content">
                <div className="right_content_box">
                    <p>
                        绑定信息
                    </p>
                    <div className="welcome_speech">   
                        上课学习请先验证身份信息
                    </div>
                    {/* form表单处 */}
                    <div className="form_box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item label="">
                                {getFieldDecorator('name', {
                                   rules: [
                                    {required: true,message: '请输入姓名',whitespace:true},
                                ],
                                })(<Input placeholder="请输入姓名" size="large" allowClear />)}
                            </Form.Item>
                            <Form.Item label="" style={{marginBottom:'14px'}}>
                                
                            </Form.Item>
                            <Form.Item label="">
                                {getFieldDecorator('cardNo', {
                                    rules: [
                                        {required: true,message: '请输入身份证号',whitespace:true},
                                    ],
                                })(<Input placeholder="请输入身份证号" size="large" allowClear onPressEnter={this.goLogin} maxLength={18} onChange={this.chagePass} />)}
                            </Form.Item>
                        </Form>
                        <div className="password_login_text" onClick={this.skipThisStep} style={{cursor:'pointer'}}>
                            跳过
                        </div>
                        <div className="footer_btn">
                            <button style={study_btn_styles} onClick={this.loginIn}>登录</button>
                        </div>
                    </div>
                </div>
                <div className="right_blank_box">
                    
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(perfectInfo))