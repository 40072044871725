import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon, message, Layout } from 'antd'

import MyHeader from '../../components/Header/index'
import MyFooter from '../../components/Footer/index'
import CourseDetail from '../../views/Index/courseDetail'//课程详情

import tool from '../../utils/commonFuc'

const { Header, Content } = Layout;
const IndexContainers = (propsObj)=>{
    const [state,setState] = useState({
        
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
        
    },[])
    
    return <div className="">
        <Layout style={{minHeight:'100vh'}}>
            {/* 头部 */}
            <Header style={{backgroundColor:'#ffffff'}}>
                <MyHeader pathname={propsObj.location.pathname} {...propsObj.history} showContactBtn={true}></MyHeader>
            </Header>
            <CourseDetail></CourseDetail>
            <MyFooter></MyFooter>
        </Layout>
    </div>
}
const mapStateToProps  = (state) =>({
    
})

const mapDispatchToProps = (dispatch) => ({
    
})
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(IndexContainers))