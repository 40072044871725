import React, { useState, useEffect } from 'react'
import ShowMath from '../../components/ShowMath'//渲染公式
// import './index.scss'
import Session from '../../utils/Session'

const QuestionsModule = (props) => {
    const [state, setState] = useState({
        
    })
    //更新state
    const updateState = (newState) => {
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({}, data, newState))
    }
    const typeArr = Session.questionTypeList
    //选择答案
    const selectAnswers = (e,val,i)=>{
        if(props.question.questionType!==2 && props.question.questionType!==3){//非多选清空其他已选
            for(let j in props.question.answerItems){
                if(j == i){
                    props.question.answerItems[i].checked = !val//改变值
                }else{
                    props.question.answerItems[j].checked = false
                }
            }
        }else{
            props.question.answerItems[i].checked = !val//改变值
        }
        props.returnAnswers(e,val,i)
    }
    // 展示答案
    const setShowAnswer = (v)=>{
        props.question.showAnswer = !props.question.showAnswer
        props.setShowAnswer()
    }
    //设置样式类名
    const setClassName = (v)=>{
        if(props.question.questionType===1 || props.question.questionType===4){
            return `option-circle ${v.checked?'option-checked':''}`
        }else{
            return `option-square ${v.checked?'option-checked':''}`
        }
    }
    // 设置题干
    const setTitle = ()=>{
        return `${props.sequence}.<span class="questions-type">
            (${typeArr[props.question.questionType-1].name})
        </span>${props.question.topic}<span class="questions-point">(${props.question.fullScore}分)</span>`
    }
    return (
        <div className="questions-module">
            {/* 题目 */}
            <div className="questions-title">
                {/* 题干 */}
                {props.question.containFormula?
                    <ShowMath content={setTitle()}></ShowMath>:
                    <div dangerouslySetInnerHTML={{__html:setTitle()}}></div>
                }
                <div className='questions-btn' onClick={setShowAnswer}>查看答案</div>
            </div>
            {/* 选项 */}
            <div className="questions-answers">
                {props.question.questionType<5?
                    // 选择题类相关
                    props.question.answerItems.map((v,i) => (
                        <div className="questions-option" key={i} onClick={(e)=>{selectAnswers(e,v.checked,i)}}>
                            <div className={setClassName(v)}>{v.answerKey}</div>
                            <div className="option-description">
                                {v.containFormula?
                                    <ShowMath content={v.answerText}></ShowMath>:
                                    <div dangerouslySetInnerHTML={{__html:v.answerText}}></div>
                                }
                            </div>
                        </div>
                    )):''}
            </div>
            {/* 答案 */}
            {props.question.showAnswer && <div className="answers-analysis">
                {props.question.answer && <div className="right-answers">
                    <span class='right-answers-title'>正确答案：</span>
                    {props.question.containFormula?
                        <ShowMath content={`${props.question.answer}`}></ShowMath>:
                        <div dangerouslySetInnerHTML={{__html:`${props.question.answer}`}}></div>
                    }
                </div>}
                
                {props.question.analysis && <div className="answers-text">
                    <span className="analysis-title">题目解析：</span>
                    {props.question.containFormula?
                        <ShowMath content={`${props.question.analysis}`}></ShowMath>:
                        <div dangerouslySetInnerHTML={{__html:`${props.question.analysis}`}}></div>
                    }
                </div>}
            </div>}
    </div>
    )
}
export default QuestionsModule