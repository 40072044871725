import React from 'react'
// import MathJax from 'react-mathjax'
import MathJax from 'ms-react-mathjax'
// const MathJax = require('react-mathjax')

export default class ShowMath extends React.Component{
    interceptMath = (content) => {  //暂时抛弃这种渲染方式
        console.log(content.split("{$$}"))
        let index = content.indexOf("{$$}");
        let nextIndex = content.indexOf("{$$}",index+4);
        if(index >= 0){
            // console.log(content.substring(0,index));
            // console.log(content.substring(index+4,nextIndex));
            // index = content.indexOf("{$$}",nextIndex+1);
            // nextIndex = content.indexOf("{$$}",index+1);
            let newContent = content.substring(nextIndex+4);
            if(newContent.length > 0){
                this.interceptMath(newContent)
            }
            return (
                <div style={{display:'inline-block'}}>
                    <span dangerouslySetInnerHTML={{__html:content.substring(0,index)}}></span>
                    <MathJax.Provider>
                        <MathJax.Node inline formula={content.substring(index+4,nextIndex)} />
                    </MathJax.Provider>
                </div>
            )
        }else{
            return (
                <span dangerouslySetInnerHTML={{__html:content}}></span>
            )
        }
    }
    showMath = () => {
        const { content } = this.props;
        // content = ' ' + content + ' ';
        if(content){
            if(content.indexOf("{$$}") === 0){
                if (content.split("{$$}") && !content.split("{$$}")[0]) {
                    return content.split("{$$}") && content.split("{$$}").map( (item,index) => this.newInterceptMath(item,index))
                } else {
                    return content.split("{$$}") && content.split("{$$}").map( (item,index) => this.newInterceptMath(item,index+1))
                }
            }else{
                return content.split("{$$}") && content.split("{$$}").map( (item,index) => this.newInterceptMath(item,index))
            }
        }else{
            return '略'
        }
    }
    newInterceptMath = (item,index) => {
        if((index+1) %2 === 0){
            return(
                <MathJax.Provider key={index}>
                    <MathJax.Node inline formula={item} />
                </MathJax.Provider>
            )
        }else{
            return (
                <span key={index} dangerouslySetInnerHTML={{__html:item}}></span>
            )
        }
    }
    render(){
        const { content } = this.props;
        return (
            <div style={{display:'inline-block'}}>
                {this.showMath()}
                {/* {content.split("{$$}") && content.split("{$$}").map( (item,index) => this.newInterceptMath(item,index))} */}
                {/* {this.newInterceptMath(content)} */}
            </div>
        )
    }
}