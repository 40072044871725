import React from 'react'

import './bjyPlayBack.scss'
import api from '../../api/class'
import imgUrl from '../../assets/img/logo.png'
import { Select, Button, Icon } from 'antd'
import moment from 'moment'

const { Option } = Select
/* eslint-disable */
class BjyVideo extends React.Component {
    state = {
        startTime: undefined,
        lastStudyTime: null,
        sectionId: 1,
        showVideo: false,
        playbackRate: 1,//真实播放倍速
        playbackRateModal: false,//倍速模态框
        playbackRateArr: [
            { value: 2, label: '2.0x' },
            { value: 1.5, label: '1.5x' },
            { value: 1.25, label: '1.25x' },
            { value: 1, label: '1.0x' },
            { value: 0.75, label: '0.75x' },
            { value: 0.5, label: '0.5x' },
        ],
        isPlaying: false,//播放状态
        isShowVolume: false,//展示按量按钮
        isFullScreen: false,//白板全屏
        timer: null,//定时器
        roundTime: 10000//定时器秒数
    }
    player = null;
    componentWillMount() {
        this.setState({
            lastStudyTime: this.props.sectionInfo.lastStudyTime || 0
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.sectionInfo.id != this.props.sectionInfo.id) {
            //回放→回放不会进入componentWillUnmount
            /* if((new Date()).getTime() - this.state.startTime > 10000 && this.state.startTime > 0){
                this.saveStudyRecord()
            } */
            location.reload()//BJY不支持单页面应用
            /* this.setState({
                showVideo:false
            })
            this.playBackInit(nextProps.sectionInfo)//回放初始化 */
        }
    }
    componentDidMount() {
        this.playBackInit(this.props.sectionInfo)//回放初始化
        // 检测是否全屏
        window.onresize = () => {
            let isFull = this.getfullscreenelement() ? true : false
            this.setState({
                isFullScreen: isFull
            })
        }
    }
    componentWillUnmount() {
        console.log(999, '-----')
        if (this.state.timer) {
            clearInterval(this.state.timer)
            this.setState({ timer: null })
        }
        /* if (this.player && this.state.startTime !== 0) {
            if((new Date()).getTime() - this.state.startTime > 10000){
                this.saveStudyRecord((new Date()).getTime() - this.state.startTime,false)
            }
            this.player = null;
        } */
    }
    saveStudyRecord = () => {
        let teachPlanId = localStorage.getItem('saveTeachPlanId')
        let courseId = localStorage.getItem('saveCourseId')
        if (this.props.sectionInfo.freeLive) {//免费试看
            return
        }
        const { startTime } = this.state;
        let nowTime = (new Date()).getTime();
        let time = nowTime - startTime
        if (time >= 10000 && startTime > 0) {
            let obj = {
                sectionType: '4',
                sectionId: this.state.sectionId,
                productId: localStorage.getItem('productId'),
                channelId: this.state.channelId,
                studyTime: Math.round(time / 1000),
                lastStudyTime: parseInt(this.player.currentTime),
                subjectId: localStorage.getItem('subjectId'),
                beginStudyDate: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
                endStudyDate: moment(nowTime).format('YYYY-MM-DD HH:mm:ss'),
            }
            let addStudyRecordData = JSON.parse(localStorage.getItem('addStudyRecordData'))
            obj = Object.assign({}, obj, addStudyRecordData)
            api.addStudyRecord(obj, (res) => {
                if (res) {
                    // 更新课程目录
                    this.props.getChapterSection({ moduleId: localStorage.getItem('moduleId') ,teachPlanId,courseId})
                    this.setState({
                        startTime: nowTime,
                    })
                }
            })
        }
    }
    //回放初始化
    playBackInit(data) {
        // loading
        let loading = BJY.playback.Loading.create({
            element: $('#bjy-playback-wrapper'),
            logoUrl: imgUrl,
            onComplete: () => {
                loading.destroy()
                this.setState({
                    showVideo: true
                })
            }
        })
        this.getBaijiayunPlayBackToken(data)//获取百家云回放token
    }
    //获取百家云回放token
    getBaijiayunPlayBackToken(data) {
        api.getBaijiayunPlayBackToken({ channelId: data.channelId + '', sessionId: data.sessionId }, (res) => {
            this.setState({
                channelId: data.channelId,
                sessionId: data.sessionId,
                sectionId: data.id,
                token: res.playBackToken
            })
            this.creatBjyPlayback()//回放初始化
        })
    }
    // 回放初始化
    creatBjyPlayback() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
        BJY.playback.init({
            privateDomainPrefix: 'b53939712', //给回放绑定自定义域名（选填，仅针对拥有专属域名的用户, 其中XXX为专属域名，从百家云账号中心获取）
            token: this.state.token, // 回放token （必填）
            useEncrypt: true, // 回放视频是否加密（选填）
            class: {
                id: this.state.channelId, // 教室 id （必填）
                sessionId: this.state.sessionId // 章节 id （选填）
            },
            user: {
                number: userInfo.id || parseInt(Math.random() * 10000000),
                name: userInfo.name || `游客${parseInt(Math.random() * 10000)}`,
                avatar: userInfo.avatarUrl || '',
                type: 0
            }
        }).then((res) => {
            this.creatBjyPlayer(res)//播放器
            this.creatBjyWhiteboard()//白板
            this.creatBjyMessageList()//聊天
            this.creaBjyVolumeSlidert()//音量
        })

    }
    // 控制条
    creatBjySlider(res) {
        let duration = res.videos[res.defaultDefinition][0].duration
        let currentTime = this.props.sectionInfo.lastStudyTime
        let video = this.player
        this.playerSlider = BJY.PlayerSlider.create({
            element: $('.bjy-playback-playslider'),
            currentTime: currentTime, // 视频播放时间
            duration: duration, // 视频总时长
            canUseHotKey: true, // 是否支持快捷键
            onBeforeDrag: () => {
                // <-- 建议在此暂停视频播放 -->
                video.pause()
                this.setState({ isPlaying: false })
            },
            slideAnimation: (style) => {
                // <-- 可在此做滑动效果 -->
            },
            onAfterDrag: (time) => {
                // <-- 建议在此播放视频时间为 time -->
                video.currentTime = time
                video.play()
                this.setState({ isPlaying: true })
            },
            onBeforeSeek: (time) => {
                // <-- 建议在此暂停视频播放 -->
                video.pause()
                this.setState({ isPlaying: false })
            },
            onAfterSeek: (time) => {
                // <-- 建议在此播放视频时间为 time -->
                video.currentTime = time
                video.play()
                this.setState({ isPlaying: true })
            },
            onBeforeBackward: () => {
                // <-- 快退之前，建议在此暂停视频播放 -->
                video.pause()
                this.setState({ isPlaying: false })
            },
            onAfterBackward: (time) => {
                // time 是快退之后选中的时间 单位：s
                // <-- 快退之后，建议在此播放视频时间为 time -->
                video.currentTime = time
                video.play()
                this.setState({ isPlaying: true })
            },
            onBeforeForward: () => {
                // <-- 快进之前，建议在此暂停视频播放 -->
                video.pause()
                this.setState({ isPlaying: false })
            },
            onAfterForward: (time) => {
                // time 是快退之后选中的时间 单位：s
                // <-- 快进之后，建议在此播放视频时间为 time -->
                video.currentTime = time
                video.play()
                this.setState({ isPlaying: true })
            },
            onSpacePress: () => {
                // <-- 建议在此判断视频的播放状态 当前为暂停就开始播放 当前为播放就暂停 -->
                video.paused ? video.play() : video.pause()
                this.setState({ isPlaying: !video.paused })
            }
        })

        // 必须监听视频的timeupdate事件，来告诉回放去根据视频时间更新数据
        video.addEventListener('timeupdate', () => {
            this.playerSlider && this.playerSlider.set('currentTime', video.currentTime)
        })
        // this.videoPlay(this)
    }
    // 音量
    creaBjyVolumeSlidert() {
        BJY.VolumeSlider.create({
            element: $('.bjy-playback-volume'),
            min: 0,
            max: 100,
            value: 30, // 默认音量
            onChange: (value) => {
                // <!-- 音量改变的时候触发，请在此处修改您的视频音量 -->
                this.player.volume = value / 100
            }
        })
        // hover效果
        $('.bjy-playback-playslider-wrapper').hover(() => {
            console.log('按兵不动')
        }, () => {
            this.setState({
                isShowVolume: false
            })
        })
    }
    // 播放按钮
    videoPlay(obj) {
        let video = obj.player
        this.setState({
            isPlaying: !this.state.isPlaying
        })
        video.paused ? video.play() : video.pause()
    }
    // 音量
    showVolume() {
        this.setState({
            isShowVolume: !this.state.isShowVolume
        })
    }
    //播放器
    creatBjyPlayer(res) {
        let video = this.player = document.getElementById('bjy-player-teacher');
        let eventEmitter = BJY.eventEmitter;
        let store = BJY.store;

        let onPlaying = () => {
            console.log('onPlaying', new Date().getTime());
            BJY.playback.play();
            if (!!this.state.timer) {
                clearInterval(this.state.timer)
                this.setState({ timer: null })
            }
            this.setState({
                startTime: (new Date()).getTime(),
                timer: setInterval(() => {
                    console.log(`-----跑定时器啦-----${this.state.roundTime / 1000}s/次(偶尔${this.state.roundTime / 1000 * 2}s/次)`)
                    this.saveStudyRecord()
                }, this.state.roundTime)
            })
        };
        let onPause = () => {
            console.log('onpause', new Date().getTime() - this.state.startTime);
            BJY.playback.pause();
            //页面销毁自动触发
            // this.saveStudyRecord()
            if (this.state.timer) {
                clearInterval(this.state.timer)
                this.setState({ timer: null })
            }
        }
        let onSeeked = () => {
            console.log('onseeked', new Date().getTime() - this.state.startTime);
            BJY.playback.seek(video.currentTime);
            BJY.playback.play();
            console.log(video.currentTime);
        }
        let onEnd = () => {
            console.log('onend', new Date().getTime() - this.state.startTime);
            this.setState({
                isPlaying: false
            })
            // 播放结束后自动暂停，不在这里写提交学习记录
        }

        let onTimeUpdate = function () {
            BJY.playback.timeupdate(video.currentTime);
        };
        BJY.playback.start().then(() => {
            console.log(res)
            // <!-- 这里可以加入您需要的功能 -->
            let teacherVideos = res.videos;
            let videoWatermark = res.videoWatermark;
            // videoWatermark.url 在百家云后台设置的水印链接
            // videoWatermark.pos 在百家云后台设置的水印位置
            if (res.videos) {
                // 首先播默认分辨率
                let url = res.videos[res.defaultDefinition][0].url;
                url = url.replace('https:', '').replace('http:', '');
                if (url.indexOf('.ev1') > -1 && flvjs) {
                    // 使用flvjs 播放加密格式视频
                    flvPlayer = flvjs.createPlayer({
                        type: 'ev1',
                        url: url,
                        cors: true
                    }, {
                        stashInitialSize: 1024,
                    });
                    flvPlayer.attachMediaElement(video);
                    flvPlayer.load();
                } else {
                    video.src = url;
                }

                let initialized = false;

                /* element.find('#player-screen').on('click', function () {
                    video.play();
                }); */

                video.oncanplay = function () {

                    // 已启动过则不再执行
                    if (initialized) {
                        return;
                    }
                    // element.find('#player-screen').hide();
                    initialized = true;
                };

                video.addEventListener('play', function () { onPlaying(); });
                video.addEventListener('pause', function () { onPause(); });
                video.addEventListener('seeked', function () { onSeeked(); });
                video.addEventListener('ended', function () { onEnd(); });

                // 必须监听视频的timeupdate事件，来告诉回放去根据视频时间更新数据
                video.addEventListener('timeupdate', function () {
                    onTimeUpdate();
                });
                // 学习时间
                console.log(`currentTime=${this.state.lastStudyTime}`)
                video.currentTime = this.state.lastStudyTime
            }
            this.creatBjySlider(res)//控制条
        });
    }
    //白板
    creatBjyWhiteboard() {
        let eventEmitter = BJY.eventEmitter;
        let store = BJY.store;
        let isFullScreen = false;

        BJY.Whiteboard.create({
            element: $('#bjy-playback-whiteboard-wrapper'),
            fit: 1
        })
        eventEmitter
            .on(
                eventEmitter.WHITEBOARD_LAYOUT_CHANGE,
                function (e, data) {
                    // 重新设置白板的高度并让其居中
                    // layout();
                }
            )
            .on(
                eventEmitter.DOC_IMAGE_LOAD_START,
                function () {
                    console.log('翻页开始');
                }
            )
            .on(
                eventEmitter.DOC_IMAGE_LOAD_END,
                function () {
                    console.log('翻页结束');
                }
            )
        // var toggleBtn = $('#fullscreen-btn');

        /* function toggleFullScreen () {
            var container = $('#bjy-withboard-container');
            if (isFullScreen) {
                toggleBtn.text('全屏');
                container.removeClass('whiteboard-fullscreen');

                setTimeout(function () {
                    eventEmitter.trigger(
                        eventEmitter.DOC_FIT_CHANGE_TRIGGER,
                        {
                            value: 2,
                        }
                    );
                }, 50);
            }
            else {
                toggleBtn.text('退出全屏');
                container.addClass('whiteboard-fullscreen');

                setTimeout(function () {
                    eventEmitter.trigger(
                        eventEmitter.DOC_FIT_CHANGE_TRIGGER,
                        {
                            value: 1,
                        }
                    );
                }, 50);
            }
            isFullScreen = !isFullScreen;
        }

        // 白板全屏按钮事件
        toggleBtn.on('click', function () {
            toggleFullScreen();
        }); */
    }
    // 全屏
    getreqfullscreen() {
        var root = document.documentElement
        return root.requestFullscreen || root.webkitRequestFullscreen || root.mozRequestFullScreen || root.msRequestFullscreen
    }
    // 退出全屏
    getexitfullscreen() {
        return document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen
    }
    // 检查全屏
    getfullscreenelement() {
        return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
    }
    // 白板全屏
    fullScreen(obj) {
        // var container = $('#bjy-playback-whiteboard-wrapper')

        /* let eventEmitter = BJY.eventEmitter
        if (obj.state.isFullScreen) {
            setTimeout(function () {
                eventEmitter.trigger(
                    eventEmitter.DOC_FIT_CHANGE_TRIGGER,
                    {
                        value: 2,
                    }
                )
            }, 50)
        }
        else {
            setTimeout(function () {
                eventEmitter.trigger(
                    eventEmitter.DOC_FIT_CHANGE_TRIGGER,
                    {
                        value: 1,
                    }
                )
            }, 50)
        } */
        let liveing = document.getElementsByClassName('liveing_poliv')[0]
        if (obj.getfullscreenelement()) {//已经全屏
            let exitFullscreen = obj.getexitfullscreen()
            exitFullscreen.call(document)
        } else {//非全屏
            let fullscreene = obj.getreqfullscreen()
            fullscreene.call(liveing)
        }
    }
    //聊天
    creatBjyMessageList() {
        // userRoleMap 这里配置不同身份信息
        let userRoleMap = {};

        userRoleMap[BJY.config.ROLE_TYPE_TEACHER] = {
            en: 'teacher', // 英文话术
            cn: '老师' // 中文话术
        };
        userRoleMap[BJY.config.ROLE_TYPE_ASSISTANT] = {
            en: 'assistant',
            cn: '助教'
        };
        userRoleMap[BJY.config.ROLE_TYPE_STUDENT] = {
            en: 'student',
            cn: '学生'
        };
        userRoleMap[BJY.config.ROLE_TYPE_GUEST] = {
            en: 'guest',
            cn: '游客'
        };
        BJY.MessageList.create({
            element: $('#bjy-playback-message-wrapper'),
            renderEmoji: BJY.data.emotion.render,
            renderUser: function (user, width, height) {
                return {
                    id: user.id,
                    name: user.name,
                    number: user.number,
                    // 头像需压缩
                    avatar: BJY.compressImage({
                        url: user.avatar,
                        width: width,
                        height: height
                    }),
                    avatar2x: BJY.compressImage({
                        url: user.avatar,
                        width: width * 2,
                        height: height * 2
                    }),
                    // 用户角色 可选值: 'teacher', 'assistant', 'student', 'guest'
                    role: userRoleMap[user.type].en,
                    // 配置角色的术语 例: '老师', '助教', '学生', '游客'
                    localRole: userRoleMap[user.type].cn
                }
            },
            renderImage: function (url) {
                url = BJY.removeProtocol(url);
                if (BJY.isAlicloudImage(url)) {
                    return '<img ondragstart="return false" class="img-content" data-src="'
                        + (url + '@500h_1e_1l')
                        + '" src="'
                        + (url + '@100w_1e_1l')
                        + '" srcset="'
                        + (url + '@200w_1e_1l')
                        + ' 2x">';
                }
                return '<img ondragstart="return false" class="img-content" data-src="' + url + '" src="' + url + '">';
            },
            renderLink: function (original) {
                var url = original;
                return '<a class="bjy-link" target="_target" href="' + BJY.removeProtocol(url) + '">'
                    + original
                    + '</a>';
            }
        });
    }
    // 改变播放倍速
    changePlaybackRate = (val) => {
        let arr = JSON.parse(JSON.stringify(this.state.playbackRateArr))
        this.setState({
            playbackRate: val,
            playbackRateArr: arr
        })
        console.log(val)
        this.player.playbackRate = val
    }
    render() {
        return (
            <div style={{ width: '100%', height: '100%', position: this.state.isFullScreen ? 'fixed' : 'relative' }} id="live-player" className="play-back-container">
                {/* 白板 */}
                <div id="bjy-withboard-container" className={this.state.isFullScreen ? 'whiteboard-fullscreen' : ''}>
                    <div id="bjy-playback-whiteboard-wrapper"></div>
                    {/* 控制条 */}
                    <div className="bjy-playback-playslider-wrapper" style={{ visibility: this.state.showVideo ? 'visible' : 'hidden' }}>
                        {/* 播放 */}
                        <Icon type={this.state.isPlaying ? "pause-circle" : "play-circle"} style={{ fontSize: '26px', color: '#fff' }} onClick={() => { this.videoPlay(this) }} />
                        <div className="bjy-playback-playslider"></div>
                        {/* 音量 */}
                        <div style={{ position: 'relative' }}>
                            <Icon type="sound" style={{ fontSize: '26px', color: '#fff' }} onClick={() => { this.showVolume() }} />
                            <div className="bjy-playback-volume-bar" style={{ display: this.state.isShowVolume ? 'block' : 'none' }}>
                                <div className="bjy-playback-volume"></div>
                            </div>
                        </div>
                        {/* 倍速 */}
                        <div id="playback-rate-select" className="playback-rate-select">
                            <Select value={this.state.playbackRate} onChange={this.changePlaybackRate} getPopupContainer={() => document.getElementById('playback-rate-select')} dropdownClassName="playback-rate-select-menu">
                                {this.state.playbackRateArr.map((v, i) => {
                                    return <Option key={i} value={v.value} className="playback-rate-select-option">{v.label}</Option>
                                })}
                            </Select>
                        </div>
                        {/* 全屏 */}
                        <Icon type={this.state.isFullScreen ? "fullscreen-exit" : "fullscreen"} style={{ fontSize: '26px', color: '#fff' }} onClick={() => { this.fullScreen(this) }} />
                    </div>
                </div>
                <div className="play-back-right">
                    {/* 播放器 */}
                    <div style={{ position: 'relative'/* ,zIndex:this.state.isFullScreen?-1:0 */ }}>
                        {/* <video id="bjy-player-teacher"
                            x5-playsinline="true"
                            x-webkit-airplay="allow"
                            webkit-playsinline="true"
                            playsInline={true}
                            controls>
                        </video> */}
                        <video id="bjy-player-teacher"></video>
                        <div id="bjy-playback-wrapper"></div>
                    </div>
                    {/* 聊天 */}
                    <div id="bjy-playback-message-wrapper-container" style={{ display: this.state.showVideo ? 'block' : 'none'/* ,zIndex:this.state.isFullScreen?-1:0 */ }}>
                        <div id="bjy-playback-message-wrapper"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BjyVideo            