// 存放组件模板数据  因为悬浮模块有较多地方有用到

import  * as type from '../constants/ActionTypes'

const ininState = {
    floatList:[]
}
const  componentModuleReducer = (state=ininState,actions) =>{
    switch(actions.type){
        case type.GET_FLOAT_MODULE_LIST:
            console.log("悬浮组件模板数据redux",actions);
            return Object.assign({},{...state},{
                floatList:actions.data
            })
           
        default:
            return {...state}
    }
}
export const floatComponentList = state => {
    console.log("state",state);
    return state.floatList
}
export default componentModuleReducer

