import React from 'react'

import './iframe.scss'
import api from '../../api/class'
import moment from 'moment'
/* eslint-disable */
class LivePlayer extends React.Component{
    state = {
        channelId:'',
        sectionInfo:{},
        startTime:undefined,
        liveStatus:'unknow',//直播状态
        timer:null,//定时器
    }
    componentWillMount(){
        this.setState({
            channelId:this.props.channelId + '',
            sectionInfo:this.props.sectionInfo,
        })
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.sectionInfo.id != this.props.sectionInfo.id){
            this.saveStudyRecord()//保存上一个视频的学习时间
            clearInterval(this.state.timer)//清除计时器
            this.getStatusByChannelId()
            this.setState({
                channelId:nextProps.channelId + '',
                sectionInfo:nextProps.sectionInfo,
                startTime:undefined,
                liveStatus:'unknow',
                timer:setInterval(()=>{
                    this.getStatusByChannelId()
                },300000)
                // },3000)
            })
        }
    }
    componentDidMount(){
        this.getStatusByChannelId()
        this.setState({
            timer:setInterval(()=>{
                this.getStatusByChannelId()
            },300000)
            // },3000)
        })
    }
    componentWillUnmount(){
        this.saveStudyRecord()
        clearInterval(this.state.timer)//清除计时器
    }
    //保存学习时间
    saveStudyRecord = (data) => {
        const { sectionInfo, channelId, startTime } = this.state;
        console.log('startTime------',startTime)
        let nowTime = (new Date()).getTime();
        let time = nowTime - startTime
        if(time>=10000 && startTime>0){
            /* let obj = {};
            obj.sectionType = '1';
            obj.sectionId = sectionInfo.id;
            obj.productId = localStorage.getItem('productId');
            obj.channelId = channelId;
            obj.studyTime = new Date(time).getSeconds() */
            let obj = {
                sectionType:'1',
                sectionId:sectionInfo.id,
                productId:localStorage.getItem('productId'),
                channelId:channelId,
                studyTime:new Date(time).getSeconds(),
                subjectId:sessionStorage.getItem('subjectId'),
                beginStudyDate:moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
                endStudyDate:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            }
            let addStudyRecordData = JSON.parse(sessionStorage.getItem('addStudyRecordData'))
            obj = Object.assign({},obj,addStudyRecordData)
            api.addStudyRecord(obj,(res) => {
                if(res){
                    this.setState({ 
                        startTime:new Date().getTime(),
                    })
                }
            });
        }
    }
    //获取直播状态
    getStatusByChannelId = () =>{
        api.getStatusByChannelId({type:1,channelId:this.state.channelId},res => {
            if(this.state.liveStatus==='unknow' && res.status==='live'){//开始直播了
                this.setState({
                    liveStatus:'live',
                    startTime:new Date().getTime()
                })
            }
            if(this.state.startTime>0 && this.state.liveStatus!=='live'){//直播结束
                this.saveStudyRecord()
                clearInterval(this.state.timer)//清除计时器
            }
            console.log(res)
        });
    }
    render(){
        return (
            <div style={{width:'1192px',height:'1000px'}}>
                <iframe style={{width:'100%',height:'100%'}} 
                scrolling="no" frameBorder="0" id="my_liveing_player" src={"https://live.polyv.cn/watch/"+this.state.channelId}></iframe>
            </div>
        )
    }
}

export default LivePlayer            