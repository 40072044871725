import axios from 'axios'
import { message } from 'antd';
import Session from '../utils/Session'
import tool from '../utils/commonFuc'

message.config({
    top: 150,
    duration: 2,    
})

// 需要在header传appType接口
let appTypeUrlArr = [
    '/sys/login/webPassLogin',
    '/sys/login/webValidLogin',
    '/sys/login/logout',
]
function postData(url, data) {
    if(url && !url.includes('http')){
        url = process.env.Text[0] + url;
    }    
    // console.log(url)
    /* if(url.indexOf('/app/appDataStatistics/saveAppDataStatistics') !=-1){   //App启动或登陆动作数据收集,添加请求头
        axios.defaults.headers['appType'] = 3;
    }else{
        delete axios.defaults.headers['appType']
    } */
    for(let v of appTypeUrlArr){
        if(url.includes(v)){
            axios.defaults.headers['appType'] =  4
            break
        }else{
            delete axios.defaults.headers['appType']
        }
    }
    axios.defaults.headers['authorization'] = sessionStorage.getItem('token') || sessionStorage.getItem("token") || '';;
    axios.defaults.headers['version'] = Session.version;
    return axios({
        method: 'post',
        url: url,
        data: data,
      }).then((res) => {
        //   debugger
        let data = res.data;        
        if (data.status === 0 || data.status === '0' || data.status === 10 || data.code === 200 || !data.status){
            // saas系统中，登陆成功返回租户列表数据，将数据缓存
            if((res.config.url.includes('webValidLogin') || res.config.url.includes('webPassLogin') || res.config.url.includes('/sys/login/account/change')) && data.data.tenantList != undefined){
                let _tenantId = "";
                data.data.tenantList.forEach(e=>{                    
                    e.value = e.id;
                    e.label = e.name;         
                    if(e.showState){
                        _tenantId = e.id;
                    }           
                })
                let tenantInfoJson = localStorage.getItem('tenantInfo')
                let rentId = sessionStorage.getItem('rentId')
                let pcUrl = sessionStorage.getItem('pcUrl')//用户配置的独立域名
                console.log("保存pcUrl------------",pcUrl);
                sessionStorage.clear()
                localStorage.clear()
                if(tenantInfoJson && tenantInfoJson.length > 0) {
                    localStorage.setItem('tenantInfo',tenantInfoJson)
                }
                sessionStorage.setItem('tenantList',JSON.stringify(data.data.tenantList));
                sessionStorage.setItem('showTenant',data.data.showTenant);
                sessionStorage.setItem('tenantId',_tenantId);
                if(rentId && rentId != 'null'){
                    sessionStorage.setItem('rentId',rentId);
                }
                if(pcUrl && pcUrl != 'null'){
                    sessionStorage.setItem('pcUrl',pcUrl)
                }
            }else if(res.config.url.includes('webValidLogin') || res.config.url.includes('webPassLogin') || res.config.url.includes('/sys/login/account/change')){
                let tenantInfoJson = localStorage.getItem('tenantInfo')
                let rentId = sessionStorage.getItem('rentId')
                sessionStorage.clear()
                if(rentId && rentId != 'null'){
                    sessionStorage.setItem('rentId',rentId);
                }
                localStorage.clear()
                if(tenantInfoJson && tenantInfoJson.length > 0) {
                    localStorage.setItem('tenantInfo',tenantInfoJson)
                }
                sessionStorage.removeItem("tenantList");
                sessionStorage.removeItem("showTenant");
                sessionStorage.removeItem("tenantId");
            }
            if(res.config.url.includes('addComment') || res.config.url.includes('videoParameters')){
                return data
            }
            return data.data;                
        }else if(data.status === 1 && data.code == 'SEN001'){
            //token失效，跳转到登录页面，清除用户信息
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('userInfo')
            localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
            // 开放给第三方的页面打断
            let href = window.location.href
            for(let v of Session.openUrl){
                if(href.includes(v)){
                    document.getElementById('live-player').remove()//删除播放器
                    message.error(data.msg);
                    return false
                }
            }
            let index = window.location.href.indexOf('#');
            let url = window.location.href.slice(0,index);
            console.log(url)
            let search = ''
            let rentId = sessionStorage.getItem('rentId')
            if(rentId && rentId != 'null' && rentId!=1 && rentId!=8){
                url = '/homePage'
                let pcUrl = sessionStorage.getItem('pcUrl')
                console.log("pcUrl------4---------------",pcUrl);
                if(pcUrl){
                    // sessionStorage.removeItem('pcUrl')
                }else{
                    search = `?rentId=${rentId}`
                }
                window.location.replace(`${window.location.protocol}//${window.location.host}${window.location.pathname}#${url}${search}`)
                window.location.reload()
            }else{
                window.location.href=url + tool.appendRentId('#/phoneLogin/index');
            }
        // }else if(data.code == "REQ007"){
        //   message.error(data.msg); 
        //   return false;
        // }else if(data.code == "SEN004"){
        //   message.error(data.msg); 
        //   return false;
        // }else{
        //   throw data
        }                
        message.error(data.msg); 
        return false;
      }).catch((err) => {
        message.error(err.msg)
      })      
}

//用来导出表格
function exportData (url, data) {
    if(url && !url.includes('http')){
        url = Session.defaultUrl + url
    }    
    axios.defaults.headers['authorization'] = sessionStorage.getItem('token');
    axios.defaults.headers['version'] = Session.version;
    return axios({
        method: 'post',
        url: url,
        data: data,
        responseType: 'blob'
      }).then((res) => {        
        return(res.data);
      }).catch((err) => {
        message.error(err.msg)
      })  
}

export {
    postData,
    exportData
};
