import React from 'react'
import { Tabs, Icon, Select, Breadcrumb, Slider } from 'antd'
import tool from "../../utils/commonFuc"
import myClassApi from "../../api/class"
import moment from 'moment'

import './index.scss'
import '../VedioStudy/bjyPlayBack.scss'
import Hls from 'hls.js'
const { Option } = Select
const { TabPane } = Tabs;
class NewVedioStudy extends React.Component{
    state = {
        show:false,
        nowTime:null,
        sectionId:null,
        classInfo:{ //录播课程详情
            name:'',
            sectionList:[], //录播课程章节详情
        },
        charterId:null,//章节id
        viedoUrl:'',
        videoTime:0,//视频时长
        selectedClass:{},
        
        playbackRate:1,//真实播放倍速
        playbackRateArr:[
            {value:2,label:'2.0x'},
            {value:1.5,label:'1.5x'},
            {value:1.25,label:'1.25x'},
            {value:1,label:'1.0x'},
            {value:0.75,label:'0.75x'},
            {value:0.5,label:'0.5x'},
        ],
        isPlaying:false,//播放状态
        isShowVolume:false,//展示按量按钮
        isFullScreen:false,//白板全屏
        videoCurrentTime:0,//视频当前进度
        videoVolume:30,//音量
        timer:null,//定时器
        roundTime:10000,//定时器秒数
    }
    // 视频操作
    setVideoSrc(videoSrc){
        let video = document.getElementById('video-content')
        if(videoSrc.includes('.m3u8')){
            if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = videoSrc
            } else if (Hls.isSupported()) {
                var hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(video);
            }
        }else{
            video.src = videoSrc
        }
        return video
    }
    componentDidMount(){
        console.log("视频播放页",this.props);
        let obj = JSON.parse(sessionStorage.getItem('selectedClass'))
        this.props.externalProductType === 2 ? this.getNewClassInfo(this.props.section.classId) : this.getNewCourseModular(this.props.productId)
        
        let selectedClass = JSON.parse(sessionStorage.getItem('selectedClass'))
        this.setState({selectedClass:selectedClass,section:this.props.section})

        // 视频操作
        let video = this.setVideoSrc(this.props.section.videoInfo.hdUrl)
        video.addEventListener('play', ()=> {
            console.log('-----play-----',new Date().getTime())
            if(this.state.timer){
                clearInterval(this.state.timer)
                this.setState({timer:null})
            }
            this.setState({
                startTime:(new Date()).getTime(),
                timer:setInterval(()=>{
                    console.log(`-----跑定时器啦-----${this.state.roundTime/1000}s/次(偶尔${this.state.roundTime/1000*2}s/次)`)
                    this.saveStudyRecord()
                },this.state.roundTime),
                isPlaying:true
            })
        })
        video.addEventListener('pause', ()=> {
            console.log('-----pause-----',new Date().getTime() - this.state.startTime)
            if(this.state.timer){
                clearInterval(this.state.timer)
                this.setState({timer:null})
            }
            this.setState({
                isPlaying:false
            })
            // this.saveStudyRecord()
        })
        video.addEventListener('seeked', ()=> {
            console.log('-----seeked-----',new Date().getTime() - this.state.startTime)
        })
        video.addEventListener('ended', ()=> {
            console.log('-----ended-----999999999999999999999999999999999999',new Date().getTime() - this.state.startTime)
            this.saveStudyRecord(true)      // 最后一次调接口传一个参数，不需要判断10s以上
            //学员看完新起点课程后，进度一直显示99%,需要在播放完的时候调接口
            if(this.state.timer){ 
                clearInterval(this.state.timer)
                this.setState({timer:null})
            }
            this.setState({
                isPlaying:false
            })
        })
        video.addEventListener('timeupdate', ()=> {
            this.setState({
                videoCurrentTime:parseInt(video.currentTime)
            })
        })
        if(this.props.section.lastStudyTime){
            video.currentTime = this.props.section.lastStudyTime
        }
    }
    saveStudyRecord = (belData = false) => {
        // 免费试看
        if(this.props.isFree){
            return
        }
        let video = document.getElementById('video-content')
        const { startTime, selectedClass, charterId, viedoUrl } = this.state;
        let nowTime = (new Date()).getTime();
        let time = nowTime - startTime
        if((belData && startTime>0) || (time>=10000 && startTime>0)){
            let obj = {
                studyType:2,//1-直播；2-录播；3-回放
                org:1,//外部机构，1-新起点
                studyId:charterId,//学习的章节id
                studyTitle:this.props.section.charterName,//学习的章节标题
                productId:selectedClass.productId,//产品ID
                productName:selectedClass.productName,//产品名称
                studyTime:belData ? 10 : Math.round(time/1000),//学习时长：秒
                lastStudyTime:parseInt(video.currentTime),//最后一次学习时间
                ordId:selectedClass.ordId,//订单id
                projectTypeId:selectedClass.projectTypeId,//项目类型ID	
                projectTypeName:selectedClass.projectTypeName,//项目类型名称
                beginStudyDate:moment(startTime).format('YYYY-MM-DD HH:mm:ss'),//开始学习时间
                endStudyDate:moment(nowTime).format('YYYY-MM-DD HH:mm:ss'),//结束学习时间
                courseUrl:viedoUrl.split('?')[0],//录播课程视频URL: studyType=2必传
                videoTime:video.duration,//视频总时长
                appType:3,//应用类型：1.安卓，2.IOS，3.PC，4.H5
                mediaId:this.props.section.videoInfo.mediaId || undefined,
            }
            myClassApi.addXqdStudyRecord(obj,(res) => {
                if(res){
                    // 更新课程目录
                    this.props.externalProductType === 2 ? this.getNewClassInfo(this.props.section.classId) : this.getNewCourseModular(this.props.productId)
                    let livingSection = JSON.parse(sessionStorage.getItem('livingSection'))//课程视频
                    if(livingSection){
                        livingSection = Object.assign({},livingSection,{
                            lastStudyTime:obj.lastStudyTime,
                            studyTime:obj.studyTime
                        })
                        sessionStorage.setItem('livingSection',JSON.stringify(livingSection))
                    }
                    this.setState({ 
                        startTime:nowTime,
                    })
                }
            })
        }
    }
    componentWillUnmount(){
        // this.saveStudyRecord()
    }
    getNewCourseModular(id){
        myClassApi[this.props.isFree?'getNewCourseModularForFree':'getNewCourseModular']({id:id, year: this.props.section.year, externalTeacherId: this.props.section.teacherId},res=>{
            console.log("获取外部课程 -录播、直播详情、套餐数据",res);
            this.setState({
                classInfo:res.classInfo || {},
                viedoUrl:this.props.section.videoInfo.hdUrl || '',
                videoTime:parseInt(this.props.section.videoInfo.videoTime),
                charterId:this.props.section.charterId // 章节ID
            })
        })
    }
    getNewClassInfo(id){
        myClassApi[this.props.isFree?'getNewClassInfoForFree':'getNewClassInfo']({classId	:id,productId:this.props.productId, year: this.props.section.year, externalTeacherId: this.props.section.teacherId},res=>{
            console.log("获取套餐录播详情数据",res);
            let obj = {
                classInfo:res|| {},
                charterId:this.props.section.charterId || '' ,//章节ID
            }
            // 
            if(this.props.section.videoInfo.hdUrl != this.state.viedoUrl){
                obj.viedoUrl = this.props.section.videoInfo.hdUrl || ''
                obj.videoTime = parseInt(this.props.section.videoInfo.videoTime) || 0
            }
            this.setState(obj)
        })
    }
    handleSection = async (item) =>{  //点击节跳转
        console.log("点击视频",item);
        if(!item.videoInfo || this.props.section.videoInfo.hdUrl==item.videoInfo.hdUrl){
            return
        }
        let videoToken = await myClassApi.getQueryPlayClassToken({chapterId:item.charterId,mediaId:item.videoInfo.mediaId})
        item.videoInfo.hdUrl += `?token=${videoToken}`
        this.props.updateSection(Object.assign({},this.state.section,item))
        this.setState({
            viedoUrl:item.videoInfo.hdUrl || '',
            videoTime:parseInt(item.videoInfo.videoTime) || 0,
            charterId: item.charterId
        },()=>{
            let video = this.setVideoSrc(item.videoInfo.hdUrl)
            if(item.lastStudyTime){
                video.currentTime = item.lastStudyTime
            }
            if(this.state.timer){
                clearInterval(this.state.timer)
                this.setState({timer:null})
            }
            this.setState({
                startTime:(new Date()).getTime(),
                timer:setInterval(()=>{
                    console.log(`-----跑定时器啦-----${this.state.roundTime/1000}s/次(偶尔${this.state.roundTime/1000*2}s/次)`)
                    this.saveStudyRecord()
                },this.state.roundTime),
                isPlaying:true
            })
            // this.saveStudyRecord()
        })
    }
    createSecion = (item) => {   //创建节
        return (
            <li key={item.charterId} className="section_li" onClick={() => this.handleSection(item)}>
                <p style={{ marginBottom: '8px' }} className={this.state.charterId === item.charterId ? 'action_section' : ''}>
                    {item.charterName}
                </p>
                {
                    item.videoInfo && 
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{
                            fontSize: '12px', border:  '1px solid #D6D8DB', color:  '#B7BABE',
                            display: 'inline-block', padding: '2px 6px',flexShrink:0
                        }}>录播</span>
                        <span  className="class-list-player-line"></span>
                        <span style={{ color: '#4D81CF' }}>时长:{tool.getTimeDom(0,0,item.videoInfo && item.videoInfo.videoTime,0)}</span>
                    </div>
                }
                
            </li>
        )
    } 
    createChapter = (item, chapterInx) => {// 创建章
        return (
            <li key={item.charterId}>
                <div style={{ fontWeight: 600 }} className={`section_li chapter_title ${this.state.charterId === item.charterId ? 'action_section' : ''}`} onClick={() => this.handleSection(item)}>
                    {item.charterName}
                    {
                        item.videoInfo && 
                        <div style={{ display: 'flex', alignItems: 'center',cursor:'pointer',marginTop:'10px' }}>
                            <span style={{
                                fontSize: '12px', border:  '1px solid #D6D8DB', color:  '#B7BABE',
                                display: 'inline-block', padding: '2px 6px',flexShrink:0
                            }}>录播</span>
                            <span  className="class-list-player-line"></span>
                            <span style={{ color: '#4D81CF' }}>时长:{tool.getTimeDom(0,0,item.videoInfo && item.videoInfo.videoTime,0)}</span>
                            
                        </div>
                    }
                </div>
                
                <ul>
                    {item.child && item.child.map((section, sectionInx) => this.createSecion(section, sectionInx, chapterInx))}
                </ul>
            </li>
        )
    }
    handleIcon = () => {
        const show = this.state.show;
        this.setState({
            show: !show
        })
    }
    // 进度条
    chageSlider = (value)=>{
        let video = document.getElementById('video-content')
        this.setState({
            videoCurrentTime:value
        })
        video.currentTime = value
    }
    // 改变播放倍速
    changePlaybackRate = (val)=>{
        this.setState({
            playbackRate:val,
        })
        let video = document.getElementById('video-content')
        video.playbackRate = val
    }
    // 音量
    showVolume(e){
        e.stopPropagation(); //阻止冒泡
        this.setState({
            isShowVolume:!this.state.isShowVolume
        })
    }
    chageVolume = (value)=>{
        let video = document.getElementById('video-content')
        this.setState({
            videoVolume:value
        })
        video.volume = value/100
    }
    // 播放按钮
    videoPlay(obj){
        let video = document.getElementById('video-content')
        this.setState({
            isPlaying:!this.state.isPlaying
        })
        video.paused ? video.play() : video.pause()
    }
    // 全屏
    getreqfullscreen () {
        var root = document.documentElement
        return root.requestFullscreen || root.webkitRequestFullscreen || root.mozRequestFullScreen || root.msRequestFullscreen
    }
    // 退出全屏
    getexitfullscreen(){
        return document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen
    }
    // 检查全屏
    getfullscreenelement(){
        return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
    }
    // 白板全屏
    fullScreen(obj){
        this.setState({
            isFullScreen:!this.state.isFullScreen
        })
        return
        let liveing = document.getElementById('video-content')
        if(obj.getfullscreenelement()){//已经全屏
            let exitFullscreen = obj.getexitfullscreen()
            exitFullscreen.call(document)
        }else{//非全屏
            let fullscreene = obj.getreqfullscreen()
            fullscreene.call(liveing)
        }
    }
    onMouseLeave = ()=>{
        this.setState({
            isShowVolume:false
        })
    }
    render(){
        const { classInfo,viedoUrl, videoTime, videoCurrentTime, videoVolume } = this.state
        const {section} = this.props
        return (
            <div>
                <div style={{padding:'20px 0px',background:'#f0f2f5'}}>
                    <Breadcrumb separator=">">
                        {!this.props.isFree && <Breadcrumb.Item href="#/home">学习主页</Breadcrumb.Item>}
                        {this.props.isFree && <Breadcrumb.Item href="#/course">选课程</Breadcrumb.Item>}
                        {this.props.isFree && <Breadcrumb.Item href={`#/courseDetail?id=${this.props.productId}`}>课程详情</Breadcrumb.Item>}
                        {/* <Breadcrumb.Item href="#/home/modular">{localStorage.getItem('teachPlanName')}</Breadcrumb.Item> */}
                        <Breadcrumb.Item>{classInfo.courseName}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                 <div className='vedio_content_box record_content_box'>
                    <div className={`left_box play-back-container ${this.state.isFullScreen?'fullscreen-video-wrap':''}`} onMouseLeave={this.onMouseLeave} onClick={this.onMouseLeave}>
                        <video id="video-content" style={{height:'100%',width:'100%'}} autoPlay>
                        </video>
                        {/* 控制条 */}
                        <div className="bjy-playback-playslider-wrapper">
                            {/* 播放 */}
                            <Icon type={this.state.isPlaying?"pause-circle":"play-circle"} style={{fontSize: '26px',color:'#fff'}} onClick={()=>{this.videoPlay(this)}} />
                            <div className="bjy-playback-playslider">
                                <div>
                                    {Math.floor(this.state.videoCurrentTime/60)}:{this.state.videoCurrentTime%60>9?this.state.videoCurrentTime%60:`0${this.state.videoCurrentTime%60}`}/
                                    {Math.floor(this.state.videoTime/60)}:{this.state.videoTime%60>9?this.state.videoTime%60:`0${this.state.videoTime%60}`}</div>
                                {/* 进度条 */}
                                <Slider tipFormatter={null} max={videoTime} value={videoCurrentTime} onChange={this.chageSlider} />
                            </div>
                            {/* 音量 */}
                            <div style={{position:'relative'}}>
                                <Icon type="sound" style={{fontSize: '26px',color:'#fff'}} onClick={(e)=>{this.showVolume(e)}}/>
                                <div className="bjy-playback-volume-bar" style={{display:this.state.isShowVolume?'block':'none'}} onClick={(e)=>{e.stopPropagation()}}>
                                    <div className="bjy-playback-volume">
                                        <span>{videoVolume}</span>
                                        <Slider tipFormatter={null} value={videoVolume} onChange={this.chageVolume} vertical />
                                    </div>
                                </div>
                            </div>
                            {/* 倍速 */}
                            <div id="playback-rate-select" className="playback-rate-select">
                                <Select value={this.state.playbackRate} onChange={this.changePlaybackRate} getPopupContainer={()=>document.getElementById('playback-rate-select')} dropdownClassName="playback-rate-select-menu" style={{width:80}}>
                                    {this.state.playbackRateArr.map((v,i)=>{
                                        return <Option key={i} value={v.value} className="playback-rate-select-option">{v.label}</Option>
                                    })}
                                </Select>
                            </div>
                            {/* 全屏 */}
                            <Icon type={this.state.isFullScreen?"fullscreen-exit":"fullscreen"} style={{fontSize: '26px',color:'#fff'}} onClick={()=>{this.fullScreen(this)}} />
                        </div>
                    </div>
                    {/* is_show_icon */}
                    <div className="right_box" >
                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="课程目录" key="1">
                            <p style={{ color: '#101010', fontSize: '16px', borderBottom: '1px solid #D9D7D7', margin: '0px', paddingBottom: '17px', paddingLeft: '15px' }}>
                                {classInfo.courseName}
                                {/* <span style={{ display: 'inline-block', color: '#5F6266', fontSize: '14px', marginLeft: '10px' }}>
                                    (共4节)
                                </span> */}
                            </p>
                            <div>
                                <ul className='chapter_list new_chapter_list'>
                                    {classInfo.sectionList instanceof Array && classInfo.sectionList.map((item, chapterInx) => this.createChapter(item, chapterInx))}
                                </ul>
                            </div>
                        </TabPane>
                    </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewVedioStudy