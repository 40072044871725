export const REQUEST_DATA = 'REQUEST_DATA'
export const RECEIVE_DATA = 'RECEIVE_DATA'
export const GET_MEUN_DATA = 'GET_MEUN_DATA'

export const CHANGE_HOME_DATE = 'CHANGE_HOME_DATE';//首页时间改变
export const CHANGE_HOME_CONTRAST_DATE = 'CHANGE_HOME_CONTRAST_DATE'  //首页对比基期时间改变
export const GET_HOME_DATA_OVERVIEW_DATA = 'GET_HOME_DATA_OVERVIEW_DATA';//获取首页数据概览数据
export const CHANGE_DATA_OVERVIEW_LAODING = 'CHANGE_DATA_OVERVIEW_LAODING'  // 数据概览组件loading状态
export const GET_HOME_SELL_HERO_DATA = 'GET_HOME_SELL_HERO_DATA';//获取首页销售英雄榜数据
export const GET_HOME_RECEPT_HERO_DATA = 'GET_HOME_RECEPT_HERO_DATA';//获取首页接待英雄榜数据
export const CHANGE_HERO_LAODING = 'CHANGE_HERO_LAODING';//英雄榜组件loading状态
export const GET_HOME_TREND_ANALYSIS_DATA = 'GET_HOME_TREND_ANALYSIS_DATA'  //获取首页趋势分析数据
export const CHANGE_TREND_ANALYSIS_LAODING = 'CHANGE_TREND_ANALYSIS_LAODING'  //趋势分析组件loading状态
export const GET_ORG_DATA = 'GET_ORG_DATA'  //当期军团维度
export const CHANGE_ORG_LAODING = 'CHANGE_ORG_LAODING'   //军团维度组件loading状态
export const GET_PEOJECT_DATA = 'GET_PEOJECT_DATA'  //获取项目维度数据
export const CHANGE_PROJECT_LAODING = 'CHANGE_PROJECT_LAODING'  // 项目维度组件loading状态
export const GET_CHANNEL_DATA = 'GET_CHANNEL_DATA' //获取渠道维度数据
export const CHANGE_CHANNEL_LAODING = 'CHANGE_CHANNEL_LAODING'   //渠道维度组件loading状态
export const GET_RAGION_DATA = 'GET_RAGION_DATA'  //获取地区维度数据
export const CHANGE_REGATION_LAODING = 'CHANGE_REGATION_LAODING'  //地区维度组件loading状态
export const CHANGE_ORG = 'CHANGE_ORG'  //改变组织颗粒
export const CHECK_SELECT_DATA = 'CHECK_SELECT_DATA'  //保存下拉框请求参数
export const GET_CONSTITUTE_DATA = 'GET_CONSTITUTE_DATA'  //获取‘构成’详情

export const GET_MY_CLASS_DATA = 'GET_MY_CLASS_DATA'   //获取我的班级数据
export const GET_TERM_DATA = 'GET_TERM_DATA'  //获取学期数据
export const GET_MODULAR_DATA = 'GET_MODULAR_DATA'   //获取模块数据
export const GET_CHAPTER_SECTION_DATA = 'GET_CHAPTER_SECTION_DATA'  //获取章节数据
export const CHANGE_EDIT_PERSON_MODAL = 'CHANGE_EDIT_PERSON_MODAL'  //改变完善信息弹出层

export const GET_EXAM_HOME = 'GET_EXAM_HOME'  //获取题库首页
export const GET_MYCLASS_SUBJECT = 'GET_MYCLASS_SUBJECT'  //获取我的科目班级
export const GET_MODULE_TEST = 'GET_MODULE_TEST'  //获取题库模块练习
export const GET_TEST_BY_TAG = 'GET_TEST_BY_TAG'  //获取专项练习，历年真题等数据

export const GET_INTELLIGENT = 'GET_INTELLIGENT'  //获取PC首页智能练习

export const GET_ERR_INTELLIGENT = 'GET_ERR_INTELLIGENT' //我的错题--智能练习


export const GET_FLOAT_MODULE_LIST = 'GET_FLOAT_MODULE_LIST'  //悬浮模块组件数据
