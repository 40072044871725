import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import tool from '../../utils/commonFuc'
import './index.scss'
import api from '../../api/newExam'
import QuestionsModule from './QuestionsModule'

const NewExamTest = (props) => {
    const [state,setState] = useState({
        currentIndex:2,//当前跑马灯索引
        questionsArr: [],//题目数组
    })
    //更新state
    const updateState = (newState)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    useEffect(()=>{
    },[])

    useEffect(()=>{
        if(props.examInfo.groups){
            let arr = []
            let errQuestions = []
            // 设置题目
            const setQuestionsArr = (array,openSubjectiveAnswer)=>{
                array.forEach((d) => {
                    // setQuestionsChecked(d)
                    if(d.childs){
                        d.sequence = d.childs[0].sequence-0.5//添加序号
                        d.childs.forEach((v)=>{
                            // setQuestionsChecked(v)
                            // 主观题和客观题总数计算
                            if(v.questionType>=5 && v.questionType<=12 && !openSubjectiveAnswer){// 主观题
                                // subjectiveQuestionsCount++
                            }else{// 客观题
                                // objectiveQuestionsCount++//需要回答的
                            } 
                        })
                        // totalQuestionsCount += d.childs.length
                    }else{
                        // 主观题和客观题总数计算
                        if(d.questionType>=5 && d.questionType<=12 && !openSubjectiveAnswer){// 主观题
                            // subjectiveQuestionsCount++
                        }else{// 客观题
                            if(d.questionType===13){//填空题
                                // objectiveQuestionsCount+=d.answerItems.length
                            }else{
                                // objectiveQuestionsCount++//需要回答的
                            }
                        } 
                        // totalQuestionsCount++
                    }
                    arr.push(d)
                })
                /* if(errQuestions.length>0){
                    alert('题目出现问题', `试题id=${errQuestions.toString()} 没有选项或填空！请联系相关人员进行修正。`, [
                        { text: '确认', onPress: () => console.log('确认') },
                    ])
                } */
            }
            props.examInfo.groups.forEach((v)=>{
                setQuestionsArr(v.questions)
            })
            updateState({
                questionsArr:arr
            })
        }
    },[props.examInfo])
    
    // 切换题目
    const changeCurrentIndex = (current)=>{
        updateState({currentIndex:current})
    }
    // 渲染题目
    const renderList = (v,i)=>{
        return v.questionType && <QuestionsModule question={v} returnAnswers={returnAnswers} sequence={i+1} key={i} setShowAnswer={setShowAnswer}></QuestionsModule>
    }
    const returnAnswers = ()=>{
        updateState({questionsArr:state.questionsArr})
    }
    // 查看答案
    const setShowAnswer = ()=>{
        updateState({questionsArr:state.questionsArr})
    }
    return <div className='new-exam-test-wrap'>
        {/* 索引 */}
        <div className='new-exam-test-title-wrap'>
            <span className='new-exam-test-title'>{props.examTitle}</span>
            {props.examInfo.questionCount && <span className='new-exam-test-question-count'>共{props.examInfo.questionCount}道题</span>}
        </div>
        {/* 试题 */}
        {<div className="new-exam-test-paper-wrap">
            {state.questionsArr.map((v,i)=>{
                return <div className='slider-slide' key={i}>{renderList(v,i)}</div>
            })}
        </div>}
    </div>
}
export default withRouter(NewExamTest)