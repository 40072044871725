import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Icon, message } from 'antd'

import './index.scss'
import ContactUsModal from '../contactUsModal/index'
const { Search } = Input
const Footer = (propsObj)=>{
    const [state,setState] = useState({
        showContactUsModal:false,//报考咨询模态框
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
    },[])

    // 设置模态框
    const setContactUsModalStatus = ()=>{
        updateState({showContactUsModal:!state.showContactUsModal})
    }

    return <div className="footer-wrap">
        <div className="footer-text">快速提升自我，挑战高薪，赶紧咨询我们吧！</div>
        <div className="footer-btn" onClick={setContactUsModalStatus}>{sessionStorage.getItem('rentId') == '1208' ? '免费注册' : '报考咨询'}</div>
        {/* 模态框 */}
        <ContactUsModal showContactUsModal={state.showContactUsModal} setContactUsModalStatus={setContactUsModalStatus}></ContactUsModal>
    </div>
}
export default withRouter(Footer)