import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router-dom'

import { Icon, message, Layout } from 'antd'

import MyHeader from '../../components/Header/index'
import MyFooter from '../../components/Footer/index'
import Index from '../../views/Index/homePage'//首页

import tool from '../../utils/commonFuc'
import Teacher from '../../views/Index/Teacher'

const { Header, Content } = Layout;
const TeacherContainers = (propsObj)=>{
    const [state,setState] = useState({
        
    })
    useEffect(()=>{
        document.title = '教师介绍'
    },[])
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }

    return <div className="">
        <Layout style={{minHeight:'100vh'}}>
            {/* 头部 */}
            <Header style={{backgroundColor:'#ffffff'}}>
                <MyHeader pathname={propsObj.location.pathname} {...propsObj.history} isTeacherIntro={true} showContactBtn={true}></MyHeader>
            </Header>
            <Teacher></Teacher>
            {/* <MyFooter></MyFooter> */}
        </Layout>
    </div>
}

export default withRouter(TeacherContainers)