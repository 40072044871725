import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
// swiper start
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// swiper end
import './index.scss'

SwiperCore.use([Navigation, Pagination, A11y, Autoplay])



const BannerSwiperMoudle = memo((props) => {
    const bannerClick = (v)=>{
        switch (v.type){
            case 1://产品
                // props.history.push({pathname:'/courseDetail',search:`id=${v.productId}`})
                let url = window.location.origin + window.location.pathname + `#/courseDetail?id=${v.productId}`
                v.productId && window.open(url) //有返回产品id才做跳转
                break
            case 2://跳转URL
                v.clickUrl && window.open(v.clickUrl)
                break
          
        }
    }
    return (
        <div className="swiper-module">
            <Swiper
                className="swiper-module-wrap"
                // spaceBetween={50}
                slidesPerView={1}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                navigation
                // 只有一张图时不允许自动切换
                loop={props.bannerArr.length > 1 ?true :false}
                autoplay={{ autoplay:props.bannerArr.length > 1 ?true :false,delay: 3000, disableOnInteraction: false }}
                // autoplay={props.bannerArr.length > 1 ?true :false}
                pagination={{ clickable: true }}
            // onSlideChange={() => console.log('slide change')}
            >
                {props.bannerArr.map((v, i) => {
                    return <SwiperSlide key={i}>
                        <img className="banner-img" src={v.pictureUrl} onClick={() => { bannerClick(v) }} />
                    </SwiperSlide>
                })}
            </Swiper>
        </div>
    );
});

export default withRouter(BannerSwiperMoudle);