import * as type from '../constants/ActionTypes';

const initialState = {
    testList: []
}

const yearTruth = (state=initialState,action) => {
    switch (action.type) {
        case type.GET_TEST_BY_TAG :
            return Object.assign({},{...state},{
                testList:action.data
            })
        default:
            return {...state}
    }
}

export const testList = state => {
    return state.testList
}

export const moduleTestData = state => {
    return state.moduleTest
}

export default yearTruth