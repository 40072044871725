import { message } from 'antd';
import { postData } from './index'
import Session from '../utils/Session'
import tenantApi from './tenant.js'


export default {
    login:(data, callback)=>{
        return postData(data.type==='pass' ? '/sys/login/webPassLogin' : '/sys/login/webValidLogin',data).then((res) => {    
            if(res){
                // message.success('登录成功');
                sessionStorage.setItem('userInfo',JSON.stringify(res));                
                sessionStorage.setItem('token',res.token);
                
                localStorage.setItem('userInfo',JSON.stringify(res));
                localStorage.setItem('token',res.token);
                let rentId
                if(res.tenantList && res.tenantList.length > 0){
                    rentId = res.tenantList[0].id
                } else {
                    rentId = res.tenantId
                }
                callback(res);
            }else{
                callback(false)
            }
        }).catch( (err) => {
            console.log(err)
            callback(false)
        })
    },
    // 注册
    loginRegister:(data, callback)=>{
        return postData('/sys/login/register',data).then((res) => {    
            if(res){
                sessionStorage.setItem('userInfo',JSON.stringify(res));                
                sessionStorage.setItem('token',res.token);
                
                localStorage.setItem('userInfo',JSON.stringify(res));
                localStorage.setItem('token',res.token);
                let rentId
                if(res.tenantList && res.tenantList.length > 0){
                    rentId = res.tenantList[0].id
                } else {
                    rentId = res.tenantId
                }
                callback(res);
            }else{
                callback(false)
            }
        }).catch( (err) => {
            console.log(err)
            callback(false)
        })
    },
    
    loginOut :  (data,callback) => {  //退出登录
        return postData('/sys/login/logout',data).then((res) => {    
            if(res){
                let lastLoginType = localStorage.getItem('lastLoginType')//最后一次登录的方式
                let rentId = sessionStorage.getItem('rentId')
                let pcUrl = sessionStorage.getItem('pcUrl')//用户配置的独立域名
                sessionStorage.clear()
                let tenantInfo = localStorage.getItem('tenantInfo')
                localStorage.clear()
                if(rentId && rentId != 'null'){
                    sessionStorage.setItem('rentId',rentId)
                }
                if(tenantInfo && tenantInfo.length > 0) {
                    localStorage.setItem('tenantInfo', tenantInfo)
                }
                if(pcUrl && pcUrl != 'null'){
                    sessionStorage.setItem('pcUrl',pcUrl)
                }
                localStorage.setItem('lastLoginType',lastLoginType)
                callback(res)
            }
        }).catch( (err) => {
            console.log(err)
        })
    },
    getMenuList:(data, callback)=>{        
        return postData(process.env.Text[1]+'/sys/menu/listAuth?module=pareto',data).then((res) => { 
            if(res && res.length > 0){
                callback(res);
            }else{
                callback(res);
                // message.error('该账号没有菜单权限');
            }
        })
    },
    sendCode:function (data,callback){   // 发送验证码[用在忘记密码]    
        return postData('/sys/validCode/get',data).then((res) => { 
            if(res === null){
                callback(true)
                message.success('发送成功');    
            }
        }).catch(function (err) {
            callback(false)
            message.error(err.msg)
        })
    },
    getValidCode:function (data,callback){   // 发送验证码【用在注册登录】    
        return postData('/sys/validCode/getCode',data).then((res) => { 
            if(res){
                callback(res)
                message.success('发送成功');    
            }
        }).catch(function (err) {
            callback(false)
            message.error(err.msg)
        })
    },
    register:function (data,callback){   // 注册        
        return postData('/sys/login/userRegister',data).then((res) => { 
            callback(res)
        }).catch(function (err) {
            callback(false)
            message.error(err.msg)
        })
    },    
    resetPass:function (data,callback){   // 重置密码        
        return postData('/sys/login/resetPass',data).then((res) => {
            if(res !== false){
                message.success(res)
            } 
            callback(res)
        }).catch(function (err) {
            callback(false)
            message.error(err.msg)
        })
    },   
    setPasswordAndRegister:function (data,callback){   // 初次登陆设置密码并注册        
        return postData('/sys/login/setPasswordAndRegister',data).then((res) => {
            if(res !== false){
                message.success(res)
            } 
            callback(res)
        }).catch(function (err) {
            callback(false)
            message.error(err.msg)
        })
    },   
    editPerson:function (data,callback){   // 完善身份信息        
        return postData('/web/usercenter/editPerson',data).then((res) => {
            if(res){
                callback(res)
            } 
        }).catch(function (err) {
            message.error(err.msg)
        })
    },
    getAgreement:function (data,callback){   //获取协议
        return postData('/admin/agreement/getAgreement',data).then((res) => {
            if(res){
                callback(res)
            } 
        }).catch(function (err) {
            message.error(err.msg)
        })
    },
    saveAppDataStatistics:function (data,callback){   //App启动或登陆动作数据收集
        return postData('/app/appDataStatistics/saveAppDataStatistics',data).then((res) => {
            if(res){
                callback(res)
            } 
        }).catch(function (err) {
            message.error(err.msg)
        })
    },
    setNewPassword:function (data,callback){   //设置密码
        return postData('/sys/login/setNewPasswordV2',data).then((res) => {
            if(res){
                callback(res)
            }else{
                message.error(res.msg)
                callback(false)
            }
        }).catch(function (err) {
            message.error(err.msg)
            callback(false)
        })
    },
    updatePassword:function (data,callback){   //修改密码
        return postData('/sys/login/modify/passwd',data).then((res) => {
            if(res){
                callback(res)
            }else{
                callback(false)
            }
        }).catch(function (err) {
            message.error(err.msg)
            callback(false)
        })
    },
    chcekCode:function (data,callback){   // 校验验证码
        return postData('/sys/login/validateZsCode',data).then((res) => {
            if(res){
                callback(res)
            }else{
                callback(false)
            }
        }).catch(function (err) {
            message.error(err.msg)
            callback(false)
        })
    },
    // 职教邦1.2
    studentAccount_qry:function (data,callback){  
        return postData('/sys/login/account/qry',data).then((res) => {
            if(res){
                callback(res)
            }else{
                callback(false)
            }
        }).catch(function (err) {
            callback(false)
        })
    },
    studentAccount_add:function (data,callback){  
        return postData('/sys/login/account/add',data).then((res) => {
            if(res){
                callback(res)
            }else{
                callback(false)
            }
        }).catch(function (err) {
            callback(false)
        })
    },
    studentAccount_unbind:function (data,callback){  
        return postData('/sys/login/account/unbind',data).then((res) => {
            if(res){
                callback(res)
            }else{
                callback(false)
            }
        }).catch(function (err) {
            callback(false)
        })
    },
    studentAccount_change:function (data,callback){  
        return postData('/sys/login/account/change',data).then((res) => {
            if(res){
                callback(res)
            }else{
                callback(false)
            }
        }).catch(function (err) {
            callback(false)
        })
    }
}