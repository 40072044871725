import React from 'react';
import api from '../../api/class'
import moment from 'moment'

/* eslint-disable */
class PolyvVideo extends React.Component{
    state = {
        startTime:undefined,
        lastStudyTime:null,
        option:{
            width: '1070',
            height: '603',
            show_rate:2,   //  允许播放的最高清晰度 1（流畅）、2（高清）
            autoplay:true,
            hideRepeat:true,  //播放结束后是否显示重播面板
            // flash: true,  //是否有限选择flash播放
            hideSwitchPlayer:true,  // 是否禁止显示HTML5与flash播放器切换按钮
        },
        sectionId:1,
        timer:null,//定时器
        roundTime:10000//定时器秒数
    }
    componentDidMount(){
        if(this.props.freeLive){//免费试看
            const option = JSON.parse(JSON.stringify(this.state.option));
            option.wrap = "#player";
            option.width = 1200
            option.height = 675
            if(this.props.videoId){
                option.vid = this.props.videoId;
            }else{
                option.url = this.props.videoUrl;
            }
            option.watchStartTime = this.props.lastStudyTime || 0
            this.player = polyvPlayer(option);
            window.s2j_onVideoPause = () => {
                
            }
            window.s2j_onVideoPlay = () => {
                
            }
            window.s2j_onVideoOver = () => {
                
            }
            this.setState({
                sectionId:this.props.id
            })
        }
    }
    componentWillReceiveProps(nextProps){
        if(this.state.sectionId === 1 && nextProps.id){
            const option = JSON.parse(JSON.stringify(this.state.option));
            option.wrap = "#player";
            if(nextProps.videoId){
                option.vid = nextProps.videoId;
            }else{
                option.url = nextProps.videoUrl;
            }
            option.watchStartTime = nextProps.lastStudyTime;
            this.player = polyvPlayer(option);
            window.s2j_onVideoPause = () => {
                // this.saveStudyRecord()
                if(this.state.timer){
                    clearInterval(this.state.timer)
                    this.setState({timer:null})
                }
            }
            window.s2j_onVideoPlay = () => {
                if(this.state.timer){
                    clearInterval(this.state.timer)
                    this.setState({timer:null})
                }
                this.setState({
                    startTime:(new Date()).getTime(),
                    timer:setInterval(()=>{
                        console.log(`-----跑定时器啦-----${this.state.roundTime/1000}s/次(偶尔${this.state.roundTime/1000*2}s/次)`)
                        this.saveStudyRecord()
                    },this.state.roundTime)
                })
            }
            window.s2j_onVideoOver = () => {
                // this.saveStudyRecord()
                if(this.state.timer){
                    clearInterval(this.state.timer)
                    this.setState({timer:null})
                }
            }
            this.setState({
                sectionId:nextProps.id
            })
            return true
        }
        if(nextProps.id && this.props.id !== nextProps.id){
            const option = JSON.parse(JSON.stringify(this.state.option));
            if(nextProps.videoId){
                option.vid = nextProps.videoId;
            }else{
                option.url = nextProps.videoUrl;
            }
            option.watchStartTime = nextProps.lastStudyTime;
            this.player.changeVid(option);
            this.setState({
                sectionId:nextProps.id
            })
            return true
        }
        return false
    }
    componentWillUnmount() {
        if(this.state.timer){
            clearInterval(this.state.timer)
            this.setState({timer:null})
        }
        if (this.player && this.state.startTime !== 0) {
            if((new Date()).getTime() - this.state.startTime > 10000){
                // this.saveStudyRecord()
            }
        }
        if(this.player){
            this.player.destroy();
        }
    }
    saveStudyRecord = () => {
        if(this.props.freeLive){//试看
            return
        }
        const { startTime } = this.state;
        let nowTime = (new Date()).getTime();
        let time = nowTime - startTime
        if(time>=10000 && startTime>0){
            let obj = {
                sectionType:'2',
                sectionId:this.state.sectionId,
                productId:localStorage.getItem('productId'),
                channelId:this.state.channelId,
                studyTime:Math.round(time/1000),
                lastStudyTime:parseInt(this.player.j2s_getCurrentTime()),
                subjectId:localStorage.getItem('subjectId'),
                beginStudyDate:moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
                endStudyDate:moment(nowTime).format('YYYY-MM-DD HH:mm:ss'),
            }
            let addStudyRecordData = JSON.parse(localStorage.getItem('addStudyRecordData'))
            obj = Object.assign({},obj,addStudyRecordData)
            api.addStudyRecord(obj,(res) => {
                console.log(res)
                if(res){
                    // 更新课程目录
                    this.props.getChapterSection({moduleId:localStorage.getItem('moduleId')})
                    this.setState({ 
                        startTime:nowTime,
                    })
                }
            })
        }
    }
    render(){
        return (
            <div id="player">
                
            </div>
        )
    }
}

export default PolyvVideo