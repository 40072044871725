import { message } from 'antd';
import { postData } from './index'

export default {
    getMyClass:(data, callback)=>{//获取我的班级数据
        // return postData('/web/usercenter/myClass',data).then((res) => {
        return postData('/web/usercenter/myClasses',data).then((res) => {
            if(res){
                callback(res);
            }
        }).catch( (err) => {
            message.error(err.msg)
        })
    },
    getTermData:(data, callback)=>{//获取学期数据
        // return postData('/web/course/getTerm',data).then((res) => {
        return postData('/web/core/userModuleTurn/getTermList',data).then((res) => {
            if(res){
                callback(res);
            }else{
                callback(false)
            }
        }).catch( (err) => {
            callback(false)
            message.error(err.msg)
        })
    },
    getModularData:(data, callback)=>{//获取模块数据
        // return postData('/web/course/getModule',data).then((res) => {
        // return postData('/web/course/getCourseCatalog',data).then((res) => {
        return postData('/web/core/userModuleTurn/listModuleTurnByTerm',{...data,courseFlag: 1}).then((res) => {    //慧简学2.19添加courseFlag传参兼容
            if(res){
                callback(res);
            }else{
                callback(false)
            }
        }).catch( (err) => {
            callback(false)
            message.error(err.msg)
        })
    },
    getChapterSection:(data, callback)=>{//获取模块下章节数据
        return postData('/web/course/getModuleCatalog',data).then((res) => {    
            if(res){
                callback(res);
            }
        }).catch( (err) => {
            message.error(err.msg)
        })
    },
    addStudyRecord:(data, callback)=>{//保存学习记录
        return postData('/web/studyrecrod/addStudyRecord',data).then((res) => {    
            if(res){
                callback(res);
            }
        }).catch( (err) => {
            message.error(err.msg)
        })
    },
    getStatusByChannelId:(data, callback)=>{//获取直播状态
        let url = (data.type===1?'/livechannel/getStatusByChannelId':'/livechannel/getBaijiayunChannelLiveStatus')//1-保利威视；2-百家云
        delete data['type']
        return postData(url,data).then((res) => {    
            if(res){
                callback(res);
            }
        }).catch( (err) => {
            message.error(err.msg)
        })
    },
    getBaijiayunLiveSign:(data, callback)=>{//获取百家云直播签名
        // return postData('/app/liveRoom/getBaijiayunSignByRoomId',data).then((res) => {
        return postData('/app/liveRoom/getBaijiayunLiveSign',data).then((res) => {
            if(res){
                callback(res);
            }
        }).catch( (err) => {
            message.error(err.msg)
        })
    },
    getBaijiayunPlayBackToken:(data, callback)=>{//获取百家云回放token
        return postData('/app/liveRoom/getBaijiayunPlayBackToken',data).then((res) => {    
            if(res){
                callback(res);
            }
        }).catch( (err) => {
            message.error(err.msg)
        })
    },
    getMyClassSubject:(data, callback) => {  //获取我的科目班级
        return postData('/web/exam/myClassSubjectList',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    // 课程评价
    addComment:(data, callback)=>{//新增评论
        return postData('/app/sectioncomment/addComment',data).then((res) => {    
            if(res.code === 'REQ001'){
                callback(res)
            }else{
                callback(false)
            }
        }).catch( (err) => {
            callback(false)
            message.error(err.msg)
        })
    },
    getFeedbackList:(data, callback) => {  //获取引导语列表
        return postData('/app/sectioncomment/feedbackList',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getComment:(data, callback) => {  //获取评论
        return postData('/app/sectioncomment/getComment',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    // 开放给第三方的接口
    getVideoParameters:(data, callback)=>{//获取视频播放参数
        return postData(`${process.env.Text[6]}/openresource/v1/study/videoParameters`,data).then((res) => {
            if(res.code === '10000'){
                if(res.data){
                    callback(res.data)
                }else{
                    callback(false)
                }
            }else{
                callback(false)
                message.error(res.msg)
            }
        }).catch( (err) => {
            callback(false)
            message.error(err.msg)
        })
    },
    // 外部API接入新起点课程start
    getNewCourseModular:(data,callback) =>{ //获取外部课程 -录播、直播详情、套餐数据
        return postData('/external/queryProduct',data).then(res=>{
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getNewClassInfo:(data,callback) =>{ //查询套餐录播课程详情
        return postData('/external/queryCourseInfo',data).then(res=>{
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getNewLiveInfo:(data,callback) =>{ //查询套餐-直播详情
        return postData('/external/queryLiveCourseInfo',data).then(res=>{
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getNewPlayLive:(data,callback) =>{ //获取套餐-直播播放凭证
        return postData('/external/playLive',data).then(res=>{
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    // 新起点第二期需求：当该产品标识为外部api接入产品，展示课程目录tab，目录根据直播/录播/套餐类型显示  相关接口
    getNewCourseModularForFree:(data,callback) =>{ //获取外部课程 -录播、直播详情、套餐数据
        return postData('/external/forFree/queryProduct',data).then(res=>{
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getNewClassInfoForFree:(data,callback) =>{ //查询套餐录播课程详情
        return postData('/external/forFree/queryCourseInfo',data).then(res=>{
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getNewLiveInfoForFree:(data,callback) =>{ //查询套餐-直播详情
        return postData('/external/forFree/queryLiveCourseInfo',data).then(res=>{
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    // 外部API接入新起点课程end
    // 
    addXqdStudyRecord:(data, callback)=>{//新起点视频保存学习记录
        return postData('/external/studyrecrod/addStudyRecord',data).then((res) => {    
            if(res){
                callback(res);
            }
        }).catch( (err) => {
            message.error(err.msg)
        })
    },
    getTeacherFilterParams:(data, callback) => { //查询录播年份教师筛选条件（需要token，用于其他已登录的地方）
        return postData('/external/course/filter/param', data).then((res) => {
            if(res){
                callback(res)
            }
        }).catch((err) => {
            message.error(err.msg)
        })
    },
    getQueryPlayClassToken:async (data,callback) =>{ //获取录播播放token
        return postData('/external/queryPlayClassToken',data).then(res=>{
            if(callback){
                callback(res)
            }
            return res
        })
    },
}
