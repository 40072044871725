import React from 'react'
import { Button, Form, Input, message , Modal, } from 'antd';
import api from '../../api/login'

import addImage from "../../assets/homePage/icon_tianjiazh@2x.png"

class AccountModal extends React.Component{
    state = {
        userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
        accountList: [],
        unbindIndex:0,
        unBindModal: false,
        addModal: false,
        formData:{
            studentAccount:'',
            passWord:''
        },
        currentIndex:0, // 当前选中边框位置
    }
    componentDidMount() {
        this.getStudentList()
    }


    getStudentList() {
        console.log('getStudentList')
        let groupId = this.state.groupId || '';
        let studentAccount = this.state.userInfo.studentAccount || '';
        api.studentAccount_qry({'groupId':groupId,'studentAccount':studentAccount},(res) => {
            if (res) {
                let list = res
                if (list.length <=1) {
                    this.setState({
                        accountList: res,
                        currentIndex: 0
                    })
                }else {
                    let tempIndex = 0
                    list.map((item, index) => {
                        if (this.state.userInfo.studentAccount == item.studentAccount) {
                            tempIndex = index
                        }
                    })
                    this.setState({
                        accountList: res,
                        currentIndex: tempIndex
                    })
                }
            }
        })
    }

    changeAccount(index) {
        console.log('change')
        this.setState({
            currentIndex: index
        })
        
    }
    api_change() {
        let item = this.state.accountList[this.state.currentIndex]
        if (this.state.userInfo.studentAccount != item.studentAccount) {
            api.studentAccount_change({'studentAccount':item.studentAccount, 'groupId': item.groupId}, (res) => {
                if (res) {
                    // res 相当于 登录成功后返回的 userInfo
                    // 1. 需要更换缓存中的 userInfo
                    // 2. 跳转到首页（myClass）
                    message.success('切换成功');
                    sessionStorage.clear()
                    
                    sessionStorage.setItem('userInfo',JSON.stringify(res));                
                    sessionStorage.setItem('token',res.token);
                    
                    localStorage.setItem('userInfo',JSON.stringify(res));
                    localStorage.setItem('token',res.token);
                    let rentId
                    if(res.tenantList && res.tenantList.length > 0){
                        rentId = res.tenantList[0].id
                    } else {
                        rentId = res.tenantId
                    }
                    window.location.reload()
                }
            })
            
        }else {
            message.error('该帐号当前正在使用中')
        }
    }

    addAccount() {
        this.setState({
            addModal: true
        })
    }

    api_unbind() {
        let item = this.state.accountList[this.state.unbindIndex]
        api.studentAccount_unbind({'id':item.id}, (res) => {
            if (res) {
                if (res == 1) {
                    this.getStudentList()
                    message.success('解绑成功');  
                }else {
                    // Toast.fail('解绑失败，请稍后重试');
                }
            }
            this.onClose()
        })
    }
    onClose() {
        this.setState({
            unBindModal: false,
            addModal: false 
        })
    }
    unBind(e,index) {
        console.log('解绑',e)
        console.log('12',index)
        e.stopPropagation();
        this.setState({
            unBindModal: true,
            unbindIndex: index
        })
    }

    
    api_addAccount = e => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log('123:123:',values)
            if (!err) {
                let obj = Object.assign({}, values)
                let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
                obj.groupId = userInfo.groupId || ''
                obj.id = userInfo.tenantPrimaryKey || ''
                console.log('obj',obj)
                api.studentAccount_add(obj, (res) => {
                    this.setState({
                        addModal: false 
                    })
                    this.getStudentList()
                    if (res && res == 1) {
                        message.success('添加成功');  
                    }
                })
            }
        });
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        const selected = {
            width: '360px', 
            height:'95px', 
            backgroundColor:'#fff', 
            border: '1px solid #3860F4',
            borderRadius: '6px', 
            marginBottom: '20px', 
            position: 'relative',
        }
        const normal = {
            width: '360px', 
            height:'95px', 
            backgroundColor:'#fff', 
            border: '1px solid #EBF0FC',
            borderRadius: '6px', 
            marginBottom: '20px', 
            position: 'relative',
        }

        const { formData } = this.state;
    
        return (
            <div style={{dwidth: '440px'}}>
                {
                    this.state.accountList.map((item,index) => (
                        <div style={this.state.currentIndex === index ? selected : normal} onClick={()=>{this.changeAccount(index)}} key={index}>
                            <div style={{ marginLeft: '16px', marginTop: '11px', color: '#343537', fontSize: '17px', fontWeight: 'bold'}}>{item.studentAccount}</div>
                            <div style={{ marginLeft: '16px', marginTop: '0px', color: '#949699', fontSize: '15px',}}>{item.phone || '未填写手机号'}</div>
                            <div style={{ marginLeft: '16px', marginTop: '0px', color: '#949699', fontSize: '15px',}}>{item.cardNo || '未填写证件号'}</div>
                            {this.state.userInfo.studentAccount === item.studentAccount && <div style={{width: '75px',height: '31px', background: '#ECF2FF', borderRadius: '0px 6px 0px 6px', fontSize: '14px', color:'#3860F4', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '0px', right: '0px'}}>当前使用</div>}
                            {this.state.userInfo.studentAccount != item.studentAccount && <div style={{width: '75px',height: '31px', background: 'fff', fontSize: '14px', color:'#FF3D3D', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: '0px', top: '32px'}} onClick={(e) => this.unBind(e,index)}>解绑</div>} 
                        </div>
                    ))
                }
                <div style={{width: '360px', height:'95px', backgroundColor:'#FCFDFF', border: '1px solid #EBF0FC', borderRadius: '6px',marginBottom: '40px', display: 'flex', alignItems: 'center', fontSize: '16px', color: '#343537', fontWeight: 'bold'}} onClick={() =>{this.addAccount()}}>
                    <img style={{width: '52px',height: '52px', marginLeft: '16px', marginRight: '14px'}} src={addImage}></img>
                    添加帐号
                </div>
                <Button type="primary" onClick={() => {this.api_change()}} style={{fontSize: '17px',color:'#fff',background:'#396BF0',
                    width:'360px',height:'42px',border:'none', borderRadius: '21px'}} >
                    确定
                </Button>
                {/* <Modal
                    visible={this.state.unBindModal}
                    transparent
                    maskClosable={false}
                    title="提示"
                    footer={[{ text: '取消', onPress: () => this.onClose(), style: 'default' },
                    { text: '确定', onPress: () => this.api_unbind() },]}
                >
                    <p>是否解除绑定</p>
                </Modal> */}
                <Modal title=""
                    visible={this.state.unBindModal}
                    width={300}
                    onCancel={()=>{this.onClose()}}
                    footer={[
                        <Button key="back" shape="round" onClick={()=>{this.onClose()}} >取消</Button>,
                        <Button key="submit" shape="round" onClick={()=>{this.api_unbind()}}>确定</Button>,
                    ]}
                >
                    是否解除绑定
                </Modal>
                <Modal title="新增帐号"
                    visible={this.state.addModal}
                    width={500}
                    onCancel={()=>{this.onClose()}}
                    onOk={()=>{this.api_addAccount()}}
                    >                    
                    <div>
                        <Form onSubmit={this.handleSubmit}>
                                <Form.Item label="">
                                    {getFieldDecorator('studentAccount', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入账号'
                                            },
                                        ],
                                        initialValue:formData.studentAccount || formData.passWord
                                    })(<Input placeholder='请输入账号' size="large" maxLength={20} />)}
                                </Form.Item>
                                <Form.Item label="" style={{marginBottom:'14px'}}>
                                    
                                </Form.Item>
                                <Form.Item label="">
                                    {getFieldDecorator('passWord', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入密码',
                                            },
                                        ],
                                    })(<Input type="password" placeholder="请输入密码" size="large" onPressEnter={this.api_addAccount} onChange={this.chagePass} />)}
                                </Form.Item>
                            </Form>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(AccountModal)