import * as type from '../constants/ActionTypes';

const initialState = {
    myClass:[],
    myClassSubject: [],
    termData:[],
    modularData:[],
    chapterSectionData:{
        charterCatalog:[],
        moduleMainTitle:'',
        sectionCount:0,
    },
    editPersonModal:false,
}

const classData = (state=initialState,action) => {
    switch (action.type) {
        case type.GET_MY_CLASS_DATA :
            console.log(action)
            return Object.assign({},{...state},{
                /* myClass:{
                    hasNullPlan:action.data.hasNullPlan,
                    myClassList:action.data.myClassList
                } */
                myClass:action.data
            })
        case type.GET_MYCLASS_SUBJECT :
                console.log(action)
            return Object.assign({},{...state},{
                myClassSubject: action.data
            })
        case type.GET_TERM_DATA :
            return Object.assign({},{...state},{
                termData:action.data
            })
        case type.GET_MODULAR_DATA :
            return Object.assign({},{...state},{
                modularData:action.data
            })
        case type.GET_CHAPTER_SECTION_DATA :
            if(action.data){
                return Object.assign({},{...state},{
                    chapterSectionData:{
                        charterCatalog:action.data.charterCatalog,
                        moduleMainTitle:action.data.moduleMainTitle,
                        sectionCount:action.data.sectionCount,
                        exerciseList:action.data.exerciseList,//练习题
                        materialList:action.data.materialList,//资料
                    }
                })
            }else{
                return {...state}
            }
        case type.CHANGE_EDIT_PERSON_MODAL :
            return Object.assign({},{...state},{
                editPersonModal:action.data
            })
        default:
            return {...state}
    }
}

export const myClassData = state => {
    return state.myClass
}

export const myClassSubjectData = state => {
    return state.myClassSubject
}

export const termData = state => {
    return state.termData
}
export const modularData = state => {
    return state.modularData
}
export const chapterSectionData = state => {
    return state.chapterSectionData
}
export const editPersonModal = state =>{
    return state.editPersonModal
}
export default classData