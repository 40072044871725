import { message } from 'antd';
import { postData } from './index'

export default {
    examStart: async (data, callback) => { // 获取试卷信息
        return postData(`${process.env.Text[2]}/exam/external/start`,data).then((res) => {  
            if(callback){
                callback(res)
            }else{
                callback(false)
            }
            return res
        }).catch( (err) => {
            console.log(err)
            if(callback){
                callback(false)
            }
            return false
        })
    },
    getSectionTypeList: async (data, callback) => { // 获取章节类型列表
        return postData(`/external/querySectionTypeList`,data).then((res) => {
            if(callback){
                callback(res)
            }else{
                callback(false)
            }
            return res
        }).catch( (err) => {
            console.log(err)
            if(callback){
                callback(false)
            }
            return false
        })
    },
    getSectionList: async (data, callback) => { // 获取章节列表
        return postData(`/external/querySectionList`,data).then((res) => {
            if(callback){
                callback(res)
            }else{
                callback(false)
            }
            return res
        }).catch( (err) => {
            console.log(err)
            if(callback){
                callback(false)
            }
            return false
        })
    },
    getPaperList: async (data, callback) => { // 获取章节列表（新起点）
        return postData(`${process.env.Text[2]}/exam/external/question/bank/paperList`,data).then((res) => {
            if(callback){
                callback(res)
            }else{
                callback(false)
            }
            return res
        }).catch( (err) => {
            console.log(err)
            if(callback){
                callback(false)
            }
            return false
        })
    },
}
