import React from 'react'
import { Form, Input, message } from 'antd'
import { withRouter } from 'react-router-dom'
import tool from '../../utils/commonFuc'
import loginApi from '../../api/login'

class resetPassNextStep extends React.Component{
    state = {
        isClick:false,
        registerStatus:false,
        isHjx: false // 是否是慧简学结构
    }

    componentDidMount(){
        const { location } = this.props;
        if(location.query){
            sessionStorage.setItem('resetPassInfo',JSON.stringify(location.query));
        }
        this.setState({
            isHjx: tool.isHJXTenant()
        })
    }

    handleSubmit = () => {

    }
    changePass = (e) => {
        let value = e.target.value;
        if(value.length >= 6){
            this.setState({
                isClick:true
            })
        }
    }
    timer = null
    solveLogin = () => {  //点击完成事件
        if(this.state.isClick){
            if(sessionStorage.getItem('resetPassInfo')){
                let obj = JSON.parse(sessionStorage.getItem('resetPassInfo'));
                this.props.form.validateFields((err,values) => {
                    if(!err){
                        obj.newPass = values.newPass;
                        loginApi.resetPass(obj, res => {
                            if(res){
                                sessionStorage.removeItem("userPhone");  //清除验证码登录界面的缓存
                                this.props.history.push("/phoneLogin/passLogin")
                            }
                        })
                    }
                })
            }else{
                message.error("重要信息缺失，无法重置密码")
            }
        }
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const isHjx = this.state.isHjx
        let colorStyle = ''
        if (isHjx) {
            colorStyle = '#396BF0'
        } else {
            colorStyle = '#FFBD30'
        }
        const study_btn_styles = {
            color: this.state.isClick && !isHjx? "#332A2A" : "#FFFFFF",
            backgroundColor: this.state.isClick ? colorStyle : "#D9D9D9",
        }   
        return (
            <div className="phone_login_content">
                <div className="right_content_box">
                    <p>设置新密码</p>
                    <div className="tip_text">密码至少由字母，数字或特殊符号中2种字符组成区分大小写</div>
                    {/* form表单处 */}
                    <div className="form_box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item label="">
                                {getFieldDecorator('newPass', {
                                    rules: [
                                    /* {
                                        required: true,
                                        message: '请输入密码',
                                    }, */
                                    {
                                        validator:(rule, value, callback) => {
                                            let reg = /(?!.*[\u4E00-\u9FA5\s])(?!^[a-zA-Z]+$)(?!^[\d]+$)(?!^[^a-zA-Z\d]+$)^.{8,12}$/
                                            console.log(reg.test(value))
                                            if(value && reg.test(value)){
                                                callback()
                                            }else{
                                                callback('请按上述密码规则要求输入8~12位字符')
                                            }
                                          }
                                    }
                                    ],
                                })(<Input placeholder="请输入新密码" onChange={this.changePass} onPressEnter={this.solveLogin} allowClear maxLength={20} />)}
                            </Form.Item>
                        </Form>
                        <div className="footer_btn over_solve_box">
                            <button style={study_btn_styles} onClick={this.solveLogin}>完成</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(resetPassNextStep))