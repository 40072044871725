import React,{useState,useEffect,createContext} from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon, message, Layout } from 'antd'

import MyHeader from '../../components/Header/index'
import MyFooter from '../../components/Footer/index'
import Course from '../../views/Index/course'//选课程

import tool from '../../utils/commonFuc'

const { Header, Content } = Layout;
export const TestContext = createContext({});
const IndexContainers = (propsObj)=>{
    const [state,setState] = useState({
        keyword:'',
        searchStatus:false,
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{

    },[])
    const setKeyword = (val)=>{
        updateState({keyword:val})
    }
    const setSearchStatus = (val,keyword)=>{
        updateState({searchStatus:val,keyword:keyword})
    }
    
    return <div className="">
        <Layout style={{minHeight:'100vh'}}>
            {/* 头部 */}
            <Header style={{backgroundColor:'#ffffff'}}>
                <TestContext.Provider value={{state,setKeyword,setSearchStatus}}>
                    <MyHeader pathname={propsObj.location.pathname} {...propsObj.history} showContactBtn={true}></MyHeader>
                </TestContext.Provider>
            </Header>
            <TestContext.Provider value={{state,setSearchStatus}}>
                <Course></Course>
            </TestContext.Provider>
            <MyFooter></MyFooter>
        </Layout>
    </div>
}
const mapStateToProps  = (state) =>({
    
})

const mapDispatchToProps = (dispatch) => ({
    
})
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(IndexContainers))