import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import MainBox from '../../components/MainBox'
import MyHeader from '../../components/Header/index'
import { Layout } from 'antd'
import NewVedioStudy from '../../views/NewVedioStudy/NewVedioStudy'
import tool from '../../utils/commonFuc'
import myClassApi from '../../api/class'
const { Header, Content } = Layout;
class NewVedioPlayContainers extends React.Component{
    state = {
        section:{},
        externalProductType:0,
        productId:null,//产品ID
        isFree:null,
    }
    
    async componentWillMount(){
        this.setState({isFree:tool.getQueryString2('isFree')})
        console.log("this.props",this.props);
        this.setState({
            productId:tool.getQueryString2('productId')
        })
        let obj = {}
        if(this.props.location.state){
            console.log("课程详情跳转播放视频页",this.props.location.state);
            // return
            sessionStorage.setItem('livingSection',JSON.stringify(this.props.location.state))
            obj = {
                section:this.props.location.state,
                externalProductType:this.props.location.state.externalProductType
            }
        }else{
            obj = {
                section:JSON.parse(sessionStorage.getItem('livingSection')),
                externalProductType:JSON.parse(sessionStorage.getItem('livingSection')).externalProductType
            }
        }
        let videoToken = ''
        if(obj.section.videoInfo.hdUrl.includes('.m3u8')){
            videoToken = await myClassApi.getQueryPlayClassToken({chapterId:obj.section.charterId,mediaId:obj.section.videoInfo.mediaId})
        }
        obj.section.videoInfo.hdUrl += videoToken?`?token=${videoToken}`:''
        this.setState(obj)
    }

    // 子组件更新父组件section
    updateSection=(section)=>{
        this.setState({
            section:section
        })
    }
    render(){
        const {section,externalProductType} = this.state
        return(
            <div>
                <Layout style={{minHeight:'100vh'}}>
                    {/* 头部 */}
                    <Header style={{backgroundColor:'#ffffff'}}>
                        <MyHeader pathname={this.props.location.pathname} {...this.props.history} showContactBtn={false}></MyHeader>
                    </Header>
                    <MainBox>
                    {
                        section.charterId && <NewVedioStudy section={section} externalProductType={externalProductType} productId={this.state.productId} updateSection={this.updateSection} isFree={this.state.isFree}></NewVedioStudy>
                    }
                    </MainBox>
                </Layout>
            </div>
        )
    }
}
export default withRouter(NewVedioPlayContainers)