import React, { memo, useState } from 'react';

import './index.scss'
import TeacherInfoCard from './TeacherInfoCard';
import { withRouter } from 'react-router-dom';


const TeacherIntroductMoudle = memo((props) => {
const {mainTitle,subTitle,lineNum,teacherList,id,hasMoreTeacher} = props.moudleInfo
   const jumpToTeacherMorePage = (v) =>{
        // props.history.push({pathname:'/teacher',search:`id=${id}&mainTitle=${mainTitle}&subTitle=${subTitle}`})
        let url = window.location.origin + window.location.pathname + `#/teacher?id=${id}&mainTitle=${mainTitle}&subTitle=${subTitle}`
        window.open(url)
   }
    return (
        <div className="teacher-intro-wrap">
            {/* <img className="home-page-content-title" src={require('../../../../assets/homePage/title-2.png')} /> */}
            <div className='header-main-box'>
                <span className='header-title'>{mainTitle}</span>
                <span className='header-subTitle'>{subTitle}</span>
            </div>
            <div className={`teacher-intro-card-module ${lineNum == 3 ?'teacher-info-wrap-style-3':''} ` }>
                {teacherList.length > 0 && teacherList.slice(0,12).map((v, i) => {
                    return <TeacherInfoCard key={v.id} info={v} lineNum={lineNum}/>
                })}
            </div>
            {teacherList.length > 12 && <div className="more-btn-box" onClick={() => { jumpToTeacherMorePage() }}>
                <div>更多教师</div>
            </div>}
        </div>
    );
});

export default withRouter(TeacherIntroductMoudle);