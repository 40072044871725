import React,{useState,useEffect,useContext} from 'react'
import { withRouter } from 'react-router-dom'
import { Pagination, message ,Icon } from 'antd'
import api from '../../api/homePage.js'
import tenantApi from '../../api/tenant'
import { TestContext } from '../../containers/HomePage/CourseContainers'
import tool from '../../utils/commonFuc'

const Index = (propsObj)=>{
    const AppContext = useContext(TestContext)
    const [state,setState] = useState({
        searchParams:{
            courseComponentId: '',
            name:'',
            projectTypeId:'',
            businessLevelId:'',
            majorId:'',
            provinceId:'',
            page:1,
            rows:8,
            productName:''  //组件化模块进来的。搜索时需要传这个字段
        },
        paramsOptions:{},//选择参数
        projectTypeActive:0,
        businessLevelActive:0,
        majorActive:0,
        provinceActive:0,
        courseList:[],//课程列表
        courseTotal:0,//课程总数
        projectTypeShowMore:false,//展示更多
        businessLevelShowMore:false,//展示更多
        majorShowMore:false,//展示更多
        provinceShowMore:false,//展示更多
        showCoursePrice: '',
    })
    
    //更新state
    const updateState = (newState,callback)=>{
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({},data,newState))
    }
    // 监听
    useEffect(()=>{
        const init = async ()=>{
            let pcUrl = window.location.hostname
            let rentInfo = await tenantApi.getTenantInfo({pcUrl:pcUrl})
            if(rentInfo){
                let rentId = rentInfo.id
                let searchParams = {
                    rentId:rentId,
                    courseComponentId: tool.getQueryString2('courseComponentId') ? tool.getQueryString2('courseComponentId') : '',
                    name:(propsObj.history.location.query && propsObj.history.location.query.keyword) || '',
                    productName:(propsObj.history.location.query && propsObj.history.location.query.keyword) || '',
                    projectTypeId:'',
                    businessLevelId:'',
                    majorId:'',
                    provinceId:'',
                    page:1,
                    rows:8,
                }
                let getCourseFilterParams = new Promise((resolve, reject) => {
                    api.getCourseFilterParams({rentId:rentId, courseComponentId:tool.getQueryString2('courseComponentId') ? tool.getQueryString2('courseComponentId') : '',},(res)=>{
                        if(res){
                            res.majors.splice(0,0,{majorId:'',majorName:'全部'})
                            res.projectTypes.splice(0,0,{projectTypeId:'',projectTypeName:'全部'})
                            if(res.businessLevels){
                                res.businessLevels.splice(0,0,{businessLevelId:'',businessLevelName:'全部'})
                            }
                            res.provinces.splice(0,0,{provinceId:'',provinceName:'全部'})
                            resolve(res)
                        }else{
                            resolve([])
                        }
                    })
                })
                let getCourseList = new Promise((resolve, reject) => {
                    api.getCourseListData(searchParams,(res)=>{
                        if(res){
                            resolve(res)
                        }else{
                            resolve([])
                        }
                    })
                })
                let showCourseObj = new Promise((resolve, reject)=>{
                    api.getOtherConfig({clientType: 3, rentId:rentId}, (res)=>{
                        if(res){
                            resolve(res)
                        }else{
                            resolve([])
                        }
                    })
                })
                Promise.all([getCourseFilterParams,getCourseList, showCourseObj]).then((res) => {
                    updateState({
                        searchParams:searchParams,
                        paramsOptions:res[0],
                        courseList:res[1].recordList,
                        courseTotal:res[1].totalCount,
                        showCoursePrice: res[2].showCoursePrice,
                    })
                    
                }).catch((error) => {
                    console.log(333,error)
                })
            }
        }
        if (!sessionStorage.getItem('rentId')) {
            setTimeout(() => {
                init()
            }, 1000)
            return
        }
        let rentId = sessionStorage.getItem('rentId')
        let searchParams = {
            rentId:rentId,
            courseComponentId: tool.getQueryString2('courseComponentId') ? tool.getQueryString2('courseComponentId') : '',
            name:(propsObj.history.location.query && propsObj.history.location.query.keyword) || '',
            productName:(propsObj.history.location.query && propsObj.history.location.query.keyword) || '',
            projectTypeId:'',
            businessLevelId:'',
            majorId:'',
            provinceId:'',
            page:1,
            rows:8,
        }
        let getCourseFilterParams = new Promise((resolve, reject) => {
            api.getCourseFilterParams({rentId:rentId, courseComponentId:tool.getQueryString2('courseComponentId') ? tool.getQueryString2('courseComponentId') : '',},(res)=>{
                if(res){
                    res.majors.splice(0,0,{majorId:'',majorName:'全部'})
                    res.projectTypes.splice(0,0,{projectTypeId:'',projectTypeName:'全部'})
                    if(res.businessLevels){
                        res.businessLevels.splice(0,0,{businessLevelId:'',businessLevelName:'全部'})
                    }
                    res.provinces.splice(0,0,{provinceId:'',provinceName:'全部'})
                    resolve(res)
                }else{
                    resolve([])
                }
            })
        })
        let getCourseList = new Promise((resolve, reject) => {
            api.getCourseListData(searchParams,(res)=>{
                if(res){
                    resolve(res)
                }else{
                    resolve([])
                }
            })
        })
        let showCourseObj = new Promise((resolve, reject)=>{
            api.getOtherConfig({clientType: 3, rentId:sessionStorage.getItem('rentId')}, (res)=>{
                if(res){
                    resolve(res)
                }else{
                    resolve([])
                }
            })
        })
        Promise.all([getCourseFilterParams,getCourseList, showCourseObj]).then((res) => {
            updateState({
                searchParams:searchParams,
                paramsOptions:res[0],
                courseList:res[1].recordList,
                courseTotal:res[1].totalCount,
                showCoursePrice: res[2].showCoursePrice,
            })
            
        }).catch((error) => {
            console.log(333,error)
        })
    },[])

    // 关键词修改
    useEffect(()=>{
        if(AppContext.state.searchStatus){
            console.log(222,AppContext.state)
            let postData = Object.assign({},state.searchParams,{name:AppContext.state.keyword,productName:AppContext.state.keyword,page:1})
            getCourseList(postData)
            AppContext.setSearchStatus(false)
        }
    },[AppContext.state.searchStatus])

    // 获取列表
    const getCourseList = (data)=>{
        let postData = Object.assign({},state.searchParams,data)
        api.getCourseListData(postData,res=>{
            updateState({
                searchParams:data,
                courseList:res.recordList,
                courseTotal:res.totalCount
            })
        })
    }

    const changeActive = (type,index,id)=>{
        switch (type){
            case 1:
                if(index != state.projectTypeActive){
                    state.projectTypeActive = index
                    getCourseList(Object.assign({},state.searchParams,{page:1,projectTypeId:id}))
                }
                break
            case 2:
                if(index != state.majorActive){
                    state.majorActive = index
                    getCourseList(Object.assign({},state.searchParams,{page:1,majorId:id}))
                }
                break
            case 3:
                if(index != state.provinceActive){
                    state.provinceActive = index
                    getCourseList(Object.assign({},state.searchParams,{page:1,provinceId:id}))
                }
                break
            case 4:
                if(index != state.businessLevelActive){
                    state.businessLevelActive = index
                    getCourseList(Object.assign({},state.searchParams,{page:1,businessLevelId:id}))
                }
                break
        }
    }
    const showMore = (type)=>{
        switch (type){
            case 1:
                updateState({projectTypeShowMore:!state.projectTypeShowMore})
                break
            case 2:
                updateState({majorShowMore:!state.majorShowMore})
                break
            case 3:
                updateState({provinceShowMore:!state.provinceShowMore})
                break
            case 4:
                updateState({businessLevelShowMore:!state.businessLevelShowMore})
                break
        }
    }
    // 改变页码
    const changePage = (val)=>{
        getCourseList(Object.assign({},state.searchParams,{page:val}))
    }

    const jumpToCourseDetail = (id)=>{
        let obj = tool.getQueryString2('courseComponentId') ? `id=${id}&courseComponentId=${tool.getQueryString2('courseComponentId')}` : `id=${id}`
        propsObj.history.push({pathname:'/courseDetail',search:obj})
    }

    return <div className="home-page-wrap">
        {/* 参数选择 */}
        <div className="course-select-params-wrap">
            <div className="course-select-params-item-bar" style={{height:state.projectTypeShowMore?'auto':30}}>
                <div className="course-select-params-item-bar-title">分类：</div>
                <div className="course-select-params-item-bar-options-module">
                    {state.paramsOptions.projectTypes && state.paramsOptions.projectTypes.map((v,i)=>{
                        return <div className={`options-item ${state.projectTypeActive==i?'active':''}`} key={i} onClick={()=>{changeActive(1,i,v.projectTypeId)}}>{v.projectTypeName}</div>
                    })}
                </div>
                <div className="course-select-params-item-bar-btn">
                    <Icon style={{color:'#BFCAE6'}} type={state.projectTypeShowMore?'caret-up':'caret-down'} onClick={()=>{showMore(1)}} />
                </div>
            </div>
            {/* <div className="course-select-params-item-bar" style={{height:state.businessLevelShowMore?'auto':30}}>
                <div className="course-select-params-item-bar-title">层次：</div>
                <div className="course-select-params-item-bar-options-module">
                    {state.paramsOptions.businessLevels && state.paramsOptions.businessLevels.map((v,i)=>{
                        return <div className={`options-item ${state.businessLevelActive==i?'active':''}`} key={i} onClick={()=>{changeActive(4,i,v.businessLevelId)}}>{v.businessLevelName}</div>
                    })}
                </div>
                <div className="course-select-params-item-bar-btn">
                    <Icon style={{color:'#BFCAE6'}} type={state.businessLevelShowMore?'caret-up':'caret-down'} onClick={()=>{showMore(4)}} />
                </div>
            </div> */}
            <div className="course-select-params-item-bar" style={{height:state.majorShowMore?'auto':30}}>
                <div className="course-select-params-item-bar-title">专业：</div>
                <div className="course-select-params-item-bar-options-module">
                    {state.paramsOptions.majors && state.paramsOptions.majors.map((v,i)=>{
                        return <div className={`options-item ${state.majorActive==i?'active':''}`} key={i} onClick={()=>{changeActive(2,i,v.majorId)}}>{v.majorName}</div>
                    })}
                </div>
                <div className="course-select-params-item-bar-btn">
                    <Icon style={{color:'#BFCAE6'}} type={state.majorShowMore?'caret-up':'caret-down'} onClick={()=>{showMore(2)}} />
                </div>
            </div>
            <div className="course-select-params-item-bar" style={{height:state.provinceShowMore?'auto':30}}>
                <div className="course-select-params-item-bar-title">地区：</div>
                <div className="course-select-params-item-bar-options-module">
                    {state.paramsOptions.provinces && state.paramsOptions.provinces.map((v,i)=>{
                        return <div className={`options-item ${state.provinceActive==i?'active':''}`} key={i} onClick={()=>{changeActive(3,i,v.provinceId)}}>{v.provinceName}</div>
                    })}
                </div>
                <div className="course-select-params-item-bar-btn">
                    <Icon style={{color:'#BFCAE6'}} type={state.provinceShowMore?'caret-up':'caret-down'} onClick={()=>{showMore(3)}} />
                </div>
            </div>
        </div>
        {/* 课程列表 */}
        <div className="course-list-wrap">
            {state.courseTotal==0 && <img style={{display:'block',margin:'100px auto 0'}} src={require('../../assets/homePage/no-data.png')} />}
            <div className="hot-course-content">
                {state.courseList && state.courseList.map((v,i)=>{
                    return <div className="hot-course-content-item" key={i} onClick={()=>{jumpToCourseDetail(v.id)}}>
                        {v.isAudition==1 && <div className='free-live-icon'>免费试看</div>}
                        <div className="hot-course-content-img" style={{backgroundImage:`url(${v.productUrl})`}}></div>
                        <div className="hot-course-content-text">
                            <div className="hot-course-content-title clamp2">{v.name}</div>
                            {state.showCoursePrice == 1 && <span className={state.showCoursePrice == 1 ? "hot-course-content-price" : ''}>￥{v.minPrice?`${v.minPrice} ~ ￥${v.maxPrice}`:v.maxPrice}</span>}
                        </div>
                    </div>
                })}
            </div>
            {state.courseTotal>0 && <Pagination className="course-list-page" showQuickJumper current={state.searchParams.page} defaultCurrent={1} total={state.courseTotal} pageSize={8} onChange={changePage} />}
        </div>
    </div>
}
export default withRouter(Index)