import { postData } from './index'

export default{
    getExamList:(data, callback) => {  //获取习题数据
        return postData('/web/exam/exerciseList',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getExamHome:(data, callback) => {  //获取题库首页
        return postData('/web/exam/home',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getApiExamHome:(data, callback) => {  //获取外部产品（新起点）题库首页
        return postData(process.env.Text[2] + '/exam/external/question/bank/v2/home',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getModuleTest:(data, callback) => {  //获取模块练习数据
        return postData('/web/exam/moduleTest',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getApiModuleTest:(data, callback) => {  ///获取外部产品（新起点）外部试卷列表接口查询
        return postData(process.env.Text[2] + '/exam/external/question/bank/paperList',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getQuestionList:(data, callback) => {  //试卷结果题目列表
        return postData(process.env.Text[2] + '/exam/test/user/answer/details',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
    getExamResultData:(data, callback) => {  //获取试卷结果
        return postData(process.env.Text[2] + '/exam/test/user/union/result/report',data).then( res => {
            callback(res)
        }).catch( err => {
            console.log(err)
        })
    },
}