import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Modal } from 'antd'

import StartPage from '../../views/Login/startPage'
import RulesModal from '../../views/Login/rulesModal'

import LoginApi from '../../api/login'
import tenantApi from '../../api/tenant'

import tool from '../../utils/commonFuc'

class startPageContainers extends React.Component{

    static propTypes = {
        history: PropTypes.object,
    }
    
    state = {
        visible:false,
        content:"",
        title:""

    }
    //保存是否保存token
    componentWillMount(){
        let token = localStorage.getItem('token')
        let userInfo = localStorage.getItem('userInfo')
        if(token && userInfo){
            sessionStorage.setItem('token',token)
            sessionStorage.setItem('userInfo',userInfo)
            this.props.history.push({pathname:'/home'})
        }
        // 清除新慧简学的机构ID
        sessionStorage.removeItem('rentId')
        const init = async ()=>{
            let pcUrl = window.location.hostname
            let hostname = tool.getQueryString(this.props.history, 'hostname') //测试专用
            if(hostname){
                pcUrl = hostname
            }
            let arr = [
                'localhost',
                'aline.mshengedu.com',
                'study.mshiedu.com',
                'study.mshengedu.com',
                'study.myunedu.com',
                'grey.mshiedu.com',
                'grey.mshengedu.com',
            ]
            let flag = true
            for(let v of arr){
                if(pcUrl.indexOf(v)>-1){
                    flag = false
                    break
                }
            }
            if(!flag){//没rentId情况下，本地、测试、线上域名打断
                return
            }
            let rentInfo = await tenantApi.getTenantInfo({pcUrl:pcUrl})
            if(rentInfo){
                sessionStorage.setItem('pcUrl',pcUrl)
                sessionStorage.setItem('rentId',rentInfo.id)
                this.props.history.push({pathname:'/homePage'})
            }
        }
        init()
    }
    showModal = (val) => {
        LoginApi.getAgreement({type:val}, res => {
            this.setState({
                content:res.content,
                title:res.title,
                visible:true
            })
        });
    }
    handleOk = () => {
        this.setState({
            visible:false
        })
    }

    handleCancel = () => {
        this.setState({
            visible:false
        })
    }

    render(){
        return (
            <div>
                <StartPage showModal={this.showModal}></StartPage>
                <Modal  title={this.state.title}
                visible={this.state.visible} 
                onOk={this.handleOk} cancelText="取消" okText="确定"
                onCancel={this.handleCancel}>
                    <RulesModal content={this.state.content}></RulesModal>
                </Modal>
            </div>
        )
    }

}

const mapStateToProps  = (state) =>({
    // homeDateData:homeReducer.homeDateData(state.home),
})

const mapDispatchToProps = (dispatch) => ({
    // checkHomeDataOverviewData: bindActionCreators(homeAction.checkHomeDataOverviewData,dispatch),
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(startPageContainers)