import React from 'react'
import { Form, Input, message } from 'antd'
import { withRouter } from 'react-router-dom'

import loginApi from '../../api/login'
import tool from '../../utils/commonFuc'

class phoneVerification extends React.Component{
    state = {
        isLogin:false,
        sendCodeText:'获取验证码',
        sendStatus:false,
        num:'',
        isHjx: false, // 是否是慧简学机构
        codeClick: false // 是否点击“发送验证码”
    }
    componentDidMount(){
        this.setState({
            isHjx: tool.isHJXTenant()
        })
    }

    chagePass = (e) => {
        if(e.target.value && e.target.value !== ''){
            this.setState({
                isLogin:true  
            })
        }else{
            this.setState({
                isLogin:false  
            })
        }
    }
    getCode = () => {
        let _data = {
            phone: this.props.form.getFieldValue("phone"),
            validType: "CZMM"
        };
        this.setState({
            codeClick: true
        })
        if (_data.phone) {
            loginApi.sendCode(_data, (res) => {
                if (res) {
                    //成功拿到验证码，修改“发送验证码”按钮状态,60秒倒计时      
                    let _num = 60;
                    let _interval = setInterval(() => {
                        this.setState({
                            sendCodeText: `后重发`,
                            sendStatus: true,
                            num:(--_num) + 'S'
                        })
                        if (_num === 0) {
                            this.setState({
                                sendCodeText: "重新发送",
                                sendStatus: false,
                                num:''
                            })
                            clearInterval(_interval);
                        }
                    }, 1000);
                }
            })
        } else {
            message.error("请输入手机号码")
        }
    }
    nextStep = () => {
        if (this.state.isLogin){
            if (!this.state.codeClick) {
                message.error('请点击获取验证码')
                return
            }
            this.props.form.validateFields((err,values) => {
                if(!err){
                    this.props.history.push({pathname:'/phoneLogin/resetPassNextStep',query:values})
                }
            })
        }
    }
    passLogin = () => {  //密码登录点击事件
        this.props.history.push({pathname:'/phoneLogin/passLogin'})
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        let colorStyle = ''
        const { isHjx} = this.state;
        if (isHjx) {
            colorStyle = '#396BF0'
        } else {
            colorStyle = '#FFBD30'
        }

        const study_btn_styles = {
            color: this.state.isLogin && !isHjx? "#332A2A" : "#FFFFFF",
            backgroundColor: this.state.isLogin ? colorStyle : "#D9D9D9",
            borderRadius:'24px'
        }
        const codeNode = () => {
            const { sendCodeText} = this.state;
            let codeColor = '#8B8B8B'
            if (sendCodeText === '获取验证码') {
                codeColor = colorStyle
            }
            const styles = {
                color: codeColor
            }
            return (
                <span onClick={this.getCode} style={styles}>
                    <span style={{color:'#FF4021'}}>
                        {this.state.num}
                    </span>
                    {this.state.sendCodeText}
                </span>
            )
        }
        const { formData } = this.state;
        return (
            <div className="phone_login_content">
                <div className="right_content_box">
                    <p>
                        找回密码
                    </p>
                    <div className="welcome_speech">   
                        请输入手机号，获取验证短信
                    </div>
                    {/* form表单处 */}
                    <div className="form_box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item label="">
                                {getFieldDecorator('phone', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入手机号',
                                        },
                                    ],
                                })(<Input placeholder="请输入手机号" size="large" allowClear maxLength={11} />)}
                            </Form.Item>
                            <Form.Item label="" style={{marginBottom:'14px'}}>
                                
                            </Form.Item>
                            <Form.Item label="">
                                {getFieldDecorator('validCode', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入验证码',
                                        },
                                    ],
                                })(<Input placeholder="请输入验证码" size="large" allowClear addonAfter={codeNode()} onPressEnter={this.goLogin} onChange={this.chagePass} />)}
                            </Form.Item>
                        </Form>
                        <div className={`${this.state.isHjx ? 'blue_hover':'orange_hover'} password_login_text`} onClick={this.passLogin} style={{cursor:'pointer'}}>
                            密码登录
                        </div>
                        <div className="footer_btn">
                            <button style={study_btn_styles} onClick={this.nextStep}>下一步</button>
                        </div>
                    </div>
                </div>
                <div className="right_blank_box">
                    
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(phoneVerification))