import React, { Component } from 'react';
import {  Breadcrumb } from 'antd'
import './index.scss'
import myClassApi from "../../api/class"
import moment from 'moment'
import tool from "../../utils/commonFuc"

class NewLivingPlayer extends Component {
    state = {
        section:{},
        liveStatus:'unknow',//直播状态
        iframeUrl:'',//直播地址
        timer:null,
        selectedClass:{},
        startTime:undefined,
    }
    
    componentDidMount(){
        this.getNewPlayLive()
        console.log("section",this.props.section);
    }
    componentWillUnmount(){
        if(this.state.timer){
            clearInterval(this.state.timer)
            this.setState({
                timer:null
            })
        }
    }
    getNewPlayLive(){
        myClassApi.getNewPlayLive({liveId:this.props.liveId,client:'1'},res=>{
            this.setState({
                iframeUrl:res.url,
                selectedClass:JSON.parse(sessionStorage.getItem('selectedClass')),
                startTime:new Date().getTime(),
                timer:setInterval(()=>{
                    this.saveStudyRecord()
                },10000)
            })
        })
    }
    saveStudyRecord = () => {
        // 免费试看
        if(this.props.isFree){
            return
        }
        const { startTime, selectedClass } = this.state;
        let nowTime = (new Date()).getTime();
        let time = nowTime - startTime
        if(time>=10000 && startTime>0){
            let obj = {
                studyType:this.props.status==2?1:3,//1-直播；2-录播；3-回放
                org:1,//外部机构，1-新起点
                studyId:this.props.liveId,//学习的章节id
                studyTitle:this.props.liveCourseName,//学习的章节标题
                productId:selectedClass.productId,//产品ID
                productName:selectedClass.productName,//产品名称
                studyTime:Math.round(time/1000),//学习时长：秒
                // lastStudyTime:parseInt(video.currentTime),//最后一次学习时间
                ordId:selectedClass.ordId,//订单id
                projectTypeId:selectedClass.projectTypeId,//项目类型ID	
                projectTypeName:selectedClass.projectTypeName,//项目类型名称
                beginStudyDate:moment(startTime).format('YYYY-MM-DD HH:mm:ss'),//开始学习时间
                endStudyDate:moment(nowTime).format('YYYY-MM-DD HH:mm:ss'),//结束学习时间
                appType:3,//应用类型：1.安卓，2.IOS，3.PC，4.H5
            }
            console.log(obj)
            myClassApi.addXqdStudyRecord(obj,(res) => {
                if(res){
                    this.setState({
                        startTime:nowTime
                    })
                }
            })
        }
    }
    render() {
        const {iframeUrl} = this.state
        const {section,liveCourseName} = this.props
        return (
            <div style={{padding:'10px 50px'}}>
                <div style={{width:'1182px',margin:'0 auto',padding:'15px 0px',background:'#f0f2f5'}}>
                    <Breadcrumb separator=">">
                        {!this.props.isFree && <Breadcrumb.Item href="#/home">学习主页</Breadcrumb.Item>}
                        {this.props.isFree && <Breadcrumb.Item href="#/course">选课程</Breadcrumb.Item>}
                        {this.props.isFree && <Breadcrumb.Item href={`#/courseDetail?id=${tool.getQueryString2('productId')}`}>课程详情</Breadcrumb.Item>}
                        {/* <Breadcrumb.Item href="#/home/modular">{localStorage.getItem('teachPlanName')}</Breadcrumb.Item> */}
                        <Breadcrumb.Item>{liveCourseName}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='vedio_content_box liveing_content_box'>
                    {
                        iframeUrl ? 
                            <iframe style={{width:'100%',height:'100%'}}
                            title="直播页面"
                            scrolling="no"
                            frameBorder="0"
                            src={iframeUrl}
                            allowfullscreen="true"
                            allowtransparency="true"
                        ></iframe> :
                        <img style={{display:'block',margin:'240px auto 0'}} src={require('../../assets/homePage/no-data.png')} />
                    }
                    
                </div>
               
            </div>
        );
    }
}

export default NewLivingPlayer;