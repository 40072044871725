import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Icon, message, Layout } from 'antd'

import MyHeader from '../../components/Header/index'
import MyFooter from '../../components/Footer/index'
import Index from '../../views/Index/homePage'//首页
import TransitPage from '../../views/Index/transitPage'//引导页

import tool from '../../utils/commonFuc'
import { floatComponentList } from '../../reducer/componentModule'
import { getFloatComponentAction } from '../../actions/componentModule'

const { Header, Content } = Layout;
const IndexContainers = (propsObj) => {
    const [state, setState] = useState({
        orgInfo: {},//机构信息
    })
    const dispatch = useDispatch()
    //更新state
    const updateState = (newState, callback) => {
        let data = JSON.parse(JSON.stringify(state))
        setState(Object.assign({}, data, newState))
    }
    useEffect(() => {
        // dispatch(getFloatComponentAction({rentId:sessionStorage.getItem('rentId'),tryFlag: 0,clientType :3}))
        // propsObj.getFloatComponentAction({rentId:sessionStorage.getItem('rentId'),tryFlag: 0,clientType :3})
    }, [])
    // const componentList = useSelector(state=>state.componentList)
    // console.log("home======componentList========",propsObj.floatComponentList)
    // 设置机构信息
    const setOrgInfo = (data) => {
        updateState({ orgInfo: data })
    }
    /* 
        关于域名的3个情况
        1.当前地址是旧的域名，没有独立域名，在旧的域名登录
        2.当前地址是旧的域名，已经有独立域名，跳去引导页
        3.当前地址是旧的域名，且拉入黑名单了，跳去404
    */
    // 是否展示中转页
    const showTransitPage = () => {
        let flag = false
        if (state.orgInfo.id && window.location.href.includes('rentId=')) {//旧域名
            if (state.orgInfo.trial == 1) {//0-正式；1-试用；试用机构可以用旧域名登录
                return false
            }
            if (state.orgInfo.cancelRentId == 1) {//情况-3
                propsObj.history.push({ pathname: '/404' })
                return flag
            } else {
                if (state.orgInfo.pcUrl.includes('rentId=')) {//情况-1
                    flag = false
                } else {//情况-2
                    flag = true
                }
            }
        }
        return flag
    }
    return <div className="">
        <Layout style={{ minHeight: '100vh' }}>
            {/* 头部 */}
            <Header style={{ backgroundColor: '#ffffff' }}>
                <MyHeader pathname={propsObj.location.pathname} {...propsObj.history} showContactBtn={true} setOrgInfo={setOrgInfo}></MyHeader>
            </Header>
            {!showTransitPage() && state.orgInfo.id && <Index></Index>}
            {!showTransitPage() && state.orgInfo.id && <MyFooter></MyFooter>}
            {showTransitPage() && <TransitPage orgInfo={state.orgInfo}></TransitPage>}
        </Layout>
    </div>
}
const mapStateToProps = (state) => ({
    floatComponentList: floatComponentList(state.componentModuleReducer)
})

const mapDispatchToProps = (dispatch) => ({
    // getFloatComponentAction:bindActionCreators(getFloatComponentAction,dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndexContainers))