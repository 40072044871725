import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
 
import './startPage.scss'

import icon1 from '../../assets/newImg/logo2@2x.png'
import check from '../../assets/newImg/Check@2x.png'
import noAgree from '../../assets/newImg/not_select@2x.png'
import code from '../../assets/newImg/code@2x.png'
import app from '../../assets/newImg/app.png'
import weixin from '../../assets/newImg/weixin.png'
import tenantApi from '../../api/tenant.js'
import tool from '../../utils/commonFuc'

class startPage extends React.Component{
    static propTypes = {
        showModal:PropTypes.func
    }
    state = {
        agree:true,
        isCustomEdition: true,
        logoPath: '',
    }

    async componentDidMount(){
        console.log('----->componentDidMount <=======')
        let id = tool.getQueryString(this.props.history,'id')
        console.log('--id=',id)
        if(id && id > 0) {
            await tenantApi.getTenantInfo({rentId:id},(res)=>{})
        } else {
            localStorage.removeItem('tenantInfo')
        }
        let logoUrl = ''
        let b = tool.isHJXTenant()
        if(b){
            // 新慧简学机构
            let json = localStorage.getItem('tenantInfo')
            let tenantInfo = JSON.parse(json)
            logoUrl = tenantInfo.logo
        } else {
            // 旧机构
        }
        this.setState({
            isCustomEdition: b,
            logoPath: logoUrl
        })
    }

    isAgree = () => {
        this.setState({
            agree: !this.state.agree
        })
    }
    goLogin = () => {
        if(!this.state.agree){
            return
        }
        const { history } = this.props;
        //检测上次登录方式
        let lastLoginType = JSON.parse(localStorage.getItem('lastLoginType'))
        if(lastLoginType && lastLoginType.showPassLogin){
            history.push({pathname:'/phoneLogin/passLogin'})
        }else{
            history.push({pathname:'/phoneLogin/index'})
        }
    }
    render(){
        const study_btn_styles = {
            color: this.state.agree ? "#332A2A" : "#FFFFFF",
            backgroundColor: this.state.agree ? "#FEDE24" : "#D9D9D9",
            borderRadius:'24px'
        }
        return (
            <div className="container_main">
                <div className="content_main">
                    <div className="icon1_father_box">
                        <img src={this.state.isCustomEdition ? this.state.logoPath : icon1} 
                            alt="图片加载失败" height="100%"></img>
                    </div>
                    <div className="title_box">
                        <span>
                            突破
                            <span></span>
                        </span>
                        <span> | </span>
                        <span>
                            从现在开始
                            <span></span>
                        </span>
                    </div>
                    <div className="center_box">
                        <button style={study_btn_styles} onClick={this.goLogin}>开始学习</button>
                    </div>
                    <div className="agreement_box">
                        <div className="check_img_box" onClick={this.isAgree}>
                            <img src={this.state.agree ? check :noAgree} alt="图片加载失败" width="100%" height="100%"></img>
                        </div>
                        <div>
                            阅读并已同意
                            <span onClick={() => this.props.showModal(2)}>《用户协议》</span>
                            和
                            <span onClick={() => this.props.showModal(3)}>《隐私政策》</span>
                        </div>
                    </div>
                    <div className="footer_btn" 
                        style={{justifyContent: this.state.isCustomEdition?'center':'space-around'}}>
                        <div className="footer_left_btn footer_common_btn">
                            <button>
                                APP下载
                            </button>
                            <button>
                                <img src={code} alt="图片加载失败" width="21" height="21"></img>
                            </button>

                            <div className="app_export_code QRCode">
                                <img src={app} alt="图片加载失败" width="222" height="222"></img>
                                <span className="">

                                </span>
                            </div>
                        </div>
                        {/* { !this.state.isCustomEdition?
                            <div className="footer_right_btn footer_common_btn">
                                <button>
                                    微信公众号
                                </button>
                                <button>
                                    <img src={code} alt="图片加载失败" width="21" height="21"></img>
                                </button>

                                <div className="officia_account_code QRCode">
                                    <img src={weixin} alt="图片加载失败" width="222" height="222"></img>
                                    <span className="">

                                    </span>
                                </div>
                            </div> : <div></div>
                        } */}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(startPage)