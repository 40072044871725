import React from 'react'
import { Form, Input, message, Button, Checkbox, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import loginApi from '../../api/login'
import RulesModal from './rulesModal'
import "./login.scss"
import logoIcon from '../../assets/newImg/logo2@2x.png'
import tool from '../../utils/commonFuc'

class phoneVerification extends React.Component{
    state = {
        isLogin:false,
        sendStatus:false,
        num:'',
        formData:{
            phone:'',
            validCode:''
        },
        btnLoading:false,
        person:{},
        isAgree: false, // 是否同意协议
        visible: false,
        content: '',
        title: '',
        isHjx: false // 是否是慧简学结构
    }

    componentDidMount(){
        if(sessionStorage.getItem("userPhone")){
            this.setState({
                formData: JSON.parse(sessionStorage.getItem("userPhone")),
                isLogin:true,
            })
        }
        this.setState({
            isHjx: tool.isHJXTenant()
        })
    }
    
    chagePass = (e) => {
        if(e.target.value && e.target.value !== ''){
            this.setState({
                isLogin:true  
            })
        }else{
            this.setState({
                isLogin:false  
            })
        }
    }
    loginIn = () => {
        window.trackEvent('PCH5-password-study', 'PC H5-账号密码登录-开始学习')
        if(this.state.isLogin){
            this.props.form.validateFields((err,values) => {
                if(!err){
                    if (!this.state.isAgree) {
                        message.error("请先勾选同意《用户协议》和《隐私政策》")
                        return
                    }
                    this.setState({
                        btnLoading:true
                    })
                    let obj = Object.assign({},values,{type:'pass'})
                    // 有机构信息的时候传机构ID
                    let tenantInfo = localStorage.getItem('tenantInfo') ? JSON.parse(localStorage.getItem('tenantInfo')).id : null
                    if(tenantInfo){
                        obj.tenantId = tenantInfo.id
                    }
                    loginApi.login(obj, res => {
                        this.setState({
                            btnLoading:false
                        })
                        if(res){
                            localStorage.setItem('lastLoginType',JSON.stringify({showPassLogin:true}))
                            sessionStorage.setItem('userName',obj.userName)
                            // 只能验证码登录
                            if(res.isOnlyValidCode===1){
                                message.warning('首次登录，请用验证码方式登录')
                                this.props.history.push({pathname:'/phoneLogin/index'})
                                return
                            }
                            // 是否强制设置密码
                            if(res.isForcePassword === 1){
                                this.props.history.push({pathname:'/phoneLogin/setPassword',search:`mustSetPassword=true`})
                                return
                            }
                            // v2.2.6上线后首次登录，提示修改密码
                            if(res.hadLoginIn===0){
                                this.props.history.push({pathname:'/phoneLogin/setPassword',search:`mustSetPassword=false`})
                                return
                            }
                            let event = {}
                            if(res.id){
                                event = {eventType:2,personId:res.id,rentId:res.tenantId}
                            }else{
                                event = {eventType:2}
                            }
                            loginApi.saveAppDataStatistics(event, res => {
                                console.log(res)
                            })
                            this.props.history.push({pathname:'/home'})
                        }
                    })
                }
            })
        }
    }
    handleForgetPass = () => {  //忘记密码事件
        window.trackEvent('PCH5-forgot-password', 'PC H5-账号密码登录-忘记密码')
        this.props.history.push({pathname:'/phoneLogin/retrievePassword'})
    }
    codeLogin = () => {  //密码登录点击事件
        window.trackEvent('PCH5-code-login', 'PC H5-验证码登录')
        this.props.history.push({pathname:'/phoneLogin/index'})
    }
    agreeChange = () => {
        this.setState({
            isAgree: !this.state.isAgree
        })
    }
    showModal = (val) => {
        if (val == 2) {
            window.trackEvent('PCH5-view-agreement', 'PC H5-查看服务协议')
        }
        if (val == 3) {
            window.trackEvent('PCH5-view-privacy', 'PC H5-查看隐私政策')
        }
        loginApi.getAgreement({type:val}, res => {
            this.setState({
                content:res.content,
                title:res.title,
                visible:true
            })
        });
    }
    handleOk = () => {
        this.setState({
            visible:false
        })
    }
    handleCancel = () => {
        this.setState({
            visible:false
        })
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const isHjx = this.state.isHjx
        let colorStyle = ''
        if (isHjx) {
            colorStyle = '#396BF0'
        } else {
            colorStyle = '#FFBD30'
        }
        const study_btn_styles = {
            color: this.state.isLogin && !isHjx ? "#332A2A" : "#FFFFFF",
            backgroundColor: this.state.isLogin ? colorStyle : "#D9D9D9",
            borderRadius:'24px'
        }
        const codeNode = () => {
            return (
                <span onClick={this.handleForgetPass} style={{color: this.state.isHjx ? '#396BF0' : '#FFBD30'}}>
                    忘记密码
                </span>
            )
        }
        const { formData } = this.state;
        return (
            <div className="phone_login_content">
                <div className="right_content_box">
                    {/* poko时显示图标 */}
                    {!this.state.isHjx && 
                        <div className='logo_box'>
                        <img src={logoIcon} alt="图片无法加载" height="86px"></img>
                        </div>
                    }
                    <p>
                        密码登录
                    </p>
                    {/* form表单处 */}
                    <div className="form_box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item label="">
                                {getFieldDecorator('userName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入账户名/手机号/身份证',
                                        },
                                    ],
                                    initialValue:formData.userName || formData.phone
                                })(<Input placeholder="请输入账户名/手机号/身份证" size="large" allowClear maxLength={20} />)}
                            </Form.Item>
                            <Form.Item label="" style={{marginBottom:'14px'}}>
                                
                            </Form.Item>
                            <Form.Item label="">
                                {getFieldDecorator('passwd', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入密码',
                                        },
                                    ],
                                })(<Input type="password" placeholder="请输入密码" size="large" allowClear addonAfter={codeNode()} onPressEnter={this.loginIn} onChange={this.chagePass} />)}
                            </Form.Item>
                        </Form>
                        <div className={`${this.state.isHjx ? 'blue_hover':'orange_hover'} password_login_text`} onClick={this.codeLogin} style={{cursor:'pointer'}}>
                            验证码登录
                        </div>
                        <div className={`${this.state.isHjx ? '':'orange_style'} agree_box`} style={{cursor:'pointer'}}>
                            <Checkbox onChange={this.agreeChange} defaultChecked={this.state.isAgree}></Checkbox>&nbsp;
                            我已同意<span className="remark_text" onClick={() => this.showModal(2)}>《用户协议》</span>和<span className="remark_text" onClick={() => this.showModal(3)}>《隐私政策》</span>  
                        </div>
                        <div className="footer_btn">
                            <Button className="study-btn" style={study_btn_styles} onClick={this.loginIn} loading={this.state.btnLoading}>登录</Button>
                        </div>
                    </div>
                </div>
                <div>
                    <Modal
                        title={this.state.title}
                        visible={this.state.visible} 
                        onOk={this.handleOk} cancelText="取消" okText="确定"
                        onCancel={this.handleCancel}>
                        <RulesModal content={this.state.content}></RulesModal>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(phoneVerification))