import * as actionTypes from '../constants/ActionTypes'
import api from '../api/homePage'


const changeFloatComponentAction = (res)=>({
    type:actionTypes.GET_FLOAT_MODULE_LIST,
    data:res
})
export const getFloatComponentAction = (data) =>{
    return dispatch =>{
        api.getFloatComponent(data,res=>{
            console.log("获取悬浮组件模板数据",res);
            dispatch(changeFloatComponentAction(res.componentList))
        })
    }

}